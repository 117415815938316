<template>
  <div class="tojoy-mytarget-mine-view">
    <section class="tab-comment tojoy-mytarget-box">
      <div class="rightDownContent">
        <div class="threeDetail">
          <!--添加工作目标入口-->
          <template v-if="pagination.page==1">
            <div
                class="tojoyAddTarget tojoyAddTarget1"
                v-for="(item, index) in addTargetLists"
                :key="index"
                style="margin: 20px 30px 0px;"
            >
              <div class="tojoyGroup" v-if="item.isOrg===1">
                <img src="../../assets/images/target/org.png">
              </div>
              <div class="tojoyDate">
              <span class="tojoyMonth">{{ item.month }}</span
              ><span class="tojoyYear">{{ item.year }}</span>
              </div>
              <p class="tojoyWriteTarget"  @click="writeInTarget(item)">
                + 填写工作目标
              </p>
            </div>
          </template>
          <!--工作目标列表-->
          <tojoy-pagination-list :data="targetLists" :total="pagination.total" :schema="'block'" class="target-list" :loading="isLoading"
                                 @page-change="handleChangePage"
          >
            <template #item="{ data }">
              <router-link
                :to="`/mytarget/deatils?targetId=${data.targetId}`"
                append
                style="width: 100%"
              >
                <div class="item-box">
                  <div class="" :class="['tojoyAddTarget', data.targetAssembleOutputs.length == 1 ? 'singleTarget' : '']">
                    <div class="tojoyGroup" v-if="data.isOrg === 1">
                      <img src="../../assets/images/target/org.png">
                    </div>
                    <div class="tojoyDate">
                      <span class="tojoyMonth">{{ data.month }}</span
                      ><span class="tojoyYear">{{ data.year }}</span>
                    </div>
                    <div
                      class="tojoyExamInfo"
                      v-for="(item, index) in data.targetAssembleOutputs"
                      :key="index"
                    >
                      <p :class="['tojoyExamList',item.targetIndexOutputs.length && item.state!=5 ? 'minHeight53': '']">
                        <span v-if="data.targetAssembleOutputs.length > 1">考评{{ ++index }}：权重{{ item.evalWeight }}%</span >
                      </p>
                      <!--<div class="tojoyExamState" v-if="!data.isFinish">-->
                      <div :class="['tojoyExamState',defaultState[item.state].cl]" v-if="item.targetIndexOutputs.length && item.state!=5">
                        {{ state[item.state] }}
                      </div>
                      <div v-if="item.targetIndexOutputs.length">
                        <div
                          class="tojoyExamItem"
                          v-for="(cItem, index) in item.targetIndexOutputs"
                          :key="index"
                        >
                          <span class="tojoyExamInfoDesing">{{
                            cItem.name
                          }}</span
                          ><span class="tojoyExamInfoTime"
                            v-if="cItem.type!=3 && cItem.planFinishDate">截止：{{ cItem.planFinishDateStr }}</span
                          ><span class="tojoyExamInfoTime"
                                 v-else-if="cItem.type===3">{{ cItem.frequency || '' }}例行</span>
                          <div style="min-width: 100px">
                             <span class="tojoyExamInfoLine"
                             >{{ cItem.progress || 0}}%</span
                             ><span></span>
                            <span class="tojoyPro">
                            <el-progress
                                :percentage="cItem.progress || 0"
                                :show-text="false"
                            ></el-progress>
                          </span>
                          </div>
                        </div>
                      </div>
                      <div v-else class="not-data" style="text-align: center">你还未填写目标，请尽快填写!</div>
                    </div>
                  </div>
                </div>
              </router-link>
            </template>
          </tojoy-pagination-list>
        </div>
      </div>
    </section>
    <!--考评方式弹框-->
    <tojoy-eval-selected
      :form="seleData"
      :display="windowPopstate"
      title="选择考评方式"
      @before-close="saveTargetFlowData"
    />
  </div>
</template>

<script>
import { homeusedapp } from '@/config/config.js';
import TojoyPaginationList from '@/components/business/pagination-list';
import TojoyEvalSelected from '@/components/mytarget/target-eval-selected';

import { __LIST_STATE__, __LIST__ORG__ } from './target.cfg';
import {
  ApiTargetLists /**我的所有目标列表查询*/,
  ApiTargetEntranceList /**工作目标入口查询  */,
  ApiTargetFlowSave /**考评流程保存 */,
} from '@/api/api_target';

export default {
  name: 'thrclear-mine',
  components: {
    TojoyPaginationList,
    TojoyEvalSelected,
  },
  data() {
    return {
      isLoading: true,
      windowPopstate: false,
      seleData: {},
      addTargetLists: [],
      targetLists: [],
      homeusedapp: homeusedapp,
      state: __LIST_STATE__,
      isOrg: __LIST__ORG__,
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      defaultState: {
        1: {
          tx: '待提交',
          cl: 'yellow',
        },
        2: {
          tx: '待确认',
          cl: 'blue',
        },
        3: {
          tx: '待自评',
          cl: 'yellow',
        },
        4: {
          tx: '待考评',
          cl: 'blue',
        },
        5: {
          tx: '已完成',
          cl: 'grey',
        },
        6: {
          tx: '已驳回',
          cl: 'yellow',
        },
      },
    };
  },
  computed: {},
  mounted() {
    this.loadTargetEntranceData();
    this.loadTargetData();
  },
  methods: {
    writeInTarget(item) {
      this.windowPopstate = true;
      this.seleData = { ...{}, ...item };
    },

    /**
     * 保存列表数据
     */
    async saveTargetFlowData(type, formData) {
      if (type === 'cancel') this.windowPopstate = false;
      if (type === 'ok') {
        const { code, data } = await ApiTargetFlowSave(formData);
        if (code === '000000') {
          this.$router.push({
            path: '/mytarget/deatils',
            query: {
              targetId: data,
            },
          });
          this.windowPopstate = false;
        }
      }
    },
    /**
     * 目标列表数据
     */
    async loadTargetData() {
      this.isLoading = true
      const { code, data } = await ApiTargetLists(this.pagination);
      if (code === '000000') {
        this.targetLists = data.list;
        this.pagination.total = data.total
        this.isLoading = false
      }
    },
    /**
     * 工作目标入口查询
     */
    async loadTargetEntranceData() {
      const { code, data } = await ApiTargetEntranceList();
      if (code === '000000') {
        // todo 数据返回结构  做数组包装
        this.addTargetLists = data && [data];
      }
    },
    handleTabClick(tab) {
      console.log(tab);
    },
    // 分页点击
    handleChangePage(val){
      this.pagination.page = val
      this.loadTargetEntranceData();
      this.loadTargetData();
    },
  },
};
</script>

<style lang="scss" scoped>
.#{$css-prefix}-mytarget-mine-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /deep/ .tojoy-pagination-list--block .item-card{
    background: none;
  }
  /deep/ .errorPage{
    width: auto;
    flex: 1;
  }
  .tab-comment {
    height: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    &__main {
      flex: 1;
      margin: 20px 30px 0;
      margin-bottom: 20px;
      background: $white;
      overflow: auto;
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: $white;
      height: 80px;
      padding: 0 30px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      p {
        font-size: 12px;
        font-weight: 400;
        color: $yellow;
        line-height: 12px;
        margin-right: 20px;
      }
      .el-button {
        height: 44px;
      }
    }
  }
  .tojoy-pagination-list {
    flex: 1;
  }

  .rightDownContent {
    height: 100%;
    /*margin: 20px 30px;*/
    display: flex;
    border-radius: 5px;
    overflow: auto;
  }

  .threeDetail {
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .tojoy-mytarget-box {
    width: 100%;
    .item-box {
      width: 100%;
    }
    .tojoyExamInfoDesing {
      display: inline-block;
      flex: 1;
      margin-right: 30px;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 200px);
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      height: 16px;
    }
    .tojoyPro {
      /*position: absolute;*/
      width: 60px;
      height: 5px;
      margin-top: 2.5px;
      margin-left: 5px;
      display: inline-block;
      /*right: 0;*/
      /*top: 20px;*/
    }
    .tojoyExamInfoLine {
      /*position: absolute;*/
      /*right: 65px;*/
      /*min-width: 100px;*/
      min-width: 28px;
      display: inline-block;
      text-align: right;
      font-size: 12px;
      height: 12px;
      line-height: 12px;
      font-weight: 400;
      color: $blue;
    }
    .tojoyExamInfoTime {
      /*position: absolute;*/
      /*right: 130px;*/
      min-width: 100px;
      text-align: left;
      margin-right: 30px;
      line-height: 12px;
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
      display: inline-block;
    }
    .tojoyExamState {
      width: 62px;
      height: 28px;
      font-size: 14px;
      line-height: 28px;
      background: #fef2e8;
      border-radius: 3px;
      font-weight: 400;
      color: #FE831A;
      text-align: center;
      position: absolute;
      right: 0px;
      top: 14px;
    }
    .tojoyExamState.blue{
      background: #EBF2FF;
      color: #3D7EFF;
    }
    .tojoyExamState.grey{
      color:#999999;
      background: #F7F7F7;
    }
    .tojoyExamState.yellow{
      background: #fef2e8;
      color: #FE831A;
    }
    .not-data {
      font-size: 14px;
      font-weight: 400;
      color: $darkgrey;
      padding: 20px 0 30px;
    }
    .tojoyExamList {
      padding-top: 20px;
      padding-bottom: 10px;
      font-size: 16px;
      font-weight: 500;
      color: $lightblack;
    }
    .minHeight53{
      min-height: 53px;
    }
    .tojoyTripleRight {
      position: absolute;
      left: 18px;
      top: 22px;
      z-index: 2;
      width: 0;
      height: 0;
      border-top: 8px solid $blue;
      border-left: 13px solid transparent;
    }
    .tojoyTriple {
      position: absolute;
      left: 5px;
      top: 22px;
      z-index: 2;
      width: 0;
      height: 0;
      border-top: 8px solid $blue;
      border-right: 13px solid transparent;
    }
    .tojoyGroup {
      width: 26px;
      height: 26px;
      position: absolute;
      left: 5px;
      top:0px;
      z-index: 2;
      >img{
        width: 100%;
        height: auto;
      }
    }
    .tojoyYear {
      width: 29px;
      height: 10px;
      font-size: 12px;

      font-weight: 400;
      color: $grey;
      line-height: 10px;
    }
    .tojoyMonth {
      display: block;
      width: 21px;
      height: 16px;
      font-size: 20px;

      font-weight: 600;
      color: $lightblack;
      line-height: 16px;
      margin-left: 14px;
      margin-top: 7px;
    }
    .tojoyExamInfo {
      margin-left: 80px;
      position: relative;
      margin-right: 11px;
    }
    .tojoyWriteTarget {
      cursor: pointer;
      height: 16px;
      font-size: 16px;

      font-weight: 500;
      color: $blue;
      line-height: 16px;
      position: absolute;
      left: 80px;
      top: 34px;
    }
    .tojoyDate {
      width: 50px;
      height: 50px;
      background: $white;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
      border-radius: 3px;
      opacity: 0.99;
      border: 1px solid $linecolor;
      position: absolute;
      top: 15px;
      left: 17px;
      text-align: center;
    }
    .tojoyExamItem {
      padding-top: 18px;
      margin-bottom: 2px;
      position: relative;
      height: 52px;
      background: $white;
      box-shadow: 0px 1px 0px 0px $linecolor;
      display: flex;
    }
    .tojoyAddTarget {
      min-height: 80px;
      background: $white;
      border-radius: 5px;
      opacity: 0.99;
      margin-bottom: 10px;
      position: relative;
    }
    .tojoyAddTarget1{
      z-index: 2;
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
    }
    .tojoyAddTarget.singleTarget{
      display: flex;
      align-items: center;
    }
    .singleTarget .tojoyExamInfo{
      flex:1;
    }
    .tojoyBlue {
      color: $blue;
    }
    .tojoySpan {
      float: right;
    }
    .tojoyRed {
      color: $red;
    }
    .fileHad {
      height: 40px;
      background: $white;
      border: 1px solid $linecolor;
      margin-top: 10px;
    }
    .tojoyP {
      height: 20px;
      font-size: 14px;

      font-weight: 400;
      color: $darkgrey;
      line-height: 20px;
      margin-bottom: 5px;
    }
  }

  .rightTop {
    background: $white;
    padding: 19px 0 19px 30px;
    .titleWord {
      height: 18px;
      font-size: 18px;
      font-weight: 500;
      color: $lightblack;
      line-height: 18px;
    }
  }
}
.target-list{
  .tojoyExamItem{
    height: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 15px;
  }
  .tojoyExamItem:last-child{
    box-shadow: 0px 0px 0px 0px transparent;
  }
  .tojoyExamInfo{
    box-shadow: 0px 1px 0px 0px $linecolor;
  }
  .tojoyExamInfo:last-child{
    box-shadow: 0px 0px 0px 0px transparent;
  }

}
/deep/ .tojoy-pagination-list--block .item-card{
  padding: 0px 0px 0px 0px;
}
</style>
