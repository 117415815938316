<template>
  <div class="tojoy-resContent">
    <div class="threeDown">
      <!-- <TojoyErrorOption v-if="datalistnull" :blankData="blankData" />
      <div v-if="!datalistnull" class="resTitle">
        <span class="resName">会议决议</span><span class="resNum">指派我的{{total}}条</span>
      </div> -->
      <tojoy-pagination-list
            @page-change="pageChange"
            :total="total"
            :currentPage="page"
            :data="dataList"
            :size="size"
          >
            <template #item="{ data }">
              <div class="resDiv">
                <div :class="'unStart over' + `color${data.state}`">{{processingdata(data.state)}}</div>
                <p class="design">{{data.title}}</p>
                <p class="line">
                  <span>到期日期:</span><span> {{data.endDate}} </span>
                  <span v-if="!!data.dayPlus && (data.state === 1 || data.state === 2) && Number(data.dayPlus) < 7" class="redWord">剩余{{data.dayPlus}}天</span>
                  <span v-if="data.state === 3" class="redWord">剩余0天</span>
                  <!-- <span class="redWord">剩余{{data.dayPlus}}天</span> -->
                </p>
                <p class="line"><span>决议来源:</span><span>{{data.meetName}}</span></p>
                <div v-if="data.state === 4" class="unUse notclick">
                    <i class="icon iconfont iconchangestatu"></i>
                    <span>更新状态</span>
                </div>
                <div v-else class="updataStatu unUse">
                  <el-dropdown @command="(val)=> handleCommand(val,data)">
                  <span class="el-dropdown-link">
                    <i class="icon iconfont iconchangestatu"></i>
                    更新状态
                  </span>
                  <el-dropdown-menu class="selectresolutionId" slot="dropdown">
                      <el-dropdown-item :command="ite.resolutionId" v-for="(ite, ix) in updateresolutionstatus" :key="ix">{{ite.state}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </template>
          </tojoy-pagination-list>
    </div>
  </div>
</template>

<script>
// import { Message } from 'element-ui'
// import TojoyErrorOption from '@/components/business/blank/blank.vue'
import TojoyPaginationList from '@/components/business/pagination-list'
import { listmeetresolution, updateresolutionState } from '@/api/thrclear.js'
export default {
  name: '',
  data() {
    return {
      datalistnull: false,
      blankData:{
        type: 'notdata'
      },
      total: 0,
      dataList:[],
      page: 1,
      size: 10,
      updateresolutionstatus:[
        {
          state: '未开始',
          resolutionId: 1
        },
        {
          state: '进行中',
          resolutionId: 2
        },
        // {
        //   state: '已过期',
        //   resolutionId: 3
        // },
        {
          state: '已完成',
          resolutionId: 4
        }
      ]
    }
  },
  components:{
    // TojoyErrorOption,
    TojoyPaginationList
  },
  created() {
    this.listmeetresolution()
  },
  mounted() {},
  methods: {
    pageChange(val) {
      this.page = val;
      this.listmeetresolution()
    },
    listmeetresolution() {
      let data = {
        page: this.page,
        size: this.size
      }
      listmeetresolution(data).then(res => {
          if (res && res.code === '000000') {
            if(res.data) {
              this.dataList = res.data.list;
              this.total = res.data.total;
            } else {
              this.datalistnull = true;
            }
          } else {
            this.datalistnull = true;
         }
      })
    },
    processingdata(val) {
      let status = '';
      switch(val)
        {
        case 1:
          status = '未开始'
        break;
        
        case 2:
          status ='进行中'
        break;

        case 3:
          status = '已过期'
        break;

        case 4:
          status = '已完成'
        break;

        default:
          status = '进行中'
      }
      return status
    },
    updateresolutionState(data) {
      updateresolutionState(data).then(res => {
        if (res && res.code === '000000') {
          this.listmeetresolution()
        }
      })
    },
    handleCommand(command,item) {
      let data = {
        // 	会议ID
        meetId: item.meetId,
        // 会议决议ID
        resolutionId: item.id,
        // 决议状态 1未开始；2进行中；3已完成；4已过期
        state: command
      }
      this.updateresolutionState(data)
    }
  }
}
</script>

<style scoped lang="scss">
.#{$css-prefix}-resContent {
  width: 100%;
  height: 100%;
  .el-dropdown-selfdefine{
    cursor: pointer;
  }
  .notclick{
    height: 20px;
    font-size: 15px;
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #999999;
    line-height: 15px;
    position: absolute;
    right: 0;
    bottom: 19px;
    .el-dropdown{
      .el-icon--right{
        display: none;
      }
    }
    cursor:not-allowed;
    .iconchangestatu{
      padding-right: 6px;
    }
  }
  .threeDown {
    height: 100%;
    width: 100%;
    /deep/.tojoy-pagination-list__wrap{
      background: #f5f5f5;
    }
    .line {
      height: 12px;
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 12px;
      margin-top: 15px;
    }
    .redWord {
      color: $ff526a;
    }
    .design {
      max-width: calc(100% - 100px);
      font-size: 16px;
      font-weight: 500;
      color: $lightblack;
      line-height: 22px;
    }
    /deep/.item-card{
      // margin-bottom: 20px;
      display: inline-block;
    }
    .resDiv {
      background: $white;
      padding-bottom: 20px;
      position: relative;
      .updataStatu {
        height: 20px;
        font-size: 15px;
        display: flex;
        align-items: center;
        font-weight: 400;
        color: $blue;
        line-height: 15px;
        position: absolute;
        right: 0;
        bottom: 19px;
        .el-dropdown{
          color: #3D7EFF;
          .el-icon--right{
            display: none;
          }
        }
      }
      .overcolor1{
          color: #999999;
        }
        .overcolor2{
          color: #FE831A;
        }
        .overcolor3{
          color: #FF526A;
        }
        .overcolor4{
          color: $green;
        }
        .overcolor5{
          color: $blue;
        }
      .unStart {
        width: 39px;
        height: 13px;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        position: absolute;
        right: 0;
      }
    }

    .resTitle {
      display: none;
      width: 100%;
      height: 60px;
      background: $white;
      opacity: 0.99;
      line-height: 60px;
      padding-left: 30px;
      margin-bottom: 20px;
    }
    .resName {
      display: inline-block;
      width: 72px;
      height: 18px;
      font-size: 18px;
      font-weight: 500;
      color: $lightblack;
      line-height: 18px;
    }
    .resNum {
      width: 79px;
      /* height: 14px; */
      font-size: 14px;

      font-weight: 400;
      color: $grey;
      /* line-height: 11px; */
      margin-left: 15px;
      /* margin-top: -31px; */
      display: inline-block;
    }
  }
}
</style>
