<template>
  <div class="democracy-mine-view">
    <tojoy-title-content title="我的互评">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <!-- {{ tabName }} -->
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
          <!-- 暂时屏蔽该功能 2020-01-15
           <el-button size="small" @click="$router.push({ name: 'democracy-inviter' })">
            <span class="icon iconfont iconpeople"></span>
            我的评价人(8)
          </el-button> -->
        </div>
      </template>
    </tojoy-title-content>
    <receive-page-list
      v-if="tabName === 'received'"
      key="dmr-pagination-list"
      :loading="loading"
      :data="receivedData.list"
      :total="receivedData.total"
      :current-page="currentPage"
      @page-change="handleChangePage"
    >
      <template #item="{data}">
        <tojoy-card
          :data="{
            time: String(data.month).slice(5, 7),
            unit: String(data.month).slice(0, 4)
          }"
        >
          <template #rightcard>
            <received-item v-for="(item, index) in data.list" :key="index" :data="item" />
          </template>
        </tojoy-card>
      </template>
    </receive-page-list>

    <section class="tab-comment" v-show="tabName === 'comment'">
      <template v-if="commentData && commentData.length > 0">
        <div class="tab-comment__main">
          <div v-for="(item, idx) in commentData" :key="idx" class="organization-tip--block comment">
            <organization-tip :type="item.type" :name="item.orgName" :createDate="item.createDate" :reportType="item.reportType" :star="item.started" />
            <!-- 员工互评 -->
            <div v-if="item.type == 1 && !item.started">
              <!-- 员工邀请 -->
              <div v-if="item.groupWay && item.groupWay == 2">
                <p class="noPeople" v-if="item.waitList.length">
                  当前有
                  <span style="color: #fe831a;font-weight: bold;">{{ item.waitList.length }}</span>
                  人邀请您评价TA，准确名单以实际评价时名单为准，若无人邀请你评价，亦不影响你的民主评议分
                </p>
                <div v-else>
                  <p class="noPeople">
                    当前有 <span style="color:red;font-weight: bold;">0</span>
                    人邀请您评价TA，准确名单以实际评价时名单为准，若无人邀请你评价，亦不影响你的民主评议分
                  </p>
                  <tojoy-blank :blank-data="{ type: 'notEvaluate' }" />
                </div>
              </div>
              <!-- 策略分组 -->
              <div v-if="item.groupWay && item.groupWay == 1">
                <p class="noPeople" v-if="item.waitList.length">
                  共有 <span style="color: #fe831a;font-weight: bold;">{{ item.waitList.length }}</span>
                  人与您互评，若分组不正确，请联系BP在民主评议开始前完成修正
                </p>
                <p v-else class="noOrgPeople">
                  请联系BP在民主评议开始前完成你的互评人员配置，否则会影响你的本项维度得分
                </p>
              </div>
            </div>
            <!-- 组织互评 -->
            <div class="noPeople" v-if="item.type == 0 && !item.started">
              当前组织管理员配置有
              <span style="color: #fe831a;font-weight: bold;">{{ item.waitList.length }}</span>
              个组织参评人参与互评，若分组不正确，请联系BP在组织互评开始前完成修正
            </div>

            <comment-item
              v-for="(w, wdx) in item.waitList"
              :key="wdx"
              :data="w"
              :started="item.started"
              @complete-rate="() => hadRateNum++"
              @click-user-card="u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })"
            />
            <div class="tab-comment__button" v-if="item.waitList && item.waitList.length">
              <el-button type="primary" :loading="submitLoading" @click="handleSubmitComment(idx)" class="footer-button" :disabled="item.started ? false : true">
                <p class="submit-star">提交{{ submitDate(item.reportType, item.submitDate) }}{{ item.type == 0 ? '组织互评' : '互评' }}</p>
                <p>{{ item.informTime }} {{ item.started ? '结束' : '开始' }}</p>
              </el-button>
            </div>
          </div>
        </div>
      </template>
      <tojoy-blank v-else :blank-data="{ type: 'notEvaluate' }" />
    </section>
    <tojoy-pagination-list
      v-if="tabName === 'complete'"
      key="dmc-pagination-list"
      :loading="loading"
      :data="completeData.list"
      :current-page="currentPage"
      :total="completeData.total || 0"
      @page-change="handleCompleteChangePage"
    >
      <template #item="{data}">
        <tojoy-card
          :data="{
            time: String(data.month).slice(5, 7),
            unit: String(data.month).slice(0, 4)
          }"
        >
          <template #rightcard>
            <div v-for="(item, sdx) in data.scoreList" :key="sdx" class="organization-tip--block">
              <organization-tip :type="item.type" :name="item.orgName" :createDate="item.createDate" :reportType="item.reportType" />
              <complete-item v-for="(o, odx) in item.outputs" :key="odx" :data="o" @click-user-card="u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })" />
            </div>
          </template>
        </tojoy-card>
      </template>
    </tojoy-pagination-list>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyCard from '@/components/business/card'
import TojoyPaginationList from '@/components/business/pagination-list'
import ReceivePageList from '@/components/democracy/receive-page-list'
import TojoyBlank from '@/components/business/blank/blank'
import TojoyTabs from '@/components/business/tabs'
import ReceivedItem from '@/components/democracy/received-item'
import CommentItem from '@/components/democracy/comment-item'
import CompleteItem from '@/components/democracy/complete-item'
import OrganizationTip from '@/components/democracy/organization-tip'
import tabsRouteMixins from '@/mixins/tabsRouteMixins'
import watchBroswerBackMixins from '@/mixins/satisfaction/watchBroswerBack'
import { handleDataBF, BF_TYPE } from './handle_data'
import { mineReceiveList, mineEvaluateList, minEvaluateSave, mineHasEvaluateList, orgGroupList } from '@/api/api_democracy'
import { getMonthWeek, getPerWeek } from '@/utils/index.js'
export default {
  name: 'democracy-mine',
  mixins: [tabsRouteMixins, watchBroswerBackMixins],
  components: {
    TojoyPaginationList,
    TojoyCard,
    TojoyTitleContent,
    TojoyBlank,
    TojoyTabs,
    ReceivedItem,
    CommentItem,
    CompleteItem,
    OrganizationTip,
    ReceivePageList //收到评价
  },
  props: {
    moduleName: {
      type: String,
      default: 'received'
    }
  },
  data() {
    return {
      receivedData: [],
      commentData: [],
      commentDataTotal: 0,
      completeData: [],
      tabName: this.moduleName,
      hadRateNum: 0,
      pagination: {
        page: 1,
        size: 10
      },
      currentPage: 1, // 标识当前页码
      submitLoading: false, // 待我评价-表单防重
      loading: false,
      groupId: null,
      reportType: null //待我评价提交用
    }
  },
  computed: {
    ...mapState({
      tabs: state => state.democracy.mineTabs,
      userName: state => state.userInfo.userName
    })
  },
  async created() {
    this.loading = true
    this.handleTabClick({ name: this.tabName })
    this.loadCommentData()
    this.loadMineRed()
  },
  methods: {
    ...mapActions('democracy', ['loadMineRed']),
    // 提交按钮时间处理
    submitDate(rType, date) {
      let newMonth = Number(date[1]) < 10 ? date[1].slice(1, 2) + '月' : date[1] + '月'
      if (rType == 4) {
        return newMonth
      } else if (rType == 1) {
        return newMonth + date[2] + '日'
      } else if (rType == 2) {
        return newMonth + '第' + getMonthWeek(date[0], date[1], date[2]) + '周'
      } else if (rType == 3) {
        return newMonth + getPerWeek(date[2])
      }
    },
    /**
     * 收到的评价
     */
    async loadReceivedData(params, options = {}) {
      this.loading = true
      const { code, data } = await mineReceiveList({ ...params }, options)
      if (code === '000000') {
        this.receivedData = handleDataBF(data, BF_TYPE.receivedList)
        this.loading = false
      }
    },
    // 待我评价
    async loadCommentData() {
      this.commentData = []
      const { code, data } = await mineEvaluateList()
      if (code === '000000') {
        // console.log(data, '待我评价77777777777')
        let _data = handleDataBF(data, BF_TYPE.commentList)
        this.commentData = _data.list
        this.commentDataTotal = _data.total
      }
    },
    /**
     * 加载我已评的列表数据）TODO
     */
    async loadCompleteData(params, options = {}) {
      this.loading = true
      const { code, data } = await mineHasEvaluateList({ ...params, size: 10 }, options)
      if (code === '000000') {
        this.completeData = handleDataBF(data, BF_TYPE.completeList)
        this.loading = false
      }
    },
    // 收到评价分页
    handleChangePage(val) {
      this.currentPage = val
      this.loadReceivedData({ page: val, size: 10 }, { loading: true })
    },
    //我已评分页
    handleCompleteChangePage(val) {
      this.currentPage = val
      this.loadCompleteData({ page: val, size: 10 }, { loading: true })
    },
    /**
     * 批量提交
     */
    async handleSubmitComment(index) {
      const _allWaitList = this.commentData[index]
      const _needcomment = _allWaitList.waitList.filter(item => !item.hadrate).map(item => item.username)
      if (_needcomment.length) {
        this.$warning(`请先完成本轮全部成员评分`)
      } else {
        this.submitLoading = true
        let _comment = handleDataBF(_allWaitList.waitList, BF_TYPE.commontSubmit)
        let user = {
          userName: this.userName,
          groupId: _allWaitList.groupId,
          reportType: _allWaitList.reportType
        }
        let params = { ..._comment, ...user }
        // console.log(_comment, '传参提交', params)
        const { code } = await minEvaluateSave(this.$filterParamsTrim(params))
        if (code === '000000') {
          this.$msgSuccess(`评价成功 共评价${this.hadRateNum}人`)
          this.loadCommentData()
          await this.loadMineRed()
          this.hadRateNum = 0
        }
        this.submitLoading = false
      }
    },
    handleTabClick({ name }) {
      this.currentPage = 1
      switch (name) {
        case 'received':
          this.loadReceivedData({ ...this.pagination })
          break
        case 'comment':
          // this.loadCommentData()
          break
        case 'complete':
          this.loadCompleteData({ ...this.pagination })
          break
      }
    }
  }
}
</script>

<style lang="scss">
.democracy-mine-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .tab-comment {
    height: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    &__main {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 20px 30px;
      // margin-bottom: 20px;
      // background: $white;
      overflow: auto;
      .noPeople {
        background: #fef2e8;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #666666;
        width: calc(100% - 30px);
        height: 32px;
        padding: 7px 10px;
        margin: 0 15px;
      }
      .noOrgPeople {
        background: #ffedf0;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #ff526a;
        width: calc(100% - 30px);
        height: 32px;
        padding: 7px 10px;
        margin: 0 15px;
      }
    }
    &__button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      // background: $white;
      height: 64px;
      padding: 0 30px;
      p {
        font-size: 12px;
        font-weight: 400;
      }
      .footer-button {
        width: 240px;
        height: 50px;
        /deep/ span {
          display: flex;
        }
        .submit-star {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 4px;
        }
      }
    }
  }
  .tojoy-title-content__footer {
    .iconpeople {
      font-size: 13px;
    }
  }
  .tojoy-pagination-list {
    flex: 1;
  }
  .organization-tip--block {
    &.comment {
      padding-top: 15px;
      background: $white;
      border-radius: 5px;
      margin-bottom: 10px;
      flex: 1;
      .democracy-organization-tip {
        margin-right: 15px;
      }
    }
    .democracy-organization-tip {
      margin-left: 15px;
      margin-bottom: 0;
    }
    .democracy-complete-item {
      &:last-of-type {
        .democracy-complete-item__wrap {
          border-bottom: none;
        }
      }
    }
  }
  .tab-received {
    height: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    .appraiser {
      height: 127px;
      &-title {
        color: #333;
        font-size: 15px;
        margin-bottom: 15px;
        font-weight: 500;
        span {
          color: #999999;
          font-weight: normal;
        }
      }
      &-content {
        display: flex;
        justify-content: space-between;
        .appraiser-l {
          display: flex;
          .block {
            display: flex;
            flex-direction: column;
            margin-right: 19px;
            align-items: center;
            p {
              width: 42px;
              margin-top: 2px;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        //
      }
    }
  }
}
</style>
