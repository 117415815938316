var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display)?_c('div',{staticClass:"tojoy-dialog__write"},[_c('el-dialog',{staticClass:"tojoy-dialog-index",attrs:{"visible":_vm.display,"width":"800px","close-on-click-modal":false,"before-close":function () {
        _vm.handleBeforeClose('cancel')
      }},on:{"update:visible":function($event){_vm.display=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"tojoy-dialog__write--header"},[_c('span',{staticClass:"resName"},[_vm._v(_vm._s(_vm.titleTxt[_vm.titleTxtType])+_vm._s(_vm.typeTxt[_vm.formData.type])+"指标")]),_c('span',{staticClass:"resNum"},[_vm._v(_vm._s(_vm.dateStr))])])]},proxy:true},{key:"footer",fn:function(){return [_c('span',{staticClass:"dialog-footer"},[_c('el-button',{directives:[{name:"preventClick",rawName:"v-preventClick"}],attrs:{"type":"primary"},on:{"click":function () {
              _vm.handleBeforeClose('ok')
            }}},[_vm._v("提交")])],1)]},proxy:true}],null,false,597072862)},[_c('el-dialog',{attrs:{"width":"560px","title":"对齐年度目标","visible":_vm.innerVisible,"custom-class":"target-align-dialog","append-to-body":""},on:{"update:visible":function($event){_vm.innerVisible=$event}}},[_c('target-align',{attrs:{"dataList":_vm.targetList},on:{"choose-target":_vm.chooseTarget}})],1),(_vm.formConfig)?_c('section',{staticClass:"tojoy-dialog__body--form tojoy-dialog__write--body"},[_c('el-form',{ref:"ruleForm",staticClass:"reset-form",attrs:{"model":_vm.formData,"show-message":true,"inline-message":"","label-width":"180px","label-position":"top"}},[_c('div',{staticClass:"form-list"},[(_vm.formConfig['name'])?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":_vm.formConfig['name'].showName,"rules":[
                {
                  required: _vm.formConfig['name'].isRequired,
                  message: ((_vm.formConfig['name'].showName) + "不能为空"),
                  trigger: ['change', 'blur']
                }
              ],"prop":"name"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"},{name:"loadFocus",rawName:"v-loadFocus"}],attrs:{"type":"textarea","maxlength":"50","placeholder":"请填写","autosize":{ minRows: 1, maxRows: 3 },"resize":"none","show-word-limit":false},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),(_vm.formData.type == 1 && _vm.targetList.length)?_c('div',{staticClass:"target-align__button--add",on:{"click":function($event){_vm.innerVisible = true}}},[_c('i',{staticClass:"el-icon-plus"}),_c('span',[_vm._v("对齐年度目标")])]):_vm._e()],1)],1):_vm._e(),(_vm.formConfig['name'] && _vm.formData.type == 1)?_c('div',{staticClass:"item",staticStyle:{"position":"relative"}},[_c('el-form-item',{staticClass:"tojoyTextareaSpe",attrs:{"label":_vm.formConfig['taskDesc'].showName,"rules":[
                {
                  required: _vm.formConfig['taskDesc'].isRequired,
                  message: _vm.getMessageTip('taskDesc'),
                  trigger: ['change', 'blur']
                },
                {
                  pattern: /^(\-|\+)?\d+(\.\d+)?$/,
                  message: '指标值为整数或者小数',
                  trigger: ['change', 'blur']
                }
              ],"prop":"taskDesc"}},[_c('div',{staticStyle:{"margin":"10px 0"}},[_c('el-radio-group',{staticClass:"radio-group",attrs:{"size":"mini"},model:{value:(_vm.formData.isPositive),callback:function ($$v) {_vm.$set(_vm.formData, "isPositive", $$v)},expression:"formData.isPositive"}},[_c('el-radio',{attrs:{"label":true,"border":""}},[_vm._v("正数 "),(_vm.formData.isPositive)?_c('img',{staticClass:"okimg",attrs:{"src":require("@/assets/images/target/selected_ok@2x.png"),"alt":""}}):_vm._e()]),_c('el-radio',{attrs:{"label":false,"border":""}},[_vm._v("负数 "),(!_vm.formData.isPositive)?_c('img',{staticClass:"okimg",attrs:{"src":require("@/assets/images/target/selected_ok@2x.png"),"alt":""}}):_vm._e()])],1)],1),_c('el-input',{directives:[{name:"only-number",rawName:"v-only-number",value:({ length: 6 }),expression:"{ length: 6 }"}],staticClass:"pleaseWrite",attrs:{"maxlength":15,"placeholder":"请填写","controls-position":"right","controls":false},model:{value:(_vm.formData.taskDesc),callback:function ($$v) {_vm.$set(_vm.formData, "taskDesc", $$v)},expression:"formData.taskDesc"}})],1),_c('el-select',{staticClass:"tojoyMony input-unit input-position",attrs:{"placeholder":"请选择"},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.formData.taskUnit),callback:function ($$v) {_vm.$set(_vm.formData, "taskUnit", $$v)},expression:"formData.taskUnit"}},_vm._l((_vm.taskOptions),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.key,"value":item.key}})}),1)],1):_vm._e(),(_vm.formConfig['taskDesc'] && (_vm.formData.type == 2 || _vm.formData.type == 3))?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":_vm.formConfig['taskDesc'].showName,"rules":[
                {
                  required: _vm.formConfig['taskDesc'].isRequired,
                  message: _vm.getMessageTip('taskDesc'),
                  trigger: ['change', 'blur']
                }
              ],"prop":"taskDesc"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","rows":5,"resize":"none","placeholder":"请填写关键任务及考核标准\n1、...\n2、...\n3、...","disabled":_vm.formConfig['taskDesc'] ? false : true,"show-word-limit":false,"maxlength":"800"},model:{value:(_vm.formData.taskDesc),callback:function ($$v) {_vm.$set(_vm.formData, "taskDesc", $$v)},expression:"formData.taskDesc"}})],1)],1):_vm._e(),(_vm.formConfig['weight'])?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":_vm.formConfig['weight'].showName,"rules":[
                {
                  required: _vm.formConfig['weight'].isRequired,
                  message: _vm.getMessageTip('weight'),
                  trigger: ['change', 'blur']
                }
              ],"prop":"weight"}},[_c('el-select',{staticClass:"tojoyMony input-select",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.weight),callback:function ($$v) {_vm.$set(_vm.formData, "weight", $$v)},expression:"formData.weight"}},_vm._l((_vm.weightOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1):_vm._e(),(_vm.formConfig['frequency'])?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":_vm.formConfig['frequency'].showName,"rules":[
                {
                  required: _vm.formConfig['frequency'].isRequired,
                  message: _vm.getMessageTip('frequency'),
                  trigger: ['change', 'blur']
                }
              ],"prop":"frequency"}},[_c('el-select',{staticClass:"tojoyMony input-select",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.frequency),callback:function ($$v) {_vm.$set(_vm.formData, "frequency", $$v)},expression:"formData.frequency"}},_vm._l((_vm.frequencyOptions),function(item){return _c('el-option',{key:item.key,attrs:{"label":item.key,"value":item.key}})}),1)],1)],1):_vm._e(),(_vm.formConfig['planFinishDate'])?_c('div',{staticClass:"item date"},[_c('el-form-item',{attrs:{"label":_vm.formConfig['planFinishDate'].showName,"rules":[
                {
                  required: _vm.formConfig['planFinishDate'].isRequired,
                  message: _vm.getMessageTip('planFinishDate'),
                  trigger: ['change', 'blur']
                }
              ],"prop":"planFinishDate"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"format":"yyyy-MM-dd","value-format":"yyyy-MM-dd","picker-options":_vm.pickerBeginDateBefore,"type":"date","prefix-icon":"null","placeholder":"选择日期"},model:{value:(_vm.formData.planFinishDate),callback:function ($$v) {_vm.$set(_vm.formData, "planFinishDate", $$v)},expression:"formData.planFinishDate"}})],1)],1):_vm._e(),(_vm.formConfig['planHour'])?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"prop":"planHour","label":_vm.formConfig['planHour'].showName,"rules":[
                {
                  required: _vm.formConfig['planHour'].isRequired,
                  message: _vm.getMessageTip('planHour'),
                  trigger: ['change', 'blur']
                },
                { validator: _vm.validatorPlanHour }
              ]}},[_c('el-input',{directives:[{name:"only-number",rawName:"v-only-number",value:({ length: 2 }),expression:"{ length: 2 }"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"请填写所需工时，最低0.5","maxlength":_vm.hourMaxLength},model:{value:(_vm.formData.planHour),callback:function ($$v) {_vm.$set(_vm.formData, "planHour", $$v)},expression:"formData.planHour"}}),_c('span',{staticClass:"tojoyTime"},[_vm._v("小时")])],1)],1):_vm._e(),(_vm.formData.bindLevel)?[(_vm.formConfig['level'])?_c('div',{staticClass:"item tojoyLevel-level-show"},[_c('el-form-item',{attrs:{"label":_vm.formConfig['level'].showName}},[_c('span',{class:['tojoyLevel', _vm.levelStyle[_vm.colorSort + 1]]},[_c('el-select',{staticClass:"tojoyMony input-select",staticStyle:{"width":"100%"},attrs:{"placeholder":"请选择"},on:{"change":_vm.changeLevel},model:{value:(_vm.formData.bindLevel),callback:function ($$v) {_vm.$set(_vm.formData, "bindLevel", $$v)},expression:"formData.bindLevel"}},_vm._l((_vm.levelOptions),function(item){return _c('el-option',{key:item.key,attrs:{"label":item.key,"value":item.key}})}),1)],1),(_vm.formData.bindLevel && _vm.levelTxt)?_c('span',{staticClass:"tojoyCom"},[_vm._v(_vm._s(_vm.levelTxt))]):_vm._e()])],1):_vm._e()]:[(_vm.formConfig['level'])?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"prop":"bindLevel","label":_vm.formConfig['level'].showName,"validate-o":"","rules":[
                  {
                    required: _vm.formConfig['level'].isRequired,
                    message: _vm.getMessageTip('level'),
                    trigger: 'change'
                  }
                ]}},[_c('span',[_c('el-select',{staticClass:"tojoyMony input-select level-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"用于快速定位关键指标"},on:{"change":_vm.changeLevel},model:{value:(_vm.formData.bindLevel),callback:function ($$v) {_vm.$set(_vm.formData, "bindLevel", $$v)},expression:"formData.bindLevel"}},_vm._l((_vm.levelOptions),function(item,index){return _c('el-option',{key:item.key,attrs:{"label":item.key,"value":item.key}},[_c('span',{class:['level-desc', _vm.levelStyle[index + 1]]},[_vm._v(" "+_vm._s(item.key))]),_c('span',[_vm._v(_vm._s(item.desc))])])}),1)],1)])],1):_vm._e()],_c('div',{staticClass:"item"},[_c('el-checkbox',{model:{value:(_vm.formData.isLib),callback:function ($$v) {_vm.$set(_vm.formData, "isLib", $$v)},expression:"formData.isLib"}},[_vm._v("同步到指标库")])],1)],2)])],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }