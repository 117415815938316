/* 工作目标 */

import * as http from './http'

//目标列表
export const ApiTargetLists = params => http.post('/target/list', params)

//目标入口查询
export const ApiTargetEntranceList = params => http.post('/target/entrance/list', params, {loading: false})

//目标详情
export const ApiTargetDetails = params => http.post('/target/detail', params)

// 考评流程查询
export const ApiTargetFlowList = params => http.post('/target-flow/flow/list', params)

// 流程记录查询
export const ApiTargetFlowLogList = params => http.post('/target-flow/log/list', params, {loading:false})

// 考评流程保存
export const ApiTargetFlowSave = params => http.post('/target-flow/flow/save', params)

// 指标新增
export const ApiTargetIndexSave = params => http.post('/target/index/save', params, {interceptRes:false, loading: false})

// 从新填写指标
export const ApiTargetRemove = params => http.post('/target/remove', params)

// 撤回指标
export const ApiTargetRevert = params => http.post('/target/revert', params)

// 删除指标
export const ApiTargetDelete = params => http.post('/target/index/delete', params)

// 修改指标
export const ApiTargetEdit = params => http.post('/target/index/edit', params,{interceptRes:false, loading: false})

// 更新指标
export const ApiTargetProgress = params => http.post('/target/index/save-progress', params, {interceptRes:false, loading: false})

// 自评指标
export const ApiTargetScore = params => http.post('/target/index/self-score', params,{interceptRes:false, loading: false} )

// 提交目标确认
export const ApiTargetSave = params => http.post('/target/save', params)

// 指标提交
export const ApiTargetSubmitEval = params => http.post('/target/index/submit-eval', params)


// 目标考核--列表数据
export const ApiTargetLeadList = params => http.post('/target-lead/lead/list', params)

// 目标考核--列表批量确认
export const ApiTargetLeadListAffirm = params => http.post('/target-lead/lead/affirm', params)

// 目标考核--列表批量驳回
export const ApiTargetLeadListReject = params => http.post('/target-lead/lead/affirm', params)

// 目标考核--列表批量评分
export const ApiTargetLeadListCore = params => http.post('/target-lead/lead/eval', params)

//查询员工领导（直属）
export const ApiTargetQueryLeader = params => http.post('/user/query-leader', params)

//查询员工（姓名入参）
export const ApiTargetQueryByName = params => http.post('/user/search-by-name', params)


//指标库查询
export const ApiTargetQueryLib = params => http.post('/target/index-lib/select', params, {loading:true})

//指标库删除
export const ApiTargetDeleteLib = params => http.post('/target/index-lib/delete', params, {loading:false})

//指标库表单配置查询
export const ApiTargetConfigForm = params => http.post('/target-config/form-config/list', params)

//子目标查询
export const ApiTargetDetailList = params => http.post('/target/detail/list', params, {loading:false})

//下属查询
export const ApiTargetLeadPending = params => http.post('/target-lead/lead/pending', params, {loading: false})

//下属未交记录查询
export const ApiTargetUrgeUser = params => http.post('/target-lead/lead/urge-user-list', params, {loading: false})

// 双线考评同步
export const ApiTargetSync = params => http.post('/target/index/sync', params)

//一键提醒
export const ApiTargetUrge = params => http.post('/target-lead/lead/urge', params, {interceptRes:false, loading: false})

// 分组配置查询
export const ApiTargetFormConfig = params => http.post('/target-config/kv-config/list', params,{loading:false})

// 获取当前目标月份
export const ApiTargetMonth= params=> http.post('/target/entrance', params,{loading:false})

// 获取最高设置
export const ApiDimensionScore= params=> http.post('/dimension/score', params,{loading:false})


//目标考核

/** start******年度目标 **/
// 年度目标-年度目标列表
export const ApiTargetYearList = params => http.post('/target-year/queryCanViewPage', params)
// 年度考评-年度目标分解列表
export const ApiTargetYearDetail = params => http.post('/target-year/queryById', params)
// 年度考评-年度目标分解列表-对齐子列表目标
export const ApiTargetAlignListById = params => http.post('/target-year/queryListByPid', params)
// 年度目标-目标对齐列表
export const ApiTargetAlignListByMonth = params => http.post('/target-year/queryListMByLiableUserMonth', params, {loading: false})
/** end******年度目标 **/
