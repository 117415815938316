<template>
  <div class="tojoy-calendar">
    <el-calendar v-model="rateValue">
      <template #dateCell="{ data }">
        <p  v-if="getReportStatus(data.day).status === '' || getReportStatus(data.day).status === null || getReportStatus(data.day).reportStatus === 4" class="date-num date-make">
          {{
            data.day
              .split('-')
              .slice(2)
              .join('-')
          }}
          <span v-show="new Date(`${rateValue + '-' + data.day
                .split('-')
                .slice(2)
                .join('-')}`).getTime() < new Date().getTime() && getReportStatus(data.day).reportStatus !== 4" class="date-radius normal"></span>
          <span class="date-radius makeUp" v-show="getReportStatus(data.day).reportStatus === 4"></span>
        </p>
        <!-- <el-tooltip
          v-if="getReportStatus(data.day) === '' || getReportStatus(data.day) === null"
          content="正常"
          placement="top"
          effect="light"
          :visible-arrow="false"
        >
          <p class="date-num date-make">
            {{
              data.day
                .split('-')
                .slice(2)
                .join('-')
            }}
            <span class="date-radius"></span>
          </p>
        </el-tooltip> -->
        <el-tooltip
          v-else
          placement="top"
          effect="light"
          popper-class="tooltip-light"
        > 
          <p class="tooltip-light__content" slot="content">
            <span>{{getReportStatus(data.day).status}}</span>
            <!-- <el-button class="makeUp" type="primary">补交</el-button> -->
            <span v-if="getReportStatus(data.day).isRepairFlag === 1" @click="routerPushThreClear(getReportStatus(data.day),data.day)" class="makeUp">补交</span>
          </p>
          <p class="date-num date-error">
            {{
              data.day
                .split('-')
                .slice(2)
                .join('-')
            }}
            <span class="date-radius"></span>
          </p>
        </el-tooltip>
        <!-- <el-tooltip
          v-else-if="getReportStatus(data.day) == 3"
          content="未交"
          placement="top"
          effect="light"
        >
          <p class="date-num date-error">
            {{
              data.day
                .split('-')
                .slice(2)
                .join('-')
            }}
            <span class="date-radius"></span>
          </p>
        </el-tooltip> -->
      </template>
    </el-calendar>
  </div>
</template>
<script>
import { reportRepairCheck } from '@/api/thrclear.js'
export default {
  name: 'tojoy-calendar',
  data() {
    return {}
  },
  model: {
    prop: 'statisticsDate',
    event: 'change'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    statisticsDate: {
      type: String,
      default: () => ''
    },
    dateList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    rateValue: {
      get() {
        return String(this.statisticsDate)
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  methods: {
    routerPushThreClear(val,day) {
      let data = {
        repairDate: day,
        reportType: val.reportType
      }
      let text = '';
      let value = new Date(day).getMonth() + 1 + '月' + new Date(day).getDate() + '日';
      if(val.reportType === 1) {
        text = `补${value}日清`
      } else if (val.reportType === 2) {
        text = `补${value}周清`
      } else {
        text = `补${new Date(day).getMonth() + 1 + '月'}月清`
      }
      reportRepairCheck(data).then(res => {
        if (res.code === '000000') {
          this.$router.push({
              name: 'thrclear-work-day',
              query: {
                reportType: val.reportType,
                name: text,
                makeUp: day
              }
          })
        }
      })
      
    },
    getReportStatus(day) {
      let flag = {
        status: '',
        isRepairFlag: 0
      }
      this.dateList.forEach(item => {
        if (item.meetDate == day.replace(/-/gi, '.')) {
          flag = {
            status:item.tips,
            isRepairFlag:item.isRepairFlag,
            reportType: item.reportType,
            reportDate: item.reportDate,
            reportStatus: item.reportStatus
          }
        }
      })
      return flag
    }
  }
}
</script>
<style lang="scss">
  .tooltip-light{
    &__content{
      max-width: 100px;
      // overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      .makeUp{
        cursor: pointer;
        margin-left: 6px;
        width: 36px;
        height: 22px;
        background: #3C87FF;
        border-radius: 2px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 22px;
      }
    }
  }
.tojoy-calendar {
  .el-calendar__header {
    display: none;
  }
  .el-calendar-table {
    font-size: 10px;

    thead th {
      color: #33333382;
    }
    tbody tr,
    tbody td {
      border: 0;
      .date {
        &-error {
          span {
            background: $red;
          }
        }
        &-make {
          .normal {
            background: $c9ec1ff;
          }
          .makeUp{
            background: #FEBB1A;
          }
        }
        &-num {
          position: relative;
        }
        &-radius {
          width: 5px;
          height: 5px;
          display: block;
          border-radius: 100%;
          position: absolute;
          bottom: -5px;
          left: 7px;
        }
      }
      &:first-child {
        td {
          border: 0;
        }
        border: 0;
      }
    }
    td.is-selected {
      background-color: transparent;
    }
    .el-calendar-day {
      height: auto;
      text-align: center;
    }
    .el-calendar-day {
      text-align: center;
      width: 33px;
      height: 33px;
      border-radius: 100%;
      margin: 0 auto 10px auto;
    }
    .is-today {
      div {
        background: $blue;
        color: $white;
      }
    }
    //     .el-calendar-day:hover {
    //       color: $blue;
    //     }
  }
  .next{
    .el-calendar-day:hover{
      background:white;
      cursor:default;
    }
    p{
      display: none;
    }
  }
  .prev{
    p{
      display: none;
    }
  }
}
.el-tooltip__popper.is-light {
  border: 0;
  color: $blue;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  &::after {
    content: '';
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    left: 50%;
    bottom: -5px;
    margin-left: -5px;
    transform: rotate(45deg);
  }
}
</style>
