<template>
  <div>
    <el-dialog
        class="tojoy-dialog__index"
      :visible.sync="display"
      width="560px"
      center
      :close-on-click-modal="false"
      :before-close="
        () => {
          handleBeforeClose('cancel');
        }
      "
    >
      <template #title>
        <div class="tojoy-dialog__header--title">
          <span>提交考评</span>
        </div>
      </template>
      <div class="tojoy-dialog__body--form">
        <el-form ref="dialogForm" >
          <el-form-item class="form-item" label="本月工作亮点">
            <el-input
                class="pleaseWrite"
                v-model="formData.highlight"
                type="textarea"
                resize="none"
                rows="5"
                show-word-limit
                maxlength="300"
                placeholder="本月工作亮点（选填,最多300字）"
            >
            </el-input>
          </el-form-item>
          <el-form-item class="form-item" label="本月待改进点">
            <el-input
                class="pleaseWrite"
                v-model="formData.improve"
                type="textarea"
                resize="none"
                rows="5"
                show-word-limit
                maxlength="300"
                placeholder="本月待改进点（选填,最多300字）"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="
              () => {
                handleBeforeClose('ok');
              }
            "
            >立即提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script >
export default {
  name: 'target-index-eval',
  components: {},
  props: {
    display: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    // 初始化数据
    Object.keys(this.formData).map((item) => {
      this.formData[item] = null;
    });
  },
  methods: {
    handleBeforeClose(val) {
      this.$emit('before-close', val);
    },
  },
  watch: {
    display(val) {
      // 初始化数据
      if (val)
        Object.keys(this.formData).map((item) => {
          this.formData[item] = null;
        });
    },
  },
};
</script>
