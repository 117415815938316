<template>
  <div class="tojoy-details-mine-view">
    <tojoy-title-content v-if="details.evalType == 2">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick"></tojoy-tabs>
        </div>
      </template>
    </tojoy-title-content>
    <section class="tab-comment">
      <div class="rightDownContent">
        <div class="tojoyDate">
          <span class="tojoyMonth">{{ details.month }}</span>
          <span class="tojoyYear">{{ details.year }}</span>
        </div>
        <!--指标详情-->
        <div class="threeDetail" @scroll="scrollDivTop">
          <div v-for="(item, index) in details.targetAssembleOutputs" :key="index" v-show="tabName === tabNameArray[index]" class="tabs-box">
            <div>
              <!--header-->
              <target-details-title-a
                :dataSoure="
                  (data => {
                    // 单双线-数据缓存
                    targetAssembleOutputs[tabNameArray[index]] = item
                    // 单双线- 分类划分detailId
                    detailId[tabNameArray[index]] = item.detailId
                    let userInfo = {
                      avatar,
                      userName,
                      jobName,
                      userId
                    }
                    let _data = { ...{}, ...data, ...item, ...userInfo }
                    return _data
                  })(titleDataSourcea)
                "
              ></target-details-title-a>
              <!--考评流程header说明-->
              <target-details-title-b
                :dataSoure="
                  (() => {
                    let _data = {}
                    _data = {
                      ...{
                        title: '考评流程',
                        seleName: evalList[index] && evalList[index][0] && evalList[index][0].checkName
                      },
                      ...item
                    }
                    return _data
                  })()
                "
                @details-sele-open="handleSeleOpen"
              ></target-details-title-b>
            </div>
            <div class="main">
              <div class="mainLeft">
                <div v-if="item.targetIndexOutputs.length">
                  <target-details-lists
                    v-for="(list, index) in item.targetIndexOutputs"
                    :key="index"
                    :formConfig="targetConfigList[list.type - 1]"
                    :dataSoure="
                      (data => {
                        data.state = item.state
                        return data
                      })(list)
                    "
                    @handle-lists-button="handleListsButtonCallbck"
                  ></target-details-lists>
                  <div class="work-month" v-if="targetAssembleOutputs[tabName] && (targetAssembleOutputs[tabName].state == 4 || targetAssembleOutputs[tabName].state == 5)">
                    <template v-if="details.highlight">
                      <h3 class="work-month__title">本月工作亮点</h3>
                      <p class="work-month__desc">
                        {{ details.highlight }}
                      </p>
                    </template>
                    <template v-if="details.improve">
                      <h3 class="work-month__title">本月待改进点</h3>
                      <p class="work-month__desc">
                        {{ details.improve }}
                      </p>
                    </template>
                  </div>
                </div>
                <div class="not-data" v-else>
                  <img :src="notDataImg" alt="" class="not-data__img" />
                  <div class="not-data__txt">
                    <p>请先添加指标</p>
                    <p v-if="details.targetAssembleOutputs[0].targetIndexOutputs.length" @click="copyEvaData">
                      或者
                      <span> 同步载入考评{{ index === 0 ? '2' : '1' }}指标 </span>
                    </p>
                    <div
                      class="not-data__add-btn target-add-btn"
                      @click="
                        getCurrentEvalDetail()
                        targtUriType = 'add'
                        createRadioTarget = true
                      "
                      v-if="(targetAssembleOutputs[tabName] && targetAssembleOutputs[tabName].state == 1) || (targetAssembleOutputs[tabName] && targetAssembleOutputs[tabName].state == 6)"
                    >
                      <p><i class="el-icon-plus" /></p>
                      <span>添加指标</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="targetAssembleOutputs[tabName] && targetAssembleOutputs[tabName].targetIndexOutputs.length">
            <div
              class="not-button target-add-btn"
              @click="
                getCurrentEvalDetail()
                targtUriType = 'add'
                createRadioTarget = true
              "
              v-if="(targetAssembleOutputs[tabName] && targetAssembleOutputs[tabName].state == 1) || (targetAssembleOutputs[tabName] && targetAssembleOutputs[tabName].state == 6)"
            >
              <p><i class="el-icon-plus" /></p>
              <span>添加指标</span>
            </div>
          </template>
        </div>
        <!--右边流程图-->
        <div class="record-process">
          <h3>流程记录</h3>
          <div class="record-process__box">
            <tojoy-process-record :recordNodeData="processData" v-if="processData.length">
              <template slot-scope="scope">
                <!-- <div>{{ scope.list }}</div> -->
                <div>
                  <p class="name">
                    {{ scope.list.name }}
                  </p>
                  <p class="content">
                    {{ scope.list.content }}
                  </p>
                </div>
              </template>
            </tojoy-process-record>
            <div v-else>
              <tojoy-blank :blank-data="{ type: 'noProcess' }" />
              <div class="set_process">
                <button class="set_process_btn" @click="windowPopstate = true">立即设置</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="tojoy-target-button">
      <reback-button
        v-if="showReOperateBtn"
        :sourceData="{
          state: targetAssembleOutputs[tabName] && targetAssembleOutputs[tabName].state,
          date: details.year + '年' + details.month + '月',
          targetId: uriPathQuery.targetId
        }"
        :showReOperateBtn="showReOperateBtn"
        @handle-withdraw="
          data => {
            getTargetDetails()
          }
        "
      ></reback-button>
      <el-button
        v-prevent-click
        v-if="(targetAssembleOutputs[tabName] && targetAssembleOutputs[tabName].state == 1) || (targetAssembleOutputs[tabName] && targetAssembleOutputs[tabName].state == 6)"
        type="primary"
        class="submit"
        @click="saveConfirmTarget"
        :disabled="calculationTotalweight().state"
        >提交确认<span>({{ calculationTotalweight().num || 0 }}</span
        >%)</el-button
      >
      <el-button type="primary" class="submit" @click="isPassSelfEva" v-if="showEvaBtn">提交考评</el-button>
    </div>

    <target-index-create :display="createRadioTarget" @before-close="handleRadioNext" @lib-click="displayLib = true" :dateStr="dateStr" :year="details.year + ''" :month="details.month + ''" />
    <target-index-write
      :display="writeTargetState"
      :formData="formData"
      :titleTxtType="targtUriType"
      @before-close="submitTargetIndexSave"
      :dateStr="dateStr"
      :dateObj="{ year: details.year + '', month: details.month + '' }"
      ref="targetIndexWrite"
    />
    <target-index-score :display="displayScore" :formData="formData" :maxScoreNumber="maxNumberScore" @before-close="submitTargetIndexScore"></target-index-score>
    <target-index-progress :display="displayProgress" :formData="formData" @before-close="submitTargetIndexProgress"></target-index-progress>
    <target-index-eval :display="displayEval" :formData="formEvaData" @before-close="submitTargetEval"></target-index-eval>
    <target-index-lib :display="displayLib" @before-close="handleConfromLib"></target-index-lib>
    <tojoy-eval-selected :form="seleData" :tabBar="[seleData.evalType]" :display="windowPopstate" title="修改考评流程" @before-close="saveTargetFlowData" />
  </div>
</template>

<script>
import { MessageBox, Message } from 'element-ui'
import { homeusedapp } from '@/config/config.js'
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyProcessRecord from '@/components/business/process-record'
import TargetIndexCreate from '@/components/mytarget/target-index-create'
import TargetIndexWrite from '@/components/mytarget/target-index-write'
import TargetIndexLib from '@/components/mytarget/target-index-lib'
import TargetIndexScore from '@/components/mytarget/target-index-score'
import TargetIndexEval from '@/components/mytarget/target-index-eval'
import TargetIndexProgress from '@/components/mytarget/target-index-progress'
import TargetDetailsTitleA from '@/components/mytarget/details-title-a'
import rebackButton from '@/components/mytarget/reback-button'
import TargetDetailsTitleB from '@/components/mytarget/details-title-b'
import TargetDetailsLists from '@/components/mytarget/details-list'
import TojoyEvalSelected from '@/components/mytarget/target-eval-selected'
import TojoyTabs from '@/components/business/tabs'
import TojoyBlank from '@/components/business/blank/blank'

import {
  ApiTargetDetails,
  ApiTargetFlowLogList,
  ApiTargetIndexSave,
  ApiTargetDelete,
  ApiTargetEdit,
  ApiTargetScore,
  ApiTargetProgress,
  ApiTargetSave,
  ApiTargetSubmitEval,
  ApiTargetFlowSave /**考评流程保存 */
} from '@/api/api_target'
import { ApiDimensionScore, ApiTargetConfigForm, ApiTargetFlowList, ApiTargetSync } from '../../api/api_target'
import { mapState } from 'vuex'
export default {
  name: 'thrclear-mine',
  components: {
    TojoyTitleContent,
    TojoyProcessRecord,
    TargetIndexCreate,
    TargetIndexWrite,
    TargetIndexScore,
    TargetIndexProgress,
    TargetDetailsTitleA,
    TargetDetailsTitleB,
    TargetDetailsLists,
    TargetIndexEval,
    TojoyEvalSelected,
    TargetIndexLib,
    TojoyTabs,
    rebackButton,
    TojoyBlank
  },
  data() {
    return {
      notDataImg: require('@/assets/images/300.png'),
      uriPathQuery: this.$route.query,
      homeusedapp: homeusedapp,
      targetAssembleOutputs: {},
      detailId: {},
      targtUriType: 'add',
      seleData: {
        evalType: 1
      },
      displayLib: false,
      windowPopstate: false,
      displayScore: false,
      displayProgress: false,
      displayEval: false,
      createRadioTarget: false,
      writeTargetState: false,
      tabName: 'approve',
      tabNameArray: ['approve', 'comment'],
      // 详情数据集合
      details: {},
      // 详情标题头 -带用户信息 一级
      titleDataSourcea: {
        headImg: require('@/assets/images/300.png'),
        headUrl: '/',
        state: 5,
        speed: 90
      },

      processData: [],
      tabs: [
        {
          name: 'approve',
          label: '考评1'
        },
        {
          name: 'comment',
          label: '考评2'
        }
      ],
      // 指标新增表单数据
      formData: {
        countYearId: null, // 年度目标的id
        detailId: null,
        finishTime: '',
        frequency: '',
        id: null,
        isLib: 0,
        level: '',
        name: '',
        planFinishDate: '',
        planHour: null,
        progress: null,
        selfScore: null,
        targetId: null,
        taskDesc: '',
        isPositive: true, //是否正数
        taskUnit: '',
        taskValue: '',
        type: 1,
        weight: null,
        workResult: '',
        bindLevel: '', // 页面绑定的指标级别值
        createTime: '',
        planFinishDateStr: ''
      },
      targetConfigList: [],
      // 目标提交考评表单数据
      formEvaData: {
        highlight: '',
        improve: ''
      },
      sourceScoreData: {},
      maxNumberScore: 100,
      evalList: [] // 考评流程数组
    }
  },
  computed: {
    ...mapState({
      avatar: state => state.userInfo.avatar,
      userName: state => state.userInfo.userName,
      jobName: state => (state.userInfo.jobList ? state.userInfo.jobList[0]?.jobName : ''),
      userId: state => state.userInfo.userId
    }),
    dateStr() {
      return `${this.details?.year}年${this.details?.month}月`
    },
    showEvaBtn() {
      if (this.details?.targetAssembleOutputs?.length) {
        for (let i = 0; i < this.details.targetAssembleOutputs.length; i++) {
          let element = this.details.targetAssembleOutputs[i]
          if (element.state != '3') {
            return false
          }
        }
        return true
      } else {
        return false
      }
    },
    showReOperateBtn() {
      if (this.details.evalType == 1) {
        return true
      }
      if (this.details.evalType == 2) {
        let state1 = this.details.targetAssembleOutputs[0].state - 0
        let state2 = this.details.targetAssembleOutputs[1].state - 0
        if ((state1 == 1 || state1 == 6) && (state2 == 1 || state2 == 6)) {
          return true
        }
        if ((state1 == 2 || state1 == 3) && (state2 == 2 || state2 == 3)) {
          return true
        }
      }
      return false
    }
  },
  mounted() {
    this.initFormData = JSON.parse(JSON.stringify(this.formData))
    this.getTargetDetails()
    this.targetQueryConfigForm()
    this.getTargetFlowList(this.uriPathQuery.targetId)
  },
  methods: {
    scrollDivTop(div) {
      this.scroll = div
    },
    scrollTop() {
      this.scroll.target.scrollTop = 0
    },
    // 获取考评流程
    async getTargetFlowList(tId) {
      this.evalList = []
      const { code, data } = await ApiTargetFlowList({
        targetId: tId
      })
      if (code === '000000') {
        if (data && data.list.length) {
          data.list.forEach((item, index) => {
            this.evalList.splice(this.evalList.length, 0, item.targetFlowDetailOutputList)
          })
        }
      }
    },
    async targetQueryConfigForm() {
      const { code, data } = await ApiTargetConfigForm({
        type: ''
      })
      if (code === '000000') {
        data.list.forEach(list => {
          let eleObj = {}
          list.map(item => {
            eleObj[item.codeName] = item
          })
          this.targetConfigList.push(eleObj)
        })
      }
    },
    // 提交考评-判断是否自评完
    isPassSelfEva() {
      for (let k = 0; k < this.details.targetAssembleOutputs.length; k++) {
        let list = this.details.targetAssembleOutputs[k].targetIndexOutputs
        for (let i = 0; i < list.length; i++) {
          if (list[i].selfScore != 0 && !list[i].selfScore) {
            Message.error('请先完成所有指标自评')
            return
          }
        }
      }
      this.displayEval = true
    },
    // 目标确认 状态[state= 1 || 6]
    async saveConfirmTarget() {
      // 导入数据 未设置流程的
      if (!this.processData.length) {
        this.windowPopstate = true
        setTimeout(() => {
          Message({
            message: '请完善考评流程',
            type: 'error',
            customClass: 'zZindex'
          })
        }, 500)
        return
      }
      // 兼容双线权重0%指标占比问题
      try {
        this.details.targetAssembleOutputs.forEach(item => {
          if (item.targetIndexOutputs?.length) {
            let result = this.saveBeforeCalSingle(item)
            if (result.state) {
              Message.error('双线权重须都为100%方可提交')
              throw new Error('跳出循环')
            }
          } else {
            Message.error('双线权重须都为100%方可提交')
            throw new Error('跳出循环')
          }
        })
        const { code, data, msg } = await ApiTargetSave({
          targetId: this.uriPathQuery.targetId - 0
        })
        if (code === '000000') {
          this.$router.back()
        }
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 指标提交 state=3
     */
    async submitTargetEval(type) {
      if (type === 'ok') {
        const { code, msg } = await ApiTargetSubmitEval({
          ...{
            targetId: this.uriPathQuery.targetId - 0
          },
          ...this.formEvaData
        })
        if (code === '000000') {
          Message.success('自评提交成功')
          setTimeout(() => {
            this.displayEval = false
            this.$router.replace({ path: '/mytarget/mine' })
          }, 1000)
        }
        return
      }

      this.displayEval = false
    },
    /**
     * 新增&编辑指标提交
     */
    async submitTargetIndexSave(params) {
      let { type, currentWeight } = params
      let funcRequestUri = {
        add: ApiTargetIndexSave,
        edit: ApiTargetEdit
      }
      if (type === 'ok') {
        // 校验单线权重是否合理
        let objData = this.calculateSingleWeight(this.getCurrentEvalDetail(), currentWeight)
        if (objData.num > 100) {
          Message.error('您添加的指标权重大于100%')
          return
        }
        this.formData.isLib = this.formData.isLib ? 1 : 0
        let params = JSON.parse(JSON.stringify(this.formData))
        this.$delete(params, 'updateTime')
        this.$delete(params, 'createTime')
        if (params.planFinishDate) {
          params.planFinishDate = params.planFinishDate.replace(/\./g, '-')
        } else {
          this.$delete(params, 'planFinishDate')
        }
        // 如果选择负数 拼接 '-'
        if (!params.isPositive) {
          params.taskDesc = '-' + params.taskDesc || ''
        }
        const { code, msg } = await funcRequestUri[this.targtUriType](params)
        if (code === '000000') {
          Message.success('提交成功')
          setTimeout(() => {
            this.targtUriType = 'add'
            this.getTargetDetails()
            this.writeTargetState = false
            this.createRadioTarget = false
          }, 1000)
        } else {
          Message({
            message: msg,
            type: 'error'
          })
        }
        return
      }

      this.writeTargetState = false
    },

    // 考评流程查询
    async getTargetFlowLogListQuery(tId) {
      const { code, data } = await ApiTargetFlowLogList({
        detailId: tId,
        source: 1 //  source  (1、我的目标详情页面 2、领导端查看目标详情页面)
      })
      if (code === '000000') {
        this.processData = data
      }
    },
    /**
     * 详情数据
     */
    async getTargetDetails() {
      const { code, data } = await ApiTargetDetails({
        targetId: this.uriPathQuery.targetId - 0
      })
      if (code === '000000') {
        this.details = data

        if (this.details?.evalType === 2) {
          this.details?.targetAssembleOutputs?.map((_t, index) => {
            this.$set(this.tabs, index, {
              ...this.tabs[index],
              evalWeight: _t.evalWeight,
              label: index === 0 ? `考评${index + 1}(权重${_t.evalWeight}%)` : `考评${index + 1}`
            })
          })
        }

        // 获取后台配置最高分数
        this.getMaxScore(this.details.isOrg)
        // 考评流程 1｜2
        this.seleData.evalType = this.details.evalType
        this.$nextTick(() => {
          // 获取考评流程
          let obj = JSON.parse(JSON.stringify(this.detailId))
          let id = obj[this.tabName]
          this.getTargetFlowLogListQuery(id - 0)
        })
      }
    },
    // 返回当前考评数据
    getCurrentEvalDetail() {
      let index = this.tabNameArray.indexOf(this.tabName)
      return this.details.targetAssembleOutputs[index]
    },
    // 获取配置最高分
    async getMaxScore(isOrg) {
      let { code, data } = await ApiDimensionScore({ code: 'target' })
      if (code === '000000') {
        this.maxNumberScore = isOrg ? data.orgScore : data.score
      }
    },

    /**
     * 单个指标删除
     */
    async toTargetDelete(data) {
      const { code } = await ApiTargetDelete({ indexId: data.id })
      if (code === '000000') {
        Message.success('删除成功')
        this.getTargetDetails()
      }
    },
    /**
     * 单个指标编辑
     */
    async toTargetEdit(data) {
      this.targtUriType = 'edit'
      this.settingFormsData(data)
      this.writeTargetState = true
    },

    /**
     * 双线流程-同步考评1的数据
     */
    copyEvaData() {
      MessageBox.confirm('确定同步考评1指标吗', '提示', {
        confirmButtonText: `确定`,
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          ApiTargetSync({
            targetId: this.details.targetId,
            detailId: this.details.targetAssembleOutputs[1].detailId
          }).then(res => {
            if (res.code == '000000') {
              Message.success('同步载入成功')
              this.getTargetDetails()
            } else {
              Message.error(res.msg)
            }
          })
        })
        .catch(() => {
          Message.info(`已取消操作`)
        })
    },

    /**
     * 目标自评分
     */
    async submitTargetIndexScore(type) {
      if (type === 'ok') {
        if (!this.formData.selfScore && this.formData.selfScore !== 0) {
          Message.error('请填写自评分数')
          return
        }
        const { code, msg } = await ApiTargetScore({
          indexId: this.formData.indexId,
          selfScore: this.formData.selfScore,
          workResult: this.formData.workResult
        })
        if (code === '000000') {
          Message.success('提交成功')
          setTimeout(() => {
            this.displayScore = false
            this.getTargetDetails()
          }, 1000)
        } else {
          Message.error(msg)
        }
        return
      } else {
        this.recoverData()
      }
      this.displayScore = false
    },

    /**
     * 目标进度更新
     */
    async submitTargetIndexProgress(type) {
      let newFormData = JSON.parse(JSON.stringify(this.formData))
      if (type === 'ok') {
        if (newFormData.type == 1 && newFormData.taskValue == '') {
          Message.error('指标完成值不能为空')
          return
        }
        // 如果选择负数 拼接 '-'
        if (!this.formData.isPositive) {
          newFormData.taskValue = '-' + newFormData.taskValue || ''
          newFormData.isPositive = false
        }
        const { code, msg } = await ApiTargetProgress({
          indexId: newFormData.indexId,
          progress: newFormData.progress,
          taskValue: newFormData.taskValue
        })
        if (code === '000000') {
          Message.success('提交成功')
          setTimeout(() => {
            this.displayProgress = false
            this.getTargetDetails()
          }, 1000)
        } else {
          Message.error(msg)
        }
        return
      } else {
        this.recoverData()
      }
      this.displayProgress = false
    },
    recoverData() {
      Object.assign(this.formData, this.sourceScoreData)
    },

    /**
     * 保存列表数据
     */
    async saveTargetFlowData(type, formData) {
      if (type === 'cancel') this.windowPopstate = false
      if (type === 'ok') {
        let targetId = this.uriPathQuery.targetId - 0
        targetId ? (formData.targetId = targetId) : ''
        const { code } = await ApiTargetFlowSave(formData)
        if (code === '000000') {
          this.windowPopstate = false
          this.getTargetDetails()
          this.getTargetFlowList(this.uriPathQuery.targetId)
        }
      }
    },

    // 计算总权重
    calculationTotalweight() {
      let num = 0
      this.details.targetAssembleOutputs.forEach(item => {
        let evalWeight = item.evalWeight / 100
        item.targetIndexOutputs.forEach(element => {
          num += element.weight * evalWeight
        })
      })
      return {
        num: num,
        state: num != 100 ? true : false
      }
    },

    // 新增、编辑指标计算单线权重
    calculateSingleWeight(targetSingleItem, currentWeight = 0) {
      let num = 0
      let id = null
      if (this.targtUriType == 'add') {
        num = currentWeight
      }
      if (this.targtUriType == 'edit') {
        id = this.formData.id
      }
      targetSingleItem.targetIndexOutputs.forEach(element => {
        if (this.targtUriType == 'edit' && element.id == id) {
          num += currentWeight
        } else {
          num += element.weight
        }
      })
      return {
        num: num,
        state: num != 100 ? true : false
      }
    },
    // 提交计算单线权重
    saveBeforeCalSingle(targetSingleItem) {
      let num = 0
      targetSingleItem.targetIndexOutputs.forEach(element => {
        num += element.weight
      })
      return {
        num: num,
        state: num != 100 ? true : false
      }
    },
    /**
     * 选择指标库确认
     */
    handleConfromLib(type, data) {
      if (type === 'ok') {
        this.displayLib = false
        this.createRadioTarget = false
        this.writeTargetState = true
        this.settingFormsData({
          ...{
            targetId: this.uriPathQuery.targetId - 0,
            detailId: this.detailId[this.tabName]
          },
          ...data
        })
        return
      }
      this.displayLib = false
    },

    // 详情列表组件按钮事件监听
    handleListsButtonCallbck(type, data) {
      switch (type) {
        case '__EDIT':
          this.toTargetEdit(data)
          break
        case '__DEL':
          MessageBox.confirm(' 确定删除？', '删除指标', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.toTargetDelete(data)
          })
          break
        case '__SCORE':
          this.formData = { ...{}, ...this.formData, ...this.initFormData, ...data }
          this.formData.selfScore = data.selfScore - 0 >= 0 ? data.selfScore : undefined
          this.formData.workResult = data.workResult
          this.formData.indexId = data.id
          this.sourceScoreData = JSON.parse(JSON.stringify(this.formData))
          this.displayScore = true
          break
        case '__PRO':
          this.formData = { ...{}, ...this.formData, ...this.initFormData, ...data }
          this.formData.indexId = data.id
          this.formData.ype = data.type
          this.sourceScoreData = JSON.parse(JSON.stringify(this.formData))
          this.displayProgress = true
          // 如果是负数
          if (Number(this.formData.taskValue) < 0) {
            this.formData.taskValue = this.formData.taskValue.slice(1)
            this.formData.isPositive = false
          }
          break
      }
    },

    /**
     * 填写指标-选择指标类型 下一步
     */
    handleRadioNext(type, data) {
      let params = {
        ...this.initFormData,
        ...{
          weight: null,
          targetId: this.uriPathQuery.targetId - 0,
          type: data.type,
          detailId: this.detailId[this.tabName]
        }
      }
      if (type === 'ok') {
        this.settingFormsData(params)
        this.writeTargetState = true
        return
      }
      this.createRadioTarget = false
    },

    // form数据默认配置
    settingFormsData(defVal = {}) {
      this.formData = { ...this.formData, ...defVal }
      // console.log('form',this.formData);
      if (this.targtUriType == 'edit' && this.formData.planFinishDate) {
        this.formData.planFinishDate = this.formData.planFinishDate.replace(/\./g, '-')
      }
      // 如果是负数
      if (Number(this.formData.taskDesc) < 0) {
        this.formData.taskDesc = this.formData.taskDesc.slice(1)
        this.formData.isPositive = false
      }
    },

    handleTabClick(tab) {
      if (this.details?.evalType === 2) {
        this.tabs.map((_tab, index) => {
          _tab.label = _tab.name === tab.name ? `考评${index + 1}(权重${_tab.evalWeight}%)` : `考评${index + 1}`
          return _tab
        })
      }

      this.tabName = tab.paneName
      this.$nextTick(() => {
        // 获取考评流程
        let obj = JSON.parse(JSON.stringify(this.detailId))
        let id = obj[this.tabName]
        this.getTargetFlowLogListQuery(id - 0)
        this.scrollTop()
      })
    },
    handleSeleOpen() {
      this.windowPopstate = true
    }
  }
}
</script>

<style lang="scss" scoped>
.#{$css-prefix}-details-mine-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .set_process {
    width: 167px;
    margin: 40px auto 0;
  }
  .set_process_btn {
    width: 100%;
    border-radius: 3px;
    border: 1px solid rgba(61, 126, 255, 0.5);
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    color: #3d7eff;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: content-box;
    outline: none;
    margin: 0;
    transition: 0.1s;
    font-weight: 400;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 12px 0px;
    font-size: 14px;
  }
  /deep/ .el-tabs__header {
    margin: 0px;
  }
  /deep/ .el-tabs__nav {
    /*padding-bottom: 11px;*/
  }
  /deep/ .el-tabs__item {
    height: 0;
    line-height: 0;
  }
  .wordColor {
    width: 97px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: $white;
    margin-left: 15px;
    i {
      margin-right: 7px;
    }
  }
  .dateColor {
    background: $blue;
    border: 1px solid $blue;
  }
  .weekColor {
    background: $green;
    border: 1px solid $green;
  }
  .tab-comment {
    height: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    &__main {
      flex: 1;
      height: 100%;
      margin: 20px 30px;
      background: $white;
      overflow: auto;
      overflow-x: hidden;
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: $white;
      height: 80px;
      padding: 0 30px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      p {
        font-size: 12px;
        font-weight: 400;
        color: $yellow;
        line-height: 12px;
        margin-right: 20px;
      }
      .el-button {
        height: 44px;
      }
    }
  }
  .tojoy-pagination-list {
    flex: 1;
  }

  .rightDownContent {
    height: 100%;
    background: $white;
    margin: 20px 30px;
    display: flex;
    border-radius: 5px;
    overflow: auto;
    .tojoyDate {
      width: 50px;
      height: 50px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
      border-radius: 3px;
      opacity: 0.99;
      border: 1px solid #eeeeee;
      /*position: absolute;*/
      text-align: center;
      margin: 13px 20px 15px;
    }
    .tojoyYear {
      width: 29px;
      height: 10px;
      font-size: 12px;

      font-weight: 400;
      color: $grey;
      line-height: 10px;
    }
    .tojoyMonth {
      display: block;
      width: 21px;
      height: 16px;
      font-size: 20px;
      font-weight: 600;
      color: $lightblack;
      line-height: 16px;
      margin-left: 14px;
      margin-top: 7px;
    }
  }
  .record-process {
    width: 380px;
    box-shadow: -1px 0px 0px 0px $linecolor;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &__box {
      overflow: auto;
      height: 100%;
      margin-left: 20px;
      p {
        &.name {
          font-size: 14px;
          font-weight: 400;
          color: $darkgrey;
          line-height: 32px;
        }
        &.content {
          font-size: 12px;
          font-weight: 400;
          color: $grey;
          line-height: 22px;
        }
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: $lightblack;
      line-height: 78px;
      padding-left: 20px;
    }
  }
  .threeDetail {
    flex: 1;
    display: flex;
    flex-direction: column;
    /*overflow: hidden;*/
    overflow: auto;
    .tabs-box {
      display: flex;
      flex-direction: column;
      /*overflow: hidden;*/
    }
    .work-month {
      width: 98%;
      margin: 0 15px;
      color: $darkgrey;
      /*overflow: auto;*/
      &__title {
        font-size: 15px;
        line-height: 15px;
        font-weight: bold;
        color: $lightblack;
        padding: 20px 0 8px;
      }
      &__desc {
        font-size: 14px;
        line-height: 28px;
        font-weight: 400;
        box-shadow: 0px 1px 0px 0px $linecolor;
        padding: 0 0 20px 0;
      }
      &__desc:last-of-type {
        box-shadow: 0px 0px 0px 0px $linecolor;
      }
    }
  }

  .num {
    width: auto;
    padding: 0px 5px;
    color: $lightblack;
    border: 1px solid $d9d9d9;
    margin-right: 15px;
    background: transparent;
    i {
      color: $grey;
      margin: 0;
    }
    span {
      color: $blue;
    }
  }

  .tl {
    text-align: left;
  }
  .tr {
    text-align: right;
  }

  .main {
    height: 100%;
    background: #fff;
    overflow: auto;
  }
  .mainLeft {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    background: #fff;
    padding-left: 15px;
    border-right: 1px solid #eee;
    box-sizing: border-box;
  }

  .personnelItem {
    flex: 1;
  }
  .personnelItem img {
    width: 46px;
    height: 46px;
    vertical-align: middle;
    border-radius: 100%;
    margin: 15px 10px 15px 15px;
  }
  .personnelItem .personnelInfo {
    display: inline-block;
    width: 60%;
    vertical-align: middle;
    margin-left: 1vh;
  }
  .personnelItem .personnelInfo .name {
    font-size: 16px;
    color: #333;
    font-weight: bold;
  }
  .personnelItem .personnelInfo .post {
    font-size: 13px;
    color: #999;
  }

  .not-data {
    width: 100%;
    margin-bottom: 30px;
    &__add-btn {
      cursor: pointer;
      width: 92px;
      height: 50px;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 25px;
      border: 1px solid rgba(158, 190, 255, 0.5);
      color: #3d7eff;
      text-align: center;
      margin: 25px auto;
      i {
        vertical-align: -4px;
        font-weight: 600;
        font-size: 16px;
      }
      span {
        font-size: 12px;
      }
    }
    &__img {
      display: block;
      width: 150px;
      height: 131px;
      margin: 60px auto 40px auto;
    }
    &__txt {
      font-size: 14px;
      font-weight: 400;
      color: $darkgrey;
      text-align: center;
      p {
        &:last-child {
          margin-top: 15px;
        }
      }
      span {
        cursor: pointer;
        color: $blue;
      }
    }
  }
  .not-button {
    position: absolute;
    bottom: 94px;
    left: 50%;
    margin-left: -46px;
    cursor: pointer;
    width: 92px;
    height: 50px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 5px 10px 0px rgba(63, 127, 254, 0.2);
    border-radius: 25px;
    border: 1px solid rgba(158, 190, 255, 0.5);
    color: $blue;
    text-align: center;
    i {
      vertical-align: -4px;
      font-weight: 600;
      font-size: 16px;
    }
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
  .title-contents {
    padding: 0;
    text-align: right;
    .submit {
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      font-weight: 400;
      color: $white;
      background: $blue;
      padding: 0 50px;
      margin: 20px 30px 20px 0;
    }
    .is-disabled {
      color: #fff;
      background-color: #b1cbff;
      border-color: #b1cbff;
    }
  }
  /deep/ .title-content .content-bottom {
    min-height: 0px;
  }
  /deep/ .title-content .content-top {
    min-height: 0px;
  }
  .target-add-btn:hover {
    color: #ffffff !important;
    background: #3d7eff !important;
    box-shadow: 0px 5px 10px 0px rgba(63, 127, 254, 0.2) !important;
    border-radius: 25px !important;
    border: 1px solid rgba(158, 190, 255, 0.5) !important;
    span {
      color: #ffffff;
    }
  }
}
</style>
