<script lang="jsx">
export default {
  name: 'tojoy-technological-number',
  props: {
    numberNodeData: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  data() {
    return {
      node: [],
    };
  },
  mounted() {

    this.node =  this.numberNodeData;
    this.$nextTick(()=>{
      document.querySelectorAll('.tojoy-technological-number .el-timeline-item__node .itc').forEach((element,index) => {
        element.innerText = index += 1;
      });
    })


  },
  methods: {
  },

  watch: {

  },

render() {
    const {$scopedSlots,$slots} = this;
    console.log(this);
    return (

    <div class="tojoy-technological-number">
     <el-timeline>
        {
          this.node.length &&  this.node.map((item,index) => {
            const { icon, color, size,leveName } = item
            return (
              <el-timeline-item 
                  color={color}
                  size={size}
                  icon={icon}>
              <div class='tojoy-technological__title'>
                  <span> {leveName } </span>
                 
                  {
                    $scopedSlots.select({
                        data: item
                      })
                  }
              </div>
               {
                    $scopedSlots.exec({
                        data: item
                      })
                }
               
             </el-timeline-item>
            )
          })
        }
      </el-timeline>
     </div>
    )
  }
}
</script>

<style lang="scss">
.#{$css-prefix}-technological-number {
  .el-timeline-item__node--large {
    left: -2px;
    width: 22px;
    height: 22px;

    .itc {
      font-weight: 600;
      color: $white;
    }
  }
  .el-timeline-item__tail {
    left: 8px;
    border-left: 2px solid $blue;
  }
  .el-timeline-item__wrapper {
    position: relative;
    padding-left: 40px;
    top: 0;
    span {
      font-weight: 600;
      color: #333333;
      font-size: 16px;
    }
  }
}
</style>
