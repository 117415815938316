<template>
  <div class="tojoy-details__lists">
    <div class="pubWrap">
      <div class="pubTop">
        <p :class="`pubIcon  ${targetTypes[dataSoure.type].cl}`">{{targetTypes[dataSoure.type].txt}}</p>
        <div class="pubTitle">
          <span class="data-title">{{ dataSoure.name }}</span>
          <p v-if="dataSoure.state == 1 || dataSoure.state == 6">
            <span class="hover" @click="handleToListsButton('__DEL', dataSoure)"
              ><i class="el-icon-delete" />删除</span
            >
            <span class="line">|</span>
            <span class="hover" @click="handleToListsButton('__EDIT', dataSoure)"
              ><i class="el-icon-edit-outline" />编辑</span
            >
          </p>
          <!--自评 stateType不存在来自于自评页面-->
          <p v-if="dataSoure.state == 3 && !stateType" class="self-value">
            <span class="hover" @click="handleToListsButton('__SCORE', dataSoure)"
              ><i
                class="el-icon-edit-outline"
            />自评<span class="color-blue" v-if="dataSoure.selfScore == 0 || dataSoure.selfScore">{{ dataSoure.selfScore }}分</span></span
            >
            <span class="line">|</span>
            <span class="color-blue hover" @click="handleToListsButton('__PRO', dataSoure)"
              ><i class="icon iconfont  iconupdateProcess" style="font-size: 14px"/>更新进度</span
            >
          </p>
          <!--待考评-->
          <p v-if="(dataSoure.selfScore!=null && dataSoure.selfScore>=0 && dataSoure.state != 3 && dataSoure.state != 2 && dataSoure.state != 1 && dataSoure.state != 6) || (stateType=='2-1' || stateType=='2-2') " class="self-value">
            <span v-if="dataSoure.state == 5 && dataSoure.selfScore!=0 && !dataSoure.selfScore">未自评</span>
            <span v-else>自评<span class="color-blue" >{{ dataSoure.selfScore }}</span>分</span
            >
          </p>
        </div>
      </div>
      <div class="level">
        <span :class="['levelValue', levelStyle[dataSoure.levelSort]]" v-if="dataSoure.level && formConfig['level']">{{ dataSoure.level }}</span>
        <span :style="{'margin-left':dataSoure.level? '' :'0px'}" v-if="formConfig['weight']">{{ formConfig['weight'].showName }}：{{ dataSoure.weight }}%</span>
        <span>进度：{{ dataSoure.progress || 0}}%</span>
        <span v-if="dataSoure.type == 3&&dataSoure.frequency&& formConfig['frequency']"
          >{{ formConfig['frequency'].showName }}：{{ dataSoure.frequency  }}</span
        >
        <span v-if="dataSoure.planFinishDate && formConfig['planFinishDate']">{{ formConfig['planFinishDate'].showName }}：{{ dataSoure.planFinishDate | filterDate }}</span>
        <span v-if="dataSoure.planHour && formConfig['planHour']">{{ formConfig['planHour'].showName }}：{{ dataSoure.planHour }}小时</span>
        <span v-if="dataSoure.countYearId">来源：{{ dataSoure.countYearName }}</span>
      </div>
      <div class="listWrap">
        <p v-if="dataSoure.type == 1">
          完成值/指标值：<span class="c-fe831a"
            >{{ dataSoure.taskValue-0 || 0 }}{{ dataSoure.taskUnit }}</span
          >/{{ dataSoure.taskDesc }}{{ dataSoure.taskUnit }}
        </p>
        <pre v-else class="list" v-html="dataSoure.taskDesc"></pre>
      </div>
      <div class="rezult" v-if="dataSoure.workResult">
        <p class="resultTitle">工作成果</p>
        <pre class="list" v-html="dataSoure.workResult"></pre>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'target-details-lists',
  components: {},
  props: {
    dataSoure: {
      type: Object,
      default: () => {
        return {};
      },
    },
    stateType: {
      type: String,
      default : '',
    },
    formConfig: {
      type: Object,
      default: () => {
        return {};
      },
    }
  },
	filters: {
		filterDate (item) {
			return item.replace(/\./,'年').replace(/\./,'月')+'日';
		},
	},
  data() {
    return {
      selfDisplayState: false,
      listHtml: '1、了解幸福绩效产品需求<br/>2、调研同行业竞品，整理并分析',
      targetTypes: {
        1: {
          cl: 'bg3',
          txt: '定量',
        },
        2: {
          cl: 'bg2',
          txt: '定性',
        },
        3: {
          cl: 'bg1',
          txt: '例行',
        }
      },
      levelStyle: {
        '1': 'level-red',
        '2': 'level-pink',
        '3': 'level-orange',
        '4': 'level-yellow',
        '5': 'level-grey'
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    handleToListsButton(type, data) {
      this.$emit('handle-lists-button', type, data);
    },
    handleBeforeClose() {},
  },
};
</script>

<style lang="scss" scoped>
.#{$css-prefix}-details {
  &__lists {
    .c-fe831a {
      color: $yellow;
    }
    .bg1 {
      background: $blue;
    }
    .bg2 {
      background: $yellow;
    }
    .bg3 {
      background: $green;
    }

    .pubWrap {
      width: 100%;
      padding-bottom: 20px;
      box-shadow: 0px 1px 0px 0px $linecolor;
    }
    .pubTop {
      display: flex;
      .score{
        color: #3D7EFF;
      }
    }
    .pubTop .pubIcon {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      margin-top: 12px;
      font-size:12px ;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .pubTitle {
      width: 50%;
      margin: 16px 0 0 10px;
      font-size: 16px;
      color: $lightblack;
      font-weight: bold;
      flex: 1;
      position: relative;
      display: flex;
      .data-title {
        flex: 1;
        margin-right: 300px;
      }
      p {
        position: absolute;
        top: 0;
        right: 30px;
        font-size: 15px;
        font-weight: 400;
        color: $darkgrey;
        i {
          margin-right: 10px;
        }
        span {
          &.line {
            padding: 0 20px;
            color: $grey600;
          }
        }
        span.hover:hover{
          color: $blue;
          cursor: pointer;
        }
      }
    }
    .self-value{
      .color-blue{
        color: #3D7EFF
      }
    }
    .pubDetail {
      font-size: 14px;
      color: #666;
      line-height: 20px;
    }
    .level {
      margin: 15px 0 23px 0;
      padding-left: 36px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
    }
    .level span {
      margin-left: 20px;
      color: $darkgrey;
    }
    .levelValue {
      display: inline-block;
      margin-left: 0 !important;
      min-width: 34px;
      padding: 0 5px;
      line-height: 20px;
      text-align: center;
      background: #F4F4F4;
      color: #999999;
    }
    .level{
      .level-red{
        background: #F7EBEC;
        color: #B23949;
      }
      .level-pink{
        background: #FFEDF0;
        color: #FF526A;
      }
      .level-orange{
        background: #FFF1ED;
        color: #FF7652;
      }
      .level-yellow{
        background: #FFF6ED;
        color: #FFAB52;
      }
      .level-grey{
        background: #F4F4F4;
        color: #999999;
      }
    }
    .listWrap {
      padding: 0 34px;
      box-sizing: border-box;
      font-size: 15px;
      font-weight: 400;
      pre{
        white-space: pre-wrap;
        word-wrap: break-word;
      }
      .list{
        font-size: 15px;
        line-height: 30px;
      }
    }
    .list {
      line-height: 1.5;
      font-size: 15px;
      color: $lightblack;
    }
    .rezult {
      margin: 20px 0;
      padding: 0 34px;
      box-sizing: border-box;
    }
    .rezult .resultTitle {
      color: $lightblack;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
