<template>
  <div class="tojoy-dialog__write" v-if="display">
    <el-dialog
      class="tojoy-dialog-index"
      :visible.sync="display"
      width="800px"
      :close-on-click-modal="false"
      :before-close="
        () => {
          handleBeforeClose('cancel')
        }
      "
    >
      <el-dialog width="560px" title="对齐年度目标" :visible.sync="innerVisible" custom-class="target-align-dialog" append-to-body>
        <target-align :dataList="targetList" @choose-target="chooseTarget"></target-align>
      </el-dialog>

      <template #title>
        <div class="tojoy-dialog__write--header">
          <span class="resName">{{ titleTxt[titleTxtType] }}{{ typeTxt[formData.type] }}指标</span><span class="resNum">{{ dateStr }}</span>
        </div>
      </template>
      <!--body-->
      <section class="tojoy-dialog__body--form tojoy-dialog__write--body" v-if="formConfig">
        <el-form :model="formData" :show-message="true" inline-message ref="ruleForm" label-width="180px" label-position="top" class="reset-form">
          <div class="form-list">
            <!--指标名称-->
            <div class="item" v-if="formConfig['name']">
              <el-form-item
                :label="formConfig['name'].showName"
                :rules="[
                  {
                    required: formConfig['name'].isRequired,
                    message: `${formConfig['name'].showName}不能为空`,
                    trigger: ['change', 'blur']
                  }
                ]"
                prop="name"
              >
                <el-input
                  v-filteremoji
                  v-loadFocus
                  type="textarea"
                  maxlength="50"
                  placeholder="请填写"
                  v-model="formData.name"
                  :autosize="{ minRows: 1, maxRows: 3 }"
                  resize="none"
                  :show-word-limit="false"
                >
                </el-input>
                <!--对齐年度目标-->
                <div class="target-align__button--add" @click="innerVisible = true" v-if="formData.type == 1 && targetList.length"><i class="el-icon-plus"></i><span>对齐年度目标</span></div>
              </el-form-item>
            </div>
            <!--指标值 pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,7})?$/,-->
            <div class="item" style="position: relative" v-if="formConfig['name'] && formData.type == 1">
              <el-form-item
                class="tojoyTextareaSpe"
                :label="formConfig['taskDesc'].showName"
                :rules="[
                  {
                    required: formConfig['taskDesc'].isRequired,
                    message: getMessageTip('taskDesc'),
                    trigger: ['change', 'blur']
                  },
                  {
                    pattern: /^(\-|\+)?\d+(\.\d+)?$/,
                    message: '指标值为整数或者小数',
                    trigger: ['change', 'blur']
                  }
                ]"
                prop="taskDesc"
              >
                <div style="margin:10px 0">
                  <el-radio-group v-model="formData.isPositive" size="mini" class="radio-group">
                    <el-radio :label="true" border
                      >正数
                      <img v-if="formData.isPositive" class="okimg" src="@/assets/images/target/selected_ok@2x.png" alt="" />
                    </el-radio>
                    <el-radio :label="false" border
                      >负数
                      <img v-if="!formData.isPositive" class="okimg" src="@/assets/images/target/selected_ok@2x.png" alt="" />
                    </el-radio>
                  </el-radio-group>
                </div>
                <el-input v-only-number="{ length: 6 }" :maxlength="15" class="pleaseWrite" v-model="formData.taskDesc" placeholder="请填写" controls-position="right" :controls="false"> </el-input>
              </el-form-item>
              <el-select v-model="formData.taskUnit" placeholder="请选择" class="tojoyMony input-unit input-position" @click.stop>
                <el-option v-for="(item, index) in taskOptions" :key="index" :label="item.key" :value="item.key"> </el-option>
              </el-select>
            </div>
            <!--指标任务-->
            <div class="item" v-if="formConfig['taskDesc'] && (formData.type == 2 || formData.type == 3)">
              <el-form-item
                :label="formConfig['taskDesc'].showName"
                :rules="[
                  {
                    required: formConfig['taskDesc'].isRequired,
                    message: getMessageTip('taskDesc'),
                    trigger: ['change', 'blur']
                  }
                ]"
                prop="taskDesc"
              >
                <el-input
                  type="textarea"
                  v-filteremoji
                  :rows="5"
                  resize="none"
                  :placeholder="`请填写关键任务及考核标准\n1、...\n2、...\n3、...`"
                  :disabled="formConfig['taskDesc'] ? false : true"
                  v-model="formData.taskDesc"
                  :show-word-limit="false"
                  maxlength="800"
                >
                </el-input>
              </el-form-item>
            </div>
            <!--权重-->
            <div class="item" v-if="formConfig['weight']">
              <el-form-item
                :label="formConfig['weight'].showName"
                :rules="[
                  {
                    required: formConfig['weight'].isRequired,
                    message: getMessageTip('weight'),
                    trigger: ['change', 'blur']
                  }
                ]"
                prop="weight"
              >
                <el-select v-model="formData.weight" placeholder="请选择" class="tojoyMony input-select" style="width: 100%">
                  <el-option v-for="item in weightOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!--频率-->
            <div class="item" v-if="formConfig['frequency']">
              <el-form-item
                :label="formConfig['frequency'].showName"
                :rules="[
                  {
                    required: formConfig['frequency'].isRequired,
                    message: getMessageTip('frequency'),
                    trigger: ['change', 'blur']
                  }
                ]"
                prop="frequency"
              >
                <el-select v-model="formData.frequency" placeholder="请选择" class="tojoyMony input-select" style="width: 100%">
                  <el-option v-for="item in frequencyOptions" :key="item.key" :label="item.key" :value="item.key"> </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!--计划完成时间-->
            <div class="item date" v-if="formConfig['planFinishDate']">
              <el-form-item
                :label="formConfig['planFinishDate'].showName"
                :rules="[
                  {
                    required: formConfig['planFinishDate'].isRequired,
                    message: getMessageTip('planFinishDate'),
                    trigger: ['change', 'blur']
                  }
                ]"
                prop="planFinishDate"
              >
                <el-date-picker
                  v-model="formData.planFinishDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerBeginDateBefore"
                  type="date"
                  prefix-icon="null"
                  placeholder="选择日期"
                  style="width: 100%"
                ></el-date-picker>
              </el-form-item>
            </div>
            <!--所需工时-->
            <div class="item" v-if="formConfig['planHour']">
              <el-form-item
                prop="planHour"
                :label="formConfig['planHour'].showName"
                :rules="[
                  {
                    required: formConfig['planHour'].isRequired,
                    message: getMessageTip('planHour'),
                    trigger: ['change', 'blur']
                  },
                  { validator: validatorPlanHour }
                ]"
              >
                <el-input v-only-number="{ length: 2 }" placeholder="请填写所需工时，最低0.5" v-model="formData.planHour" style="width: 100%" :maxlength="hourMaxLength"></el-input>
                <span class="tojoyTime">小时</span>
              </el-form-item>
            </div>
            <!--指标级别-->
            <template v-if="formData.bindLevel">
              <div class="item tojoyLevel-level-show" v-if="formConfig['level']">
                <el-form-item :label="formConfig['level'].showName">
                  <span :class="['tojoyLevel', levelStyle[colorSort + 1]]"
                    ><el-select v-model="formData.bindLevel" placeholder="请选择" style="width: 100%" class="tojoyMony input-select" @change="changeLevel">
                      <el-option v-for="item in levelOptions" :key="item.key" :label="item.key" :value="item.key"> </el-option> </el-select></span
                  ><span class="tojoyCom" v-if="formData.bindLevel && levelTxt">{{ levelTxt }}</span></el-form-item
                >
              </div>
            </template>
            <template v-else>
              <div class="item" v-if="formConfig['level']">
                <el-form-item
                  prop="bindLevel"
                  :label="formConfig['level'].showName"
                  validate-o
                  :rules="[
                    {
                      required: formConfig['level'].isRequired,
                      message: getMessageTip('level'),
                      trigger: 'change'
                    }
                  ]"
                >
                  <span>
                    <el-select v-model="formData.bindLevel" placeholder="用于快速定位关键指标" class="tojoyMony input-select level-input" style="width: 100%" @change="changeLevel">
                      <el-option v-for="(item, index) in levelOptions" :key="item.key" :label="item.key" :value="item.key">
                        <span :class="['level-desc', levelStyle[index + 1]]"> {{ item.key }}</span
                        ><span>{{ item.desc }}</span>
                      </el-option>
                    </el-select>
                  </span>
                </el-form-item>
              </div>
            </template>
            <div class="item">
              <el-checkbox v-model="formData.isLib">同步到指标库</el-checkbox>
            </div>
          </div>
        </el-form>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            v-preventClick
            type="primary"
            @click="
              () => {
                handleBeforeClose('ok')
              }
            "
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ApiTargetConfigForm } from '@/api/api_target'
import TargetAlign from '../../annual-target/target-align'
import { ApiTargetAlignListByMonth, ApiTargetFormConfig } from '../../../api/api_target'
import { Message } from 'element-ui'
import {cloneDeep} from 'lodash'
export default {
  name: 'target-index-write',
  components: { TargetAlign },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          bindLevel: null
        }
      }
    },
    display: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    titleTxtType: {
      type: String,
      default: () => {
        return 'add'
      }
    },
    dateStr: {
      type: String,
      default: () => {
        return ''
      }
    },
    dateObj: Object,
    default: () => {
      return {
        year: '',
        month: ''
      }
    }
  },
  data() {
    return {
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() < new Date(new Date() - 24 * 60 * 60 * 1000).getTime() //如果当天可选，就不用减8.64e7
        }
      },
      formConfig: null,
      titleTxt: {
        add: '添加',
        edit: '编辑'
      },
      typeTxt: {
        1: '定量',
        2: '定性',
        3: '例行'
      },
      configTypeObj: {
        unit: 'taskOptions', // 单位 unit
        frequency: 'frequencyOptions', // 频率frequency
        important: 'levelOptions' // 级别important
      },
      levelStyle: {
        '1': 'level-red',
        '2': 'level-pink',
        '3': 'level-orange',
        '4': 'level-yellow',
        '5': 'level-grey'
      },
      colorSort: '',
      innerVisible: false, // 对齐目标dialog
      taskOptions: [],
      frequencyOptions: [],
      levelOptions: [],
      levelTxt: '',
      targetList: [],
      weightOptions: Object.keys(Array.apply(null, { length: 21 }))
        .map(item => {
          let num = 0
          return {
            value: num + item * 5,
            label: num + item * 5 + '%'
          }
        })
        .reverse()
    }
  },
  computed: {
    hourMaxLength() {
      // let index = this.formData.planHour?.toString().indexOf('.')
      // let len = this.formData.planHour?.toString().length
      // return index>-1 ?  this.formData.planHour?.toString().slice(index,len).length+3 : 3
      return 6
    }
  },
  created() {},
  mounted() {
    this.getTargetAlignList(this.year, this.month)
    this.$nextTick(() => {
      this.formData.taskDesc = ''
      this.formData.planHour = ''
      this.formData.taskUnit = ''
    })
  },
  methods: {
    validatorPlanHour(rule, value, callback) {
      if (value == null || value == undefined || value == '') {
        callback()
        return
      }
      if (value - 0 > 300) {
        callback(new Error('累计所需工时不得超过300'))
      } else if (value - 0 < 0.5) {
        callback(new Error('最小工时0.5'))
      } else {
        callback()
      }
    },
    changeLevel() {
      let obj = this.levelOptions.find((item, index) => {
        if (item.key == this.formData.bindLevel) {
          this.colorSort = index
        }
        return item.key == this.formData.bindLevel
      })
      this.levelTxt = obj?.desc || ''
    },
    init() {
      this.getTargetFormConfig('unit')
      this.getTargetFormConfig('frequency')
      this.getTargetFormConfig('important')
      this.targetQueryConfigForm()
      // 值充值
      this.formData.isLib = !!this.formData.isLib
    },
    async getTargetFormConfig(type) {
      const { data } = await ApiTargetFormConfig({ group: type })
      this[this.configTypeObj[type]] = data.list
      // 指标文案回显
      // 指标文案回显
      if (type == 'important') {
        let obj = this.levelOptions.find((item, index) => {
          return item.key == this.formData.level
        })
        if (obj) {
          this.formData.bindLevel = this.formData.level
          this.levelTxt = obj.desc || ''
        } else {
          this.bindLevel = null
          this.levelTxt = ''
        }
      }
      if (type == 'unit') {
        this.formData.taskUnit = this.formData.taskUnit ? this.formData.taskUnit : data.list[0]?.key
      }
    },
    async targetQueryConfigForm() {
      const { code, data } = await ApiTargetConfigForm({
        type: this.formData.type - 0
      })
      if (code === '000000') {
        this.formConfig = {}
        data.list.forEach(element => {
          if (element.isEnabled) {
            this.formConfig[element.codeName] = element
          }
        })
      }
    },
    handleBeforeClose(val) {
      let params = {
        type: val
      }
      if (val == 'ok') {
        this.formData.level = this.formData.bindLevel || ''
        this.$refs['ruleForm'].validate(valid => {
          if (valid) {
            params.currentWeight = this.formData.weight
            this.$emit('before-close', params)
          } else {
            return false
          }
        })
      } else {
        this.formData.isPositive = true
        this.$emit('before-close', params)
      }
    },
    getMessageTip(key) {
      let msg = ''
      if (this.formConfig[key].codeName == 'weight' || this.formConfig[key].codeName == 'planFinishDate') {
        msg = `请设置${this.formConfig[key].showName}`
      } else if (this.formConfig[key].showName == '指标') {
        msg = `${this.formConfig[key].showName}名称不能为空`
      } else if (this.formConfig[key].codeName == 'level') {
        msg = `请选择${this.formConfig[key].showName}`
      } else if (this.formConfig[key].codeName == 'taskDesc') {
        msg = `${this.formConfig[key].showName}不能为空`
      } else {
        msg = `请填写${this.formConfig[key].showName}`
      }
      return msg
    },
    // 获取对齐年度目标
    async getTargetAlignList(year, month) {
      let res = await ApiTargetAlignListByMonth({ year, month })
      if (res.code == '000000') {
        this.targetList = res.data.list || []
        if (this.targetList.length) {
          this.targetList = this.targetList.map(item => {
            if (this.formData.countYearId == item.id) {
              this.$set(item, 'choosed', true)
            } else {
              this.$set(item, 'choosed', false)
            }
            return item
          })
        }
      }
    },
    chooseTarget(data) {
      this.formData.name = data.name
      this.formData.taskUnit = data.unit
      this.formData.taskDesc = data.planValue + ''
      this.formData.countYearId = data.id
      this.innerVisible = false
    },
    dealTargetAlignList(val) {
      let index = this.targetList.findIndex(item => {
        return item.id === val
      })
      this.targetList[index].choosed = true
      this.targetList.map(item => {
        if (item.id === val) {
          item.choosed = true
        } else {
          item.choosed = false
        }
      })
    }
  },
  watch: {
    display(val) {
      val && this.init()
    },
    innerVisible(val) {
      val && this.getTargetAlignList(this.dateObj.year - 0, this.dateObj.month - 0)
    },
    'dateObj.month'(val) {
      val && this.getTargetAlignList(this.dateObj.year - 0, this.dateObj.month - 0)
    },
    'formData.countYearId': function(val) {
      val && this.dealTargetAlignList(val)
    },
    'formData.bindLevel': function() {
      this.changeLevel()
    }
  }
}
</script>
<style lang="scss" scoped>
.#{$css-prefix}-dialog {
  &__write {
    .reset-form .form-list {
      margin-top: 0px;
      background: #ffffff;
      padding-left: 0px;
      /deep/ .el-input-number.is-without-controls .el-input__inner {
        padding: 0px;
        text-align: left;
      }
    }
    /deep/ .el-form-item__content {
      position: relative;
    }
    /deep/ .el-dialog__body {
      overflow: auto;
      height: 512px;
      padding: 10px 30px 20px 30px;
    }
    /deep/ .el-dialog__header {
      background: $white;
      border-bottom: 1px solid #f2f2f2;
    }
    /deep/ .el-dialog__footer {
      z-index: 10;
      height: 64px;
      text-align: center;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 16px 0;
      position: relative;
    }
    &--header {
      width: 100%;
      position: relative;
      .resName {
        display: inline-block;
        font-size: 18px;
        font-weight: bold;
        color: $lightblack;
      }
      .resNum {
        font-size: 14px;
        font-weight: 400;
        color: $grey;
        margin-left: 15px;
        display: inline-block;
        line-height: 32px;
      }
    }
    &--body {
      .el-radio-group {
        display: flex;
      }
      .tojoyMony.input-select {
        /deep/ .el-input__inner {
          background: none;
          text-align: left;
        }
      }
      .tojoyLevel-level-show {
        /deep/ .el-form-item__error {
          display: none;
        }
      }
      .tojoyMony.input-unit {
        position: absolute;
        top: 76px;
        left: 150px;
        // top: 16px;
        // left: 140px;
        width: 80px;
        height: 32px;
        background: #f7f7f7;
        border-radius: 2px;
        line-height: 32px;
        /deep/ .el-input {
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-input__inner {
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          margin: 0px;
        }
        /deep/ .el-input--suffix .el-input__inner {
          padding-right: 10px;
          text-align: center;
        }
      }
      .tojoyTextareaSpe {
        width: 230px;
        position: relative;
        /deep/.el-form-item__label::after {
          content: '（可选择指标值类型，默认为正数）';
          position: absolute;
          width: 200px;
          color: #999999;
        }
        .el-form-item__content {
          .radio-group {
            .el-radio.is-bordered.is-checked {
              background-color: #ebf3ff;
            }
            /deep/ .el-radio {
              width: 70px;
              height: 32px;
              padding: 8px 15px 0 10px;
              margin-right: 10px;
              position: relative;
              .el-radio__input {
                display: none;
              }
              .el-radio__label {
                margin-top: 4px;
                font-size: 14px;
                .okimg {
                  position: absolute;
                  right: 5px;
                  bottom: 5px;
                  width: 8px;
                  height: 6px;
                }
              }
            }
          }
        }
        /deep/ .pleaseWrite{
          height: 40px;
         .el-input__inner{
            padding-bottom: 20px !important;
          }
        }
      }
      // 指标级别样式
      .tojoyLevel {
        width: 50px;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 400;
        display: inline-block;
        text-align: center;
        /deep/ .el-input__inner {
          height: 20px;
          border-radius: 3px;
          font-size: 11px;
          font-weight: 400;
          display: inline-block;
          text-align: center !important;
          padding-right: 10px;
          line-height: 20px;
        }
        /deep/ .el-input__icon {
          line-height: 20px;
          height: 20px;
          width: 20px;
        }
        .tojoyMony.level-input {
          text-align: center !important;
          width: 32px;
        }
        .tojoyMony.input-select /deep/ .el-input__suffix {
          right: 0;
          top: 0px;
        }
      }
      .level-red .tojoyMony.input-select /deep/ .el-input__inner {
        background: #f7ebec;
        color: #b23949;
      }
      .level-pink .tojoyMony.input-select /deep/ .el-input__inner {
        background: #ffedf0;
        color: #ff526a;
      }
      .level-orange .tojoyMony.input-select /deep/ .el-input__inner {
        background: #fff1ed;
        color: #ff7652;
      }
      .level-yellow .tojoyMony.input-select /deep/ .el-input__inner {
        background: #fff6ed;
        color: #ffab52;
      }
      .level-grey .tojoyMony.input-select /deep/ .el-input__inner {
        background: #f4f4f4;
        color: #999999;
      }
      .tojoyCom {
        height: 15px;
        font-size: 15px;
        font-weight: 400;
        color: $darkgrey;
        line-height: 15px;
        display: inline-block;
        margin-left: 15px;
      }
      .tojoyTime {
        font-size: 15px;
        position: absolute;
        left: 180px;
        top: 19px;
      }
      .target-align__button--add {
        position: absolute;
        top: -5px;
        right: 0px;
        padding: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        cursor: pointer;
      }
      .target-align__button--add:hover {
        color: #3d7eff;
      }
    }
    /deep/ .el-button--primary {
      width: 120px;
      height: 32px;
      border-radius: 3px;
      line-height: 32px;
    }
    /deep/ .el-button {
      padding: 0;
    }
  }
}
.target-align-dialog {
  .el-dialog__header {
    border-bottom: 1px solid #f2f2f2;
  }
  .el-dialog__body {
    padding-top: 19px;
  }
}
</style>
