<template>
  <div class="tojoy-meeting-mine-view">
    <tojoy-title-content title="两会">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="meetTypeId" :tabs="tabs" @tab-click="handleTabClick" />
          <div class="colorContain">
            <el-button
              class="elBtn"
              v-for="(item, index) in homeusedapp.filter(h => h.scen.includes('meeting'))"
              :key="index"
              :type="item.btnType"
              :icon="item.icon"
              @click="
                $router.push({
                  path: item.path
                })
              "
              >{{ item.name }}</el-button
            >
          </div>
        </div>
      </template>
    </tojoy-title-content>
    <section class="tab-comment">
      <div class="rightDownContent">
        <div class="threeDetail">
          <tojoy-pagination-list
            ref="tojoy-pagination-list-ref"
            :data="completeData"
            :loading="loading"
            :currentPage="page"
            :total="total"
            @page-change="pageChange"
          >
            <template #item="{ data }">
              <div class="detailDown">
                <div class="detailInfo">
                  <!-- 会议名称 -->
                  <p v-if="data.meetName !== '' && data.meetName !== null" class="tomTitleMessage">
                    {{ data.meetName }}
                  </p>
                  <!-- 会议开始时间 -->
                  <p class="smallText" v-if="data.startTime !== '' && data.startTime !== null">
                    {{ formListState.type4.startTime.showName }}：{{ data.startTime }}
                  </p>
                  <!-- 会议类型 -->
                  <p class="smallText">
                    {{ formListState.type4.meetTypeId.showName }}：{{ data.meetTypeName }}
                  </p>
                  <!-- 会议纪要 -->
                  <p v-if="data.meetSummary" class="todayTitle">
                    {{ formListState.type4.meetSummary.showName }}
                  </p>
                  <ul v-if="data.meetSummary" class="todayList">
                    <li>{{ data.meetSummary }}</li>
                  </ul>
                  <!-- 会议决议列表 -->
                  <p
                    v-if="data.resolutionOutputList && data.resolutionOutputList.length > 0"
                    class="todayTitle"
                  >
                    {{ formListState.type4.addMeet.showName }}
                  </p>
                  <div v-if="data.resolutionOutputList && data.resolutionOutputList.length > 0">
                    <div v-for="(item, index) in data.resolutionOutputList" :key="index">
                      <p class="tojoyP">
                        {{ item.title }}
                      </p>
                      <p>
                        <span class="content-text__small">责任人：</span>
                        <span
                          class="content-text__small timeName"
                          v-for="(it, ind) in item.executor"
                          :key="ind"
                          >{{ it.userName }}</span
                        >
                        <span class="content-text__small">到期日期：{{ item.endDate }}</span>
                      </p>
                      <div v-if="item.state == 3" class="messageIng tojoyOver">已完成</div>
                      <div v-if="item.state == 1" class="messageIng tojoyUn">未开始</div>
                      <div v-if="item.state == 4" class="messageIng tojoyUn">已过期</div>
                      <div v-if="item.state == 2" class="messageIng">进行中</div>
                    </div>
                  </div>
                  <p v-if="data.fileUrls && data.fileUrls.length > 0" class="todayTitle">附件</p>
                  <div class="extendlist">
                    <a
                      @click="downFile(im)"
                      class="fileHad"
                      v-for="(im, ix) in data.fileUrls"
                      :key="ix"
                    >
                      <span v-if="'doc,docx'.includes(im.fileType)" class="fileIcon Wcolor">W</span>
                      <span v-else-if="'pdf'.includes(im.fileType)" class="fileIcon Pcolor">P</span>
                      <span v-else-if="'xls, xlsx'.includes(im.fileType)" class="fileIcon Xcolor"
                        >X</span
                      >
                      <span v-else-if="'txt'.includes(im.fileType)" class="fileIcon Tcolor">T</span>
                      <span v-else-if="'png,jpg,gif'.includes(im.fileType)" class="fileIcon Jcolor"
                        ><i class="iconfont iconimg"></i
                      ></span>
                      <span v-else class="fileIcon NOcolor">?</span>
                      <span class="fileMessage">{{ im.fileName }}</span>
                    </a>
                    <!-- <div class="fileHad">
                      <span class="fileIcon">P</span>
                      <span class="fileMessage">20201208周会会议纪要.doc</span>
                    </div>
                    <div class="fileHad">
                      <span class="fileIcon">?</span>
                      <span class="fileMessage">20201208周会会议纪要.doc</span>
                    </div>
                    <div class="fileHad">
                      <span class="fileIcon">X</span>
                      <span class="fileMessage">20201208周会会议纪要.doc</span>
                    </div>
                    <div class="fileHad">
                      <span class="fileIcon">T</span>
                      <span class="fileMessage">20201208周会会议纪要.doc</span>
                    </div>
                    <div class="flexhad">
                      <span class="bg"><i class="el-icon-picture"></i></span>
                      <span class="fileMessage">20201208周会会议纪要.doc</span>
                    </div> -->
                  </div>
                  <!-- 参会人员 -->
                  <p
                    v-if="
                      (data.joinMember && data.joinMember.length > 0) ||
                        (data.leaveMember && data.leaveMember.length > 0) ||
                        (data.overMember && data.overMember.length > 0) ||
                        (data.absentMember && data.absentMember.length > 0)
                    "
                    class="todayTitle"
                  >
                    {{ formListState.type4.joinMember.showName }}
                  </p>
                  <p class="tojoyP">
                    <span
                      v-for="(t, i) in dataProcessing(
                        data.joinMember,
                        data.leaveMember,
                        data.overMember,
                        data.absentMember
                      )"
                      :key="i"
                    >
                      <!-- 程孝先、</span><span>任溶溶</span><span class="tojoyRed">(迟到) -->
                      <span>{{ t.userName }}</span>
                      <span class="tojoyRed" v-if="t.type == 2">(请假)</span>
                      <span class="tojoyRed" v-if="t.type == 3">(迟到)</span>
                      <span class="tojoyRed" v-if="t.type == 4">(缺席)</span>
                      <span class="point">、</span>
                    </span>
                  </p>
                  <div class="time-wrap">
                    <span class="time">{{ data.updateTimeStr }}</span>
                    <p>
                      <span class="label">提交人:</span>
                      <span class="username">{{ data.updateUserName }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </tojoy-pagination-list>
        </div>
        <div class="threeDate">
          <div class="calendar-title">
            <span>两会统计</span>
            <span class="el-dropdown-link">
              <el-date-picker
                :clearable="false"
                size="mini"
                @change="datechange"
                value-format="yyyy-MM"
                v-model="statisticsDate"
                type="month"
                placeholder="选择月"
                prefix-icon="el-icon-arrow-down"
              >
              </el-date-picker>
            </span>
          </div>
          <div class="calendar-conts">
            <tojoy-calendar :dateList="dateList" :statisticsDate="statisticsDate" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { homeusedapp } from '@/config/config.js'
import { Message } from 'element-ui'
import {
  listmeetinfo,
  listmeetypes,
  meetcalendartips,
  meetRedtips,
  meetDelredtips
} from '@/api/thrclear.js'
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyPaginationList from '@/components/business/pagination-list'
import TojoyCalendar from '@/components/common/calendar'
import TojoyTabs from '@/components/business/tabs'
export default {
  name: 'thrclear-mine',
  components: {
    TojoyTitleContent,
    TojoyPaginationList,
    TojoyCalendar,
    TojoyTabs
  },
  data() {
    return {
      formListState: {},
      completeData: [],
      tabs: [],
      meetTypeId: '0',
      checkList: ['日清'],
      value: '',
      homeusedapp: homeusedapp,
      page: 1,
      size: 10,
      dateList: [],
      statisticsDate: '',
      total: 0,
      loading: false
    }
  },
  computed: {},
  async created() {
    this.loading = true
    this.getnowDate()
    await this.listmeetypes()
    this.listmeetinfo(this.getqueryListmeet())
    this.meetRedtips()
    this.meetcalendartips(String(this.statisticsDate.replace(/-/gi, '')))
  },
  mounted() {},
  methods: {
    //
    meetDelredtips(data) {
      meetDelredtips(data).then(res => {
        if (res && res.code !== '000000') {
          Message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    pageChange(val) {
      this.loading = true
      this.page = val
      this.listmeetinfo(this.getqueryListmeet())
    },
    // 点击会议列表的文件
    downFile(val) {
      window.open(val.fileUrl)
    },
    meetRedtips(index) {
      meetRedtips().then(res => {
        if (res && res.code === '000000') {
          if (res.data) {
            let list = res.data.list
            for (let j = 0; j < this.tabs.length; j++) {
              for (let i = 0; i < list.length; i++) {
                if (
                  list[i].isTips === 1 &&
                  Number(list[i].meetTypeId) === Number(this.tabs[j].name)
                ) {
                  this.$set(this.tabs[j], 'badge', true)
                }
              }
            }
          } else {
            for (let i = 0; i < this.tabs.length; i++) {
              this.$set(this.tabs[i], 'badge', false)
            }
          }
          if (index) {
            this.$set(this.tabs[index], 'badge', false)
          }
        }
      })
    },
    dataProcessing(joinMember = [], leaveMember = [], overMember = [], absentMember = []) {
      let joinMemberarr = joinMember
        ? joinMember.map(item => {
            item.type = 1
            return item
          })
        : []
      let leaveMemberarr = leaveMember
        ? leaveMember.map(item => {
            item.type = 2
            return item
          })
        : []
      let overMemberarr = overMember
        ? overMember.map(items => {
            items.type = 3
            return items
          })
        : []
      let absentMemberarr = absentMember
        ? absentMember.map(items => {
            items.type = 4
            return items
          })
        : []
      let peoplearr = [...leaveMemberarr, ...overMemberarr, ...joinMemberarr, ...absentMemberarr]
      // const leaveMemberarr = leaveMember?.map(l => l.userId) ?? []
      // const overMemberarr = overMember?.map(l => l.userId) ?? []
      // let peoplearr = joinMember.map(res => {

      //   if (leaveMemberarr.includes(res.userId)) {
      //     res.type = 2
      //   } else if (overMemberarr.includes(res.userId)) {
      //     res.type = 3
      //   }
      //   return res
      // })
      return peoplearr
    },
    async handleTabClick({ label, name, index }) {
      let itemTabs = this.tabs.find(item => item.name === name)
      if (itemTabs.badge) {
        this.meetDelredtips({ meetTypeId: name, meetTypeName: label })
      }
      this.page = 1
      this.loading = true
      this.$refs['tojoy-pagination-list-ref'].resetScrollTop()
      await this.listmeetinfo(this.getqueryListmeet())
      this.meetRedtips(index)
    },
    /**
     * 查询会议类型
     */
    async listmeetypes() {
      const { code, data } = await listmeetypes()
      if (code === '000000') {
        let _default = {
          meetTypeName: '全部',
          meetTypeId: 0
        }
        this.tabs = [_default, ...(data?.list ?? [])].map((l, index) => {
          return {
            name: String(l.meetTypeId),
            label: l.meetTypeName,
            index: index
          }
        })
      } else {
        Message({
          message: '会议类型查询失败！',
          type: 'error'
        })
      }
    },
    // 获取查询会议记录的请求参数
    getqueryListmeet() {
      let meetTypeName = ''
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabs[i].name == this.meetTypeId) {
          meetTypeName = this.tabs[i].label
        }
      }
      let data = {
        // 会议类型ID 全部传0，其他传具体的类型ID
        meetTypeId: this.meetTypeId,
        // 会议类型名称
        meetTypeName: meetTypeName,
        //	当前页
        page: this.page,
        // 页大小
        size: this.size
      }
      return data
    },
    // APP-查询会议记录列表[宋红飞]
    listmeetinfo(data, options = {}) {
      this.completeData = []
      listmeetinfo(data, options).then(res => {
        if (res && res.code === '000000') {
          if (res.data) {
            this.total = res.data.total
            this.completeData = res.data.list
            this.formListState = res.data.formConfigMap
          } else {
            this.total = 0
          }
        }
        this.loading = false
      })
    },
    /**
     * 获取当前年月
     */
    getnowDate() {
      const myDate = new Date()
      let month = Number(myDate.getMonth()) + 1
      if (month < 10) {
        month = '0' + month.toString()
      }
      this.statisticsDate = myDate.getFullYear() + '-' + month
    },
    meetcalendartips(val) {
      meetcalendartips({ monthDate: val }).then(res => {
        if (res && res.code === '000000') {
          if (res.data) {
            this.dateList = res.data.list
          }
        }
      })
    },
    datechange() {
      this.meetcalendartips(String(this.statisticsDate.replace(/-/gi, '')))
    }
  }
}
</script>

<style lang="scss">
.#{$css-prefix}-meeting-mine-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .tojoy-pagination-list {
    flex: 1;
    margin-right: 1px;
    &__wrap {
      margin: 0;
      padding: 0;
    }
    &__notdata {
      margin: 0;
    }
    &__loading {
      margin: 0;
    }
  }
  .iconblue,
  .iconorange {
    font-size: 14px;
    padding-right: 7px;
  }
  .tab-comment {
    height: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    &__main {
      flex: 1;
      margin: 20px 30px 0;
      margin-bottom: 20px;
      background: $white;
      overflow: auto;
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: $white;
      height: 80px;
      padding: 0 30px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      p {
        font-size: 12px;
        font-weight: 400;
        color: $yellow;
        line-height: 12px;
        margin-right: 20px;
      }
      .el-button {
        height: 44px;
      }
    }
  }
  .tojoy-pagination-list {
    flex: 1;
  }

  .rightDownContent {
    height: 100%;
    background: $white;
    margin: 20px 30px 0;
    display: flex;
    border-radius: 5px;
    overflow: auto;
  }
  .threeDate {
    width: 380px;
    box-shadow: -1px 0px 0px 0px $linecolor;
    flex: none;
    flex-direction: column;
    display: flex;
    .el-icon-arrow-down {
      color: #666666;
    }
    .el-dropdown-link {
      cursor: pointer;
      .el-input__prefix {
        left: 80px;
        text-align: right;
      }
      cursor: pointer;
    }
    .el-date-editor {
      width: 110px;
    }
    .el-input__inner {
      cursor: pointer;
      width: 110px !important;
      border: none;
    }
    .calendar {
      &-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;
        margin-top: 20px;
      }
      &-conts {
        height: 100%;
        overflow: auto;
        display: flex;
      }
    }
  }
  .threeDetail {
    flex-grow: 1;
    overflow: auto;
  }
  /deep/.errorPage {
    width: auto;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    background: #ffffff;
  }
  .num {
    width: auto;
    padding: 0px 5px;
    color: $lightblack;
    border: 1px solid $d9d9d9;
    margin-right: 15px;
    background: transparent;
    i {
      color: $grey;
      margin: 0;
    }
    span {
      color: $blue;
    }
  }
  .tojoyRed {
    color: $red;
  }
  .extendlist {
    a {
      display: flex;
    }
    .fileHad {
      height: 40px;
      background: $white;
      border: 1px solid $linecolor;
      margin-top: 10px;
      cursor: pointer;
    }
  }
  .flexhad {
    display: flex;
    align-items: center;
    border: 1px solid $linecolor;
    margin-top: 10px;
    .bg {
      height: 40px;
      background: white;
      .el-icon-picture {
        color: #fe831a;
        font-size: 40px;
      }
    }
  }

  .tojoyP {
    font-size: 14px;
    font-weight: 400;
    color: $darkgrey;
    line-height: 20px;
    margin-bottom: 5px;
    span:last-child {
      .point {
        display: none;
      }
    }
  }
  .timeName {
    color: $blue !important;
    margin-right: 15px;
  }
  .fileMessage {
    font-size: 12px;
    font-weight: 400;
    color: $lightblack;
    line-height: 12px;
    margin-left: 10px;
    line-height: 38px;
  }
  .fileIcon {
    display: inline-block;
    width: 38px;
    height: 38px;
    // background: $blue;
    line-height: 38px;
    text-align: center;
    color: $white;
  }
  .Wcolor {
    background: #3d7eff;
  }
  .Pcolor {
    background: #ff526a;
  }
  .Xcolor {
    background: #06ae56;
  }
  .Tcolor {
    background: #795eff;
  }
  .Jcolor {
    background: #fe831a;
  }
  .NOcolor {
    background: #8199af;
  }
  .num {
    width: 124px;
    height: 32px;
    background: $white;
    border-radius: 2px;
    border: 1px solid $d9d9d9;
    margin-right: 15px;
    display: inline-block;
    line-height: 32px;
    text-align: center;
  }
  .had {
    height: 14px;
    font-size: 14px;

    font-weight: 400;
    color: $blue;
    line-height: 14px;
  }
  .el-icon-date {
    display: none !important;
  }
  .action {
    height: 13px;
  }
  .detailDown {
    position: relative;
    width: 100%;
    padding: 5px 0 20px 5px;
  }
  .all {
    // width: 30px;
    height: 15px;
    font-size: 15px;

    font-weight: 400;
    color: $blue;
    line-height: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 44px;
  }
  .time-wrap {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: $grey;
    line-height: 12px;
    .time {
      color: $grey;
    }
    .label {
      color: $darkgrey;
    }
    .username {
      color: $blue;
    }
  }
  .parent {
    position: relative;
  }
  .todaySpe {
    margin-top: 20px;
  }
  .todayTitle {
    font-size: 15px;
    font-weight: 500;
    color: $lightblack;
    line-height: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .content-text {
    &__small {
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 12px;
    }
  }
  .smallText {
    height: 12px;
    font-size: 12px;

    font-weight: 400;
    color: $darkgrey;
    line-height: 12px;
    // margin-left: 43px;
    margin-bottom: 10px;
  }
  .tomTitleMessage {
    font-size: 18px;
    font-weight: 500;
    color: $lightblack;
    line-height: 18px;
    margin-bottom: 15px;
  }
  .todayList li {
    color: $darkgrey;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .tojoyOver {
    color: $darkgrey !important;
    background: $f5f5f5 !important;
  }
  .tojoyUn {
    color: $green !important;
    background: $e6f6ee !important;
  }
  .messageIng {
    width: 47px;
    height: 20px;
    background: $ebf2ff;
    border-radius: 3px;
    font-size: 11px;

    font-weight: 400;
    color: $blue;
    line-height: 18px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
  }
  .name {
    /* width: 45px; */
    height: 15px;
    font-size: 15px;

    font-weight: 400;
    color: $lightblack;
    line-height: 15px;
    margin-top: 17px;
    margin-bottom: 10px;
  }
  .title {
    /* width: 48px; */
    height: 12px;
    font-size: 12px;

    font-weight: 400;
    color: $grey;
    line-height: 12px;
    margin-bottom: 15px;
  }
  .colorContain {
    position: absolute;
    right: 30px;
    //margin-bottom: 16px;
    .elBtn {
      width: 111px;
    }
    .el-button {
      padding: 8px 20px;
      border-radius: 2px;
    }
  }
}
</style>
