<template>
  <div>
    <el-dialog
        class="tojoy-dialog__index tojoy-dialog__create"
      :visible.sync="display"
      width="800px"
      :close-on-click-modal="false"
      :before-close="
        () => {
          handleBeforeClose('cancel');
        }
      "
    >
      <template #title>
        <div class="tojoy-dialog__create--header">
          <span class="resName">添加指标类型</span
          ><span class="resNum">{{ dateStr }}</span>
          <div class="tojoyAddStyle target-operate-wrap">
            <el-button
                @click="handleOpenLib"
            ><i class="el-icon-document-add" style="font-size: 14px" />
              指标库选择</el-button>
          </div>
        </div>
      </template>
      <section class="tojoy-dialog__create--body">
        <!--指标类型列表-->
        <div
          class="tojoyDivParent"
          v-for="(item, index) in radioLists"
          :key="index"
        >
          <div :class="`tojoyMount  ${item.cls}`">{{ item.iconTxt }}</div>
          <div class="tojoyContent" @click="changeRadio(item)">
            <div class="tojoyCtarget">
              <p class="tojoyMuntName">{{ item.title }}</p>
              <p class="tojoyMuntInfo">{{ item.disc }}</p>
            </div>
            <span class="tojoyCircle"
            ><el-radio v-model="radio" :label="item.label + ''"></el-radio
            ></span>
          </div>
        </div>
        <!--提示-->
        <div>
          <p class="tojoySmallTips">创建目标小贴士：</p>
          <p class="tojoyUpTips">
            目标应该尽可能符合SMART原则，即具体的、可衡量的、可达成的、与其他目标有一定的相关性，并且具有时间限制。
          </p>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="
              () => {
                handleBeforeClose('ok');
              }
            "
            >下一步</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script >
export default {
  name: 'target-index-create',
  components: {},
  props: {
    display: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    dateStr: {
      type: String,
      default: ''
    },
    month: {
      type: String,
      default: ''
    },
    year: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      radioLists: [
        {
          iconTxt: '定量',
          title: '定量指标',
          disc: '一般是指可以用数字量化的指标',
          cls: '',
          label: 1,
          sort: 1,
        },
        {
          iconTxt: '定性',
          title: '定性指标',
          disc: '定性指标就是要做哪些事情',
          cls: 'tojoyOrgColor',
          label: 2,
          sort: 2,
        },
        {
          iconTxt: '例行',
          title: '例行指标',
          disc: '适应于周期性工作考核',
          cls: 'tojoyBuleColor',
          label: 3,
          sort: 3,
        },
      ],
      radio: '1',
    };
  },
  computed:{},
  methods: {
    changeRadio(item){
      this.radio =item.label+''
    },
    handleOpenLib() {
      this.$emit('lib-click');
    },
    handleBeforeClose(val) {
      this.$emit('before-close', val, {
        type: this.radio,
      });
    },
  },
};
</script>
<style lang="scss">
.#{$css-prefix}-dialog {
  &__create {
    &--header {
      width: 100%;
      position: relative;
      .resName {
        display: inline-block;
        font-size: 18px;
        line-height: 18px;
        font-weight: bold;
        color: $lightblack;
      }
      .resNum {
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        color: $grey;
        margin-left: 15px;
        display: inline-block;
      }
      .tojoyAddStyle {
        position: absolute;
        top: -4px;
        display: inline-block;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        margin-left: 20px;
        color:#666666;
      }
    }

    &--body {
      border-radius: 5px;
      position: relative;

      .tojoyDivParent {
        display: flex;
        align-items: center;
        position: relative;
        .tojoyMount {
          width: 40px;
          height: 40px;
          background: $green;
          border-radius: 50%;
          text-align: center;
          line-height: 40px;
          color: $white;
          font-size: 12px;
          margin-right: 15px;
        }
        .tojoyOrgColor {
          background: $yellow;
        }
        .tojoyBuleColor {
          background: $blue;
        }
        .tojoyContent{
          flex: 1;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 1px 0px 0px #eeeeee;
          .tojoyCtarget{
            flex: 1;
            padding: 20px 0;
          }
          .tojoyCircle{
            padding-right: 20px;
            .el-radio__label{
              display: none;
            }
          }
        }
        .tojoyCtarget {
          flex: 1;
          .tojoyMuntName {
            height: 16px;
            font-size: 16px;
            font-weight: bold;
            color: $lightblack;
            line-height: 16px;
          }
          .tojoyMuntInfo {
            font-size: 12px;
            font-weight: 400;
            color: $grey;
            line-height: 12px;
            margin-top: 10px;
          }
        }
      }
      .tojoySmallTips {
        font-size: 14px;
        font-weight: 400;
        color: $darkgrey;
        margin-bottom: 10px;
        padding-top: 10px;
      }
      .tojoyUpTips {
        font-size: 12px;
        font-weight: 400;
        color: $grey;
        margin-bottom: 10px;
      }
    }

    .el-dialog__body {
      padding-top: 9px;
      height: 512px;
    }

    .el-dialog__footer {
      z-index: 10;
      height: 64px;
      text-align: center;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 16px 0;
      position: relative;
    }
    .el-button--primary {
      color: $white;
      width: 120px;
      height: 32px;
      background: $blue;
      border-radius: 3px;
      line-height: 32px;
    }
    .el-button {
      padding: 0;
    }
  }
}
</style>
