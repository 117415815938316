<template>
  <div class="tojoy-target-evaluation__list">
    <div class="tojoyAddTarget">
      <div class="tojoyDate">
        <span class="tojoyMonth">{{ sourceData.month }}</span
        ><span class="tojoyYear">{{ sourceData.year }}</span>
      </div>
      <div class="tojoyExamInfo">
        <!--列表头部-->
        <div class="tojoyExmHeader">
	        <div class="tojoyAvatar target-avatar-wrap">
		        <img  v-if="sourceData.avatar" :src="sourceData.avatar || defaultAvatar" />
		        <span v-else class="target-avatar target-avatardefault">{{  sourceData.userName? sourceData.userName.slice(-2):'' }}</span>
	        </div>
          <div class="tojoyHeaderContent">
            <div class="tojoyAvatarInfo">
              <p class="tojoyAvatarName">{{ sourceData.userName }}</p>
              <p class="tojoyAvatarTitle">{{ sourceData.userJob }}</p>
            </div>
            <div
                class="tojoyProgress"
                :style="
            (state == '1-1' && 'right:200px;') ||
            (state == '1-2' && 'right:0;') ||
            (state == '2-1' && 'right:260px;') ||
            (state == '2-2' && 'right:160px;')
          "
            >
              <span class="tojoyProTotal"> 总进度：</span>
              <span class="tojoyProgressNumber"
              >{{ sourceData.progress || 0 }}%</span
              >
              <span class="tojoyProgressNum">
            <el-progress
                :percentage="sourceData.progress || 0"
                :show-text="false"
            ></el-progress>
          </span>
            </div>
            <div class="tojoyProScore">
              <div v-if="state == '1-1'">
                <!--驳回-->
                <span
                    class="b1 hover"
                    v-if="!isFuncBatch"
                    @click.stop.prevent="
                () => {
                  if (!isFuncBatch) {
                    affirmDisplayState = true;
                    affirmState = 6;
                  }
                }
              "
                >
              <span>
                 <i class="icon iconfont iconrejectprocess handle-icon"/>
                <span class="hanle-text">驳回</span>
              </span>
            </span>
                <span v-else-if="isFuncBatch" @click.stop>
               <el-checkbox
                   v-model="sourceData.affirm.rejectChecked"
                   @change="
                  () => {
                    if(sourceData.affirm.rejectChecked){
                       sourceData.affirm.approveChecked = false
                       sourceData.affirm.state = '6'
                    }
                  }
                "
                   label="6"
               >
              <span>驳回</span>
            </el-checkbox>
            </span>
                <span class="b2">|</span>
                <span
                    class="b3 hover"
                    @click.stop.prevent="
                () => {
                  if (!isFuncBatch) {
                    affirmDisplayState = true;
                    affirmState = 3;
                  }
                }
              "
                    v-if="!isFuncBatch"
                ><span>
                  <i class="handle-icon el-icon-circle-check" />
                  <span class="handle-text">同意</span>
              </span>
            </span>
                <span v-else @click.stop>
               <el-checkbox
                   v-model="sourceData.affirm.approveChecked"
                   v-if="isFuncBatch"
                   @change="
                  () => {
                    if(sourceData.affirm.approveChecked){
                       sourceData.affirm.rejectChecked = false
                       sourceData.affirm.state = '3'
                    }
                  }
                "
                   label="3"
               >
                <span>确认目标</span>
              </el-checkbox>
            </span>
              </div>
              <div v-if="state == '2-1'"  @click.stop.prevent>
                <span>
                   <span>{{ sourceData.scoreType ? scoreTypeObj[sourceData.scoreType] : '综合评分'}}</span>
                   <span class="tojoyDetailNum">{{ sourceData.showScore }}</span>
                   <span>分</span>
                </span>
                <span class="b2">|</span>
                <span class="b3" @click.stop.prevent style="width: 65px;">
                  <!--<el-select-->
                      <!--v-if="isFuncBatch"-->
                      <!--v-model="sourceData.score.score"-->
                      <!--:placeholder= "`评分`"-->
                  <!--&gt;-->
              <!--<el-option-->
                  <!--v-for="item in scoreOptions"-->
                  <!--:key="item.value"-->
                  <!--:label="item.label"-->
                  <!--:value="item.value"-->
              <!--&gt;-->
              <!--</el-option>-->
            <!--</el-select>-->
                              <write-score class="target-evaluation-score" style="position: relative; width: 65px;display: inline-block" v-if="isFuncBatch" v-model="sourceData.score.score" :placeholder= "`评分`" :max-score-number="maxNumberScore" ></write-score>
              <span class="hover" v-else @click="lscoreDisplayState = true;">
                <i
                    class="iconfont iconscore handle-icon"
                /> <span class="handle-text">去评分</span></span
              >
            </span>
              </div>
              <!--评分显示:scoreType评分类型:1、综合自评 2、综合评分 3、目标得分=》改为我的自评-->
              <div v-if="state == '2-2'"  @click.stop.prevent>
                <span>{{ sourceData.scoreType ? scoreTypeObj[sourceData.scoreType] : '综合评分'}}</span>
                <span class="tojoyDetailNum">{{ sourceData.showScore }}</span>
                <span>分</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 考评权重显示 1.1.6 -->
        <eval-weight-show-comp
          :data="{
            evalType: sourceData.evalType,
            evalWeight: sourceData.evalWeight,
            sort:sourceData.sort
          }"
          schema="list"
        />
        <!--列表项内容-->
        <div
            class="tojoyExamItem"
            v-for="(item, index) in sourceData.targetIndexOutputs"
            :key="index"
        >
          <span class="tojoyExamInfoDesing">{{ item.name }}</span
          ><span class="tojoyExamInfoTime" v-if="item.finishTime || item.planFinishDate">截止：{{ item.finishTime || (item.planFinishDateStr && item.planFinishDateStr.replace(/\./g,'-')) }}</span
        ><span class="tojoyExamInfoTime"
               v-else-if="item.type===3">{{ item.frequency || '' }}例行</span>
          <span class="tojoyExamInfoLine">进度：{{ item.progress || 0}}%</span
          ><span></span>
        </div>
      </div>
    </div>
    <target-index-affirm
        :display="affirmDisplayState"
        :affirmState="affirmState-0"
        @before-close="submitAffirm"
    ></target-index-affirm>
    <target-index-lscore
        :display="lscoreDisplayState"
        :maxNumberScore="maxNumberScore"
        :defaultScore = "defaultScore"
        @before-close="submitLscore"
    >
    </target-index-lscore>
  </div>
</template>
<script >
  import TargetIndexAffirm from '@/components/mytarget/target-index-affirm';
  import TargetIndexLscore from '@/components/mytarget/target-index-lscore';
  import EvalWeightShowComp from '@/components/mytarget/eval-weight-show';
  import WriteScore from '../../common/WriteScore';
  const defaultAvatar = require('@/assets/images/profile.png')

  export default {
    name: 'target-evaluation-list',
    components: { WriteScore, TargetIndexAffirm, TargetIndexLscore, EvalWeightShowComp },
    props: {
      sourceData: {
        type: Object,
        default: () => {
          return {};
        },
      },
      state: {
        type: String,
        default: () => {
          return '';
        },
      },
      isFuncBatch: {
        type: Boolean,
        default: () => {
          return false;
        },
      },
      maxNumberScoreObj: {
        type: Object,
        default: () => {
          return {
            orgScore: 100,
            score: 100
          };
        },
      }
    },
    data() {
      return {
        checked: false,
        affirmDisplayState: false,
        lscoreDisplayState: false,
        affirmState: 3,
        defaultAvatar,
        scoreTypeObj:{
          1 : '综合自评',
          2 : '综合评分',
          3 : '我的评分' // 目标得分
        }
      };
    },
    watch:{
      isFuncBatch:{
        immediate:true,
        handler (val) {
          console.log('watch', this.sourceData)
          if(val){
             if(this.state == '2-1'){
               console.log(this.sourceData)
              this.sourceData.score.score = this.sourceData.showScore
             }
          }
        }
      }
    },
    computed: {
      scoreOptions () {
        let length = 0
        let maxScoreNumber = this.maxNumberScore + ''
        if ((maxScoreNumber).indexOf('.') > -1) {
          length = Math.floor(this.maxNumberScore / 1)
        } else {
          length = this.maxNumberScore / 1
        }
        let options = (Object.keys(Array.apply(null, { length })).map(
            (item) => {
              let num = ++item * 1;
              return {
                value: num,
                label: num + '分',
              };
            },
        )).reverse()
        options.push({ value: 0, label: '0分' })
        if ((maxScoreNumber).indexOf('.') > -1) {
          options.unshift({ value: this.maxNumberScore, label: this.maxNumberScore+'分' })
        }
        return options
      },
      maxNumberScore(){
        return this.sourceData.isOrg ? this.maxNumberScoreObj.orgScore : this.maxNumberScoreObj.score
      },
      defaultScore(){
        return this.sourceData.showScore
      }
    },
    methods: {
      // 目标确认 状态[state= 3 || 6]
      submitAffirm(type, val) {
        this.affirmDisplayState = false;
        let reqParams = {
          detailId: this.sourceData.detailId,
          remark: val,
          state: this.affirmState,
        }
        this.$emit('submitAffirm',{type,val,reqParams})
      },
      // 目标评分
      submitLscore(type, val) {
        this.lscoreDisplayState = false;
        let reqParams = {
              detailId: this.sourceData.detailId,
              score: val,
            }
        this.$emit('submitLscore',{type, reqParams})
      },
      handleBeforeClose(val) {
        this.$emit('before-close', val);
      },
    },
  };
</script>
<style lang="scss">
  .#{$css-prefix}-target-evaluation {
    &__list {
      width: 100%;
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      .tojoyAvatarTitle {
        font-size: 12px;
        font-weight: 400;
        color: $grey;
      }
      .handle-icon{
        margin-right: 5px;
        font-size: 14px!important;
      }
      .tojoyAvatarName {
        /*height: 15px;*/
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        color: $lightblack;
      }
      .tojoyAvatar {
        width: 38px;
        height: 38px;
        /*border-radius: 50%;*/
        display: inline-block;
        overflow: hidden;
	      margin: 0;
        img {
          width: 100%;
          height: 100%;
        }
	      .target-avatar{
		      font-size: 14px;
	      }
      }
      .tojoyAvatarInfo {
        margin-left: 10px;
        display: inline-block;
      }

      .tojoyPro {
        position: absolute;
        left: 1445px;
        width: 60px;
        height: 5px;
        display: inline-block;
      }
      .tojoyExamState {
        width: 62px;
        height: 24px;
        background: #fef2e8;
        border-radius: 3px;
        font-size: 14px;
        font-weight: 400;
        color: $yellow;
        line-height: 28px;
        text-align: center;
        position: absolute;
        right: 0;
        top: 20px;
      }
      .tojoyExamList {
        padding-top: 20px;
        /* width: 122px; */
        /* height: 16px; */
        font-size: 16px;

        font-weight: bold;
        color: $lightblack;
      }
      .tojoyTripleRight {
        position: absolute;
        left: 18px;
        top: 22px;
        z-index: 2;
        width: 0;
        height: 0;
        border-top: 8px solid &blue;
        border-left: 13px solid transparent;
      }
      .tojoyTriple {
        position: absolute;
        left: 5px;
        top: 22px;
        z-index: 2;
        width: 0;
        height: 0;
        border-top: 8px solid #2b58b1;
        border-right: 13px solid transparent;
      }
      .tojoyGroup {
        width: 26px;
        height: 22px;
        opacity: 0.99;
        /* width: 16px; */
        /* height: 16px; */
        font-size: 8px;

        font-weight: 600;
        color: $white;
        text-align: center;
        padding-top: 2px;
        position: absolute;
        left: 5px;
        z-index: 2;
      }
      .tojoyYear {
        width: 29px;
        height: 10px;
        font-size: 12px;

        font-weight: 400;
        color: $grey;
        line-height: 10px;
      }
      .tojoyMonth {
        display: block;
        width: 21px;
        height: 16px;
        font-size: 20px;
        font-weight: 600;
        color: $lightblack;
        line-height: 16px;
        margin-left: 14px;
        margin-top: 7px;
      }
      .tojoyProScore {
        height: 22px;
        line-height: 22px;
        font-size: 15px;
        font-weight: 400;
        color: $darkgrey;
        position: absolute;
        right: 10px;
        top: 0px;
        display: inline-block;
        span {
          /*cursor: default;*/
          &.b1 {
            color: $ff526a;
          }
          &.b2 {
            color: $grey600;
            margin: 0 15px;
          }
          &.b3 {
            color: $blue;
          }
        }
        span.hover{
          cursor: pointer;
        }
      }
      .tojoyProgress {
        position: absolute;
        overflow: hidden;
        top: 0px;
      }
      .tojoyProgressNum {
        width: 60px;
        height: 5px;
        display: inline-block;
      }
      .tojoyProgressNumber {
        font-size: 12px;
        color: $blue;
        margin: 0 5px;
      }
      .tojoyDetailNum {
        color: $yellow;
      }
      .tojoyProTotal {
        font-size: 12px;
        font-weight: 400;
        color: $darkgrey;
        line-height: 12px;
        top: 22px;
      }
      .tojoyWriteTarget {
        height: 16px;
        font-size: 16px;
        font-weight: bold;
        color: &blue;
        line-height: 16px;
        position: absolute;
        left: 80px;
        top: 34px;
      }
      .tojoyDate {
        width: 50px;
        height: 50px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
        border-radius: 3px;
        opacity: 0.99;
        border: 1px solid #eeeeee;
        position: absolute;
        text-align: center;
      }
      .tojoyExamItem {
        color: #333333;
        font-size: 15px;
        font-weight: 400;
        padding-top: 18px;
        margin-bottom: 2px;
        position: relative;
        width: 100%;
        height: 52px;
        display: flex;
        &:nth-last-child(1) {
          box-shadow: 0px 1px 0px 0px transparent;
        }
        box-shadow: 0px 1px 0px 0px #eeeeee;
        .tojoyExamInfoDesing {
          flex: 1;
          margin-right: 30px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .tojoyExamInfoTime {
          height: 21px;
          line-height: 21px;
          font-size: 12px;
          font-weight: 400;
          color: $darkgrey;
          min-width: 100px;
          margin-right: 30px;
          /*display: inline-block;*/
          /*position: absolute;*/
          /*right: 80px;*/
        }
        .tojoyExamInfoLine {
          /*position: absolute;*/
          /*right: 0;*/
          min-width: 100px;
          height: 21px;
          font-size: 12px;
          line-height: 21px;
          font-weight: 400;
          color: $darkgrey;
          margin-right: 15px;
        }
      }
      .tojoyAddTarget {
        width: 100%;
        min-height: 80px;
        border-radius: 5px;
        opacity: 0.99;
        position: relative;
        .tojoyExamInfo {
          margin-left: 65px;
          position: relative;
        }
        .tojoyExmHeader{
          display: flex;
          justify-items: flex-start;
          margin-bottom: 10px;
        }
      }

      .tojoyP {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: $darkgrey;
        line-height: 20px;
        margin-bottom: 5px;
      }
      .el-select > .el-input{
        display: inline-block;
        width: 65px;
        line-height: 22px;
        height: 22px;
      }
      .el-select .el-input__icon{
        line-height: 22px;
        height: 22px;
        width: 14px;
      }
      .el-select .el-input--suffix .el-input__inner{
        padding: 0 14px 0 6px;
        text-align: center;
      }
      .el-input {
        width: 65px;
        line-height: 22px;
      }
      .el-input__inner {
        height: 22px;
        padding: 10px;
      }
    }
  }
</style>
