<template>
	<div class="target-align">
		<div class="target-align-desc">阅读完成值会同步至年度目标</div>
		<div class="target-align-content">
			<div class="content-item" v-for="(item,index) in dataList" :key="index">
				<div class="left">
					<p class="name">{{ item.name }}</p>
					<p class="target">
						<span>{{ item.orderNo }}月目标：</span>
						<span>{{ item.planValue }}{{ item.unit }}</span>
					</p>
				</div>
				<div class="right">
					<button class="button" :class="[item.choosed? 'choosed':'']" @click="choose(item)">{{ item.choosed ? '已选择' : '选择' }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'target-align',
		props:{
			dataList:{
				type:Array,
				default:()=>{
					return []
				}
			},
		},
		data(){
			return {
				list:[]
			}
		},
		mounted(){
			this.list=this.dataList
		},
		methods:{
			choose(data){
				this.list = this.list.map(item=>{
					if(item.id!==data.id){
						this.$set(item,'choosed',false)
					}else{
						this.$set(item,'choosed',true)
					}
					return item
				})
				this.$emit('choose-target',data)
			}
		}
	};
</script>

<style scoped lang="scss">
	.target-align{
		width: 100%;
		height: 400px;
		overflow: hidden;
		.target-align-desc{
			height: 12px;
			font-size: 12px;
			font-weight: 400;
			color: #999999;
			line-height: 12px;
		}
		.target-align-content{
			display: flex;
			flex-direction: column;
			flex: 1;
			margin-top: 10px;
			height: 390px;
			overflow: hidden;
		}
		.content-item{
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 12px 0;
			border-bottom: 1px solid #EEEEEE;
			.name{
				height: 16px;
				font-size: 16px;
				font-weight: 500;
				color: #333333;
				line-height: 16px;
			}
			.target{
				margin-top: 10px;
				height: 14px;
				font-size: 14px;
				font-weight: 400;
				color: #666666;
				line-height: 14px;
			}
			.button{
				width: 54px;
				height: 25px;
				border-radius: 3px;
				opacity: 0.99;
				background: $blue;
				color: white;
				border: none;
				margin-left: 15px;
				vertical-align: 1px;
				cursor: pointer;
			}
			.choosed{
				background: #CCCCCC;
				color: #FFFFFF;
			}
		}
	}

</style>
