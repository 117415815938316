<template>
	<div class="tojoy-details__button"  v-if="showBtn">
			<el-button
				v-if="sourceData.state == 1 || sourceData.state == 6"
				v-prevent-click
				type="default"
				@click="toTargetRemove"
			>重新填写</el-button>

			<el-button
				v-if="sourceData.state == 2 || sourceData.state == 3"
				v-prevent-click
				type="default"
				@click="toTargetRevert"
			>撤回</el-button>
	</div>
</template>
<script>
	import { ApiTargetRemove, ApiTargetRevert } from '@/api/api_target';
	import { MessageBox, Message } from 'element-ui';
	export default {
		name: 'target-details-title-c',
		components: {},
		props: {
			sourceData: {
				type: Object,
				default: () => {
					return {};
				},
			},
			showReOperateBtn:{
				type: Boolean,
				default: () => {
					return false
				}
			}
		},
		data() {
			return {};
		},
		computed: {
			showBtn(){
				return (this.sourceData.state == 1 || this.sourceData.state == 6 || this.sourceData.state == 2 || this.sourceData.state == 3) ? true : false
			},
		},
		mounted() {},
		methods: {
			/**
			 * 重新填写指标
			 */
			toTargetRemove() {
				MessageBox.confirm('清空已填指标重新填写目标', '警告', {
					confirmButtonText: '重新填写',
					cancelButtonText: '取消',
					type: 'warning',
				}).then(() => {
					ApiTargetRemove({
						targetId: this.sourceData.targetId,
					}).then((data) => {
						if (data.code === '000000') {
							this.$router.push({ path: '/mytarget/mine' });
						}
					});
				});
			},
			/**
			 * 撤回填写指标
			 */
			async toTargetRevert() {
				MessageBox.confirm(' 撤回后目标需要重新提交确认', '警告', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				}).then(() => {
					ApiTargetRevert({
						targetId: this.sourceData.targetId,
					}).then((data) => {
						if (data.code === '000000') {
							this.$emit('handle-withdraw');
						} else {
							Message({
								message: data.msg,
								type: 'error',
							});
						}
					});
				});
			},
			handleTabClick() {},
		},
	};
</script>

<style lang="scss" scoped>
	.tojoy-details__button{
		display: inline-block;
		.el-button--default{
			color: #333333;
		}
		/deep/ .el-button:focus, /deep/ .el-button:hover{
			color: #3d7eff;
			border-color: #c5d8ff;
			background-color: #ecf2ff;
		}
	}
</style>
