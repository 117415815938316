<template>
  <div class="tojoy-write-dayContent">
    <div class="threeDown">
      <div class="resTitle">{{ title }}</div>
      <div class="box">
        <el-form inline-message :show-message="true" label-position="top" ref="formMeeting" :model="workForm" class="reset-form">
          <!-- 会议类型 -->
          <div class="form-list" v-if="formListState.meetTypeId.isEnabled === 1">
            <div class="item">
              <el-form-item
                class="formItemTop"
                :label="formListState.meetTypeId.showName"
                :rules="[{ required: formListState.meetTypeId.isRequired === 1 ? true : false, message: '请选择' + formListState.meetTypeId.showName, trigger: 'change' }]"
                prop="reportFor"
              >
                <el-select class="reportName" v-model="workForm.reportFor" :placeholder="'请选择' + formListState.meetTypeId.showName">
                  <el-option v-for="(item, index) in dataList" :key="index" :label="item.meetTypeName" :value="item.meetTypeId"> </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <!-- 会议名称 -->
          <div class="form-list" v-if="formListState.meetName.isEnabled === 1">
            <div class="item">
              <el-form-item
                :rules="[
                  { required: formListState.meetName.isRequired === 1 ? true : false, message: '请输入' + formListState.meetName.showName, trigger: 'blur' },
                  { min: 0, max: 20, message: formListState.meetName.showName + '，20字以内', trigger: 'blur' }
                ]"
                :label="formListState.meetName.showName"
                prop="meetName"
              >
                <el-input maxlength="20" :show-word-limit="true" v-filteremoji class="pleaseWrite" :placeholder="'请填写' + formListState.meetName.showName" v-model="workForm.meetName" clearable />
              </el-form-item>
            </div>
          </div>
          <!-- 参加人员 -->
          <div class="form-list">
            <div class="item" v-if="formListState.joinMember.isEnabled === 1">
              <el-form-item
                :rules="[{ required: formListState.joinMember.isRequired === 1 ? true : false, message: '请选择' + formListState.joinMember.showName, trigger: 'change' }]"
                :label="formListState.joinMember.showName"
                prop="joinMember"
              >
                <person-selected
                  :multipleLimit="100"
                  :placeholder="'请选择' + formListState.joinMember.showName"
                  :reserveKeyword="false"
                  v-model="workForm.joinMember"
                  :remote="true"
                  :propsAlias="{ value: 'userId', label: 'userName' }"
                  :disabled="false"
                  :multiple="true"
                  :disableDataList="disableDataList"
                ></person-selected>
              </el-form-item>
            </div>
            <div class="item" v-if="formListState.leaveMember.isEnabled === 1">
              <!-- 请假人员 -->
              <el-form-item
                :rules="[{ required: formListState.leaveMember.isRequired === 1 ? true : false, message: '请选择' + formListState.leaveMember.showName, trigger: 'change' }]"
                :label="formListState.leaveMember.showName"
                prop="leaveMember"
              >
                <person-selected
                  :multipleLimit="10"
                  :placeholder="'请选择' + formListState.leaveMember.showName"
                  :reserveKeyword="false"
                  v-model="workForm.leaveMember"
                  :remote="true"
                  :propsAlias="{ value: 'userId', label: 'userName' }"
                  :disabled="false"
                  :multiple="true"
                  :disableDataList="disableDataList"
                ></person-selected>
              </el-form-item>
            </div>
            <!-- 缺席人员 -->
            <div class="item" v-if="formListState.absentMember.isEnabled === 1">
              <el-form-item
                :rules="[{ required: formListState.absentMember.isRequired === 1 ? true : false, message: '请选择' + formListState.absentMember.showName, trigger: 'change' }]"
                :label="formListState.absentMember.showName"
                prop="absentMember"
              >
                <person-selected
                  :multipleLimit="10"
                  :placeholder="'请选择' + formListState.absentMember.showName"
                  :reserveKeyword="false"
                  v-model="workForm.absentMember"
                  :remote="true"
                  :propsAlias="{ value: 'userId', label: 'userName' }"
                  :disabled="false"
                  :multiple="true"
                  :disableDataList="disableDataList"
                ></person-selected>
              </el-form-item>
            </div>
            <!-- 迟到人员 -->
            <div class="item" v-if="formListState.overMember.isEnabled === 1">
              <el-form-item
                :rules="[{ required: formListState.overMember.isRequired === 1 ? true : false, message: '请选择' + formListState.overMember.showName, trigger: 'change' }]"
                :label="formListState.overMember.showName"
                prop="overMember"
              >
                <person-selected
                  :multipleLimit="10"
                  :placeholder="'请选择' + formListState.overMember.showName"
                  :reserveKeyword="false"
                  v-model="workForm.overMember"
                  :remote="true"
                  :propsAlias="{ value: 'userId', label: 'userName' }"
                  :disabled="false"
                  :multiple="true"
                  :disableDataList="disableDataList"
                ></person-selected>
              </el-form-item>
            </div>
          </div>
          <!-- 会议开始时间 -->
          <div class="form-list" v-if="formListState.startTime.isEnabled === 1">
            <div class="item">
              <el-form-item
                :rules="[{ required: formListState.startTime.isRequired === 1 ? true : false, message: '请选择' + formListState.startTime.showName, trigger: 'change' }]"
                :label="formListState.startTime.showName"
                prop="startTime"
              >
                <el-date-picker
                  @change="meetingStardate"
                  :picker-options="{
                    disabledDate: date => {
                      return date.getTime() >= Date.now()
                    }
                  }"
                  format="yyyy-MM-dd HH:mm"
                  v-model="workForm.startTime"
                  value-format="yyyy-MM-dd HH:mm"
                  type="datetime"
                  :placeholder="'请选择' + formListState.startTime.showName"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
          <!-- 会议记录 -->
          <div class="form-list" v-if="formListState.meetSummary.isEnabled === 1">
            <div class="item">
              <el-form-item
                :rules="[
                  { required: formListState.meetSummary.isRequired === 1 ? true : false, message: '请填写' + formListState.meetSummary.showName, trigger: 'blur' },
                  { min: 1, max: 800, message: formListState.meetSummary.showName + '限1-800字以内', trigger: 'blur' }
                ]"
                :label="formListState.meetSummary.showName"
                prop="meetSummary"
              >
                <el-input
                  maxlength="800"
                  :show-word-limit="true"
                  v-filteremoji
                  class="pleaseWrite"
                  :placeholder="'请填写' + formListState.meetSummary.showName"
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 6, maxRows: 16 }"
                  v-model="workForm.meetSummary"
                  clearable
                />
              </el-form-item>
            </div>
          </div>
          <div class="form-list" v-if="formListState.uploadFiles.isEnabled === 1">
            <div class="item">
              <div class="resDiv">
                <div class="telldiv">
                  <p class="tell">{{ formListState.uploadFiles.showName }}</p>
                  <div class="filesList">
                    <TojofileList class="fileOption" @remove="removeFile" :isShow="true" v-for="(item, index) in fileList" :key="index" :data="item"></TojofileList>
                  </div>
                  <el-upload
                    :show-file-list="false"
                    class="upload-demo"
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :http-request="fileUpload"
                    multiple
                    :limit="10"
                    :on-exceed="handleExceed"
                    :file-list="fileList"
                  >
                    <div>
                      <i class="el-icon-circle-plus-outline"></i><span>添加文件<span v-if="formListState.uploadFiles.isRequired === 1" class="redStar">*</span></span>
                    </div>
                  </el-upload>
                </div>
              </div>
            </div>
          </div>
          <div v-if="formListState.addMeet.isEnabled === 1">
            <div v-for="(item, index) in workForm.selfInputList" :key="index" class="reset-form">
              <div class="scoreLine">
                <span class="scoreTitle">{{ formListState.addMeet.showName }}</span>
                <span class="el-icon-delete" @click="deleteSelfList(index)"> 删除</span>
              </div>
              <div class="form-list">
                <div class="item" v-for="(domains, inde) in item.template" :key="inde">
                  <el-form-item :rules="domains.rules" :label="domains.text" :prop="'selfInputList.' + index + '.template.' + inde + '.val'">
                    <component :is="domains.comp" v-model="domains.val" placeholder="请输入内容" v-bind="domains.attrs" />
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
        </el-form>
        <div v-if="formListState.addMeet.isEnabled === 1" class="carryOn" @click="addSelfInputList">
          <i class="el-icon-circle-plus-outline"></i>添加会议决议<span v-if="formListState.addMeet.isRequired === 1" class="redStar">*</span>
        </div>
      </div>
      <div class="saveBox">
        <el-button :disabled="saveBtnDisabled" :loading="saveBtnStatu" class="subButton" @click="reportClickStage(1)">保存为草稿</el-button>
        <el-button type="primary" :disabled="subBtnDisabled" :loading="subBtnStatu" class="subButton" @click="reportClickStage(2)">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Message, MessageBox } from 'element-ui'
import ChoosePeople from '../../components/common/tojoy-select/index.vue'
import TojofileList from '../../components/common/file/index.vue'
import PersonSelected from '@/components/common/tojoy-select/person-selected.vue'
import { listmeetypes, savemeetinfo, upload, meetdraftinfo, formConfig } from '@/api/thrclear.js'
export default {
  name: 'thrclear-hold-meeting',
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6 //如果没有后面的-8.64e6就是不可以选择今天的
        }
      },
      formListState: {
        absentMember: {
          isEnabled: 1
        },
        addMeet: {
          isEnabled: 1
        },
        joinMember: {
          isEnabled: 1
        },
        leaveMember: {
          isEnabled: 1
        },
        meetName: {
          isEnabled: 1
        },
        meetSummary: {
          isEnabled: 1
        },
        meetTypeId: {
          isEnabled: 1
        },
        overMember: {
          isEnabled: 1
        },
        startTime: {
          isEnabled: 1
        },
        uploadFiles: {
          isEnabled: 1
        }
      },
      subBtnDisabled: false,
      saveBtnDisabled: false,
      subBtnStatu: false,
      saveBtnStatu: false,
      disableDataList: [],
      watchStatus: false,
      workForm: {
        // 会议类型
        reportFor: [],
        // 会议名称
        meetName: '',
        // 参会人员
        joinMember: [],
        // 请假人员
        leaveMember: [],
        // 缺席人员
        absentMember: [],
        // 	迟到人员
        overMember: [],
        // 会议开始时间
        startTime: '',
        // 会议纪要
        meetSummary: '',
        selfInputList: []
      },
      rules: {
        // // 会议类型
        // reportFor: [{ required: true, message: '会议类型不能为空', trigger: 'blur' }],
        // // 会议名称
        // meetName: [
        //   { min: 1, max: 20, message: '会议名称，20字以内', trigger: 'blur' },
        //   { required: true, message: '会议名称不能为空', trigger: 'blur' }
        // ],
        // // 参会人员
        // joinMember: [{ required: true, message: '参会人员不能为空', trigger: 'change' }],
        // // 会议开始时间
        // startTime: [{ required: true, message: '会议开始不能为空', trigger: 'change' }],
        // // 会议纪要
        // meetSummary: [
        //   { min: 1, max: 20, message: '会议纪要限1-800字以内', trigger: 'blur' },
        //   { required: true, message: '会议纪要不能为空', trigger: 'blur' }
        // ]
      },
      fileList: [],
      dataList: [],
      savemeetinfoid: '',
      customize: {
        template: [
          {
            text: '决议标题',
            val: '',
            query: 'title',
            comp: 'el-input',
            rules: [
              { required: true, message: '决议标题不能为空！', trigger: 'blur' },
              { min: 1, max: 200, message: '决议标题限制200字以内', trigger: 'blur' }
            ]
          },
          {
            text: '责任人',
            val: [],
            query: 'executor',
            comp: 'choose-people',
            rules: [{ required: true, message: '请至少选择一个责任人', trigger: 'blur' }],
            attrs: {
              dataList: [],
              remote: false,
              propsAlias: { value: 'userId', label: 'userName' },
              disabled: false,
              multiple: true,
              reserveKeyword: false
            }
          },
          {
            text: '决议到期日期',
            val: '',
            query: 'endDate',
            comp: 'el-date-picker',
            rules: [
              { required: true, message: '请输入内容', trigger: 'blur' },
              { message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
            ],
            attrs: {
              type: 'date',
              valueFormat: 'yyyy-MM-dd',
              placeholder: '请选择会议开始时间'
            }
          }
        ]
      },
      title: '开会',
      // 会议记录
      meetingMinutes: '',

      // 会议开始时间 YYYY-MM-DD HH:MM
      startTime: '',

      // 会议附件 多个附件url逗号隔开
      attachment: [],
      // 会议决议集合
      meetResolutionInput: []
    }
  },
  components: {
    ChoosePeople,
    PersonSelected,
    TojofileList
  },
  created() {
    this.listmeetypes() //会议类型
    this.meetdraftinfo()
    this.formConfig() //三清两会动态设置
  },
  watch: {
    'workForm.joinMember': {
      handler: function(val, oldVal) {
        this.processPeople(val)
      },
      deep: true
    },
    'workForm.leaveMember': {
      handler: function(val, oldVal) {
        this.processPeople(val)
      },
      deep: true
    },
    'workForm.absentMember': {
      handler: function(val, oldVal) {
        this.processPeople(val)
      },
      deep: true
    },
    'workForm.overMember': {
      handler: function(val, oldVal) {
        this.processPeople(val)
      },
      deep: true
    }
  },
  computed: {
    templateObj() {
      let obj = this.customize.template.map(res => {
        if (res.query === 'executor') {
          let obj = {}
          res.attrs.dataList = [...this.workForm.joinMember, ...this.workForm.leaveMember, ...this.workForm.absentMember, ...this.workForm.overMember].filter((element, index) => {
            if (obj[element.userId]) {
              return false
            } else {
              obj[element.userId] = true
              return true
            }
          })
        }
        return res
      })
      return {
        template: obj
      }
    }
  },
  mounted() {},
  methods: {
    // 选择会议开始时间时触发
    meetingStardate(date) {
      let now = new Date().valueOf()
      let dateNew = date.replace(/-/g, '/')
      let time = new Date(dateNew).valueOf()
      if (now > time) {
        this.saveBtnDisabled = false
        this.subBtnDisabled = false
      } else {
        this.saveBtnDisabled = true
        this.subBtnDisabled = true
        Message({
          message: '会议开始时间不能晚于当前时间',
          type: 'error'
        })
      }
    },
    // 获取三清两会表单动态配置
    formConfig() {
      formConfig({ type: 4 }).then(res => {
        if (res && res.code === '000000') {
          let list = res.data.reportFormConfigDto
          let codeName = ''
          for (let i = 0; i < list.length; i++) {
            codeName = list[i].codeName
            this.formListState[codeName] = list[i]
            console.log(this.formListState, '000')
          }
        } else {
          Message({
            message: '获取三清两会表单动态配置失败！',
            type: 'error'
          })
        }
      })
    },
    // watch处理人员数据的方法
    processPeople(val) {
      this.$nextTick(() => {
        let obj = {}
        // 处理已经选的人，就不能再选了
        this.disableDataList = [...this.workForm.absentMember, ...this.workForm.overMember, ...this.workForm.leaveMember, ...this.workForm.joinMember].filter((element, index) => {
          if (obj[element.userId]) {
            return false
          } else {
            obj[element.userId] = true
            return true
          }
        })
        this.workForm.selfInputList.map(res => {
          res.template = res.template.map(item => {
            if (item.query === 'executor') {
              item.attrs.dataList = this.disableDataList
              item.val = item.val.filter(its => item.attrs.dataList.map(result => result.userId).includes(its.userId))
            }
            return item
          })
          return res
        })
      })
    },
    //   处理人员数据格式
    querypeoplelist(val, list) {
      let arr = []
      if (list.length > 0) {
        for (let i = 0; i < list.length; i++) {
          let obj = {}
          if (val === 1) {
            for (let item in list[i]) {
              obj.userId = list[i].userId
              obj.userName = list[i].userName
              obj.orgChain = list[i].mainOrgChain
              obj.orgId = list[i].orgId
              obj.workNo = list[i].workNo
            }
          } else {
            for (let item in list[i]) {
              obj.userId = list[i].userId
              obj.userName = list[i].userName
              obj.mainOrgChain = list[i].orgChain
              obj.orgId = list[i].orgId
              obj.workNo = list[i].workNo
            }
          }
          arr.push(obj)
        }
      } else {
        arr = []
      }
      return arr
    },
    //   获取提交会议的参数
    getqueryData(val) {
      let meetTypeName = ''
      for (let i = 0; i < this.dataList.length; i++) {
        if (this.dataList[i].meetTypeId === this.workForm.reportFor) {
          meetTypeName = this.dataList[i].meetTypeName
        }
      }
      let data = {
        // 会议类型id
        meetTypeId: this.workForm.reportFor,
        // 会议类型名称
        meetTypeName: meetTypeName,
        // 	会议名称
        meetName: this.workForm.meetName,
        // 参会人员 json字符串
        joinMember: this.querypeoplelist(1, this.workForm.joinMember),
        // 请假人员 json字符串
        leaveMember: this.querypeoplelist(1, this.workForm.leaveMember),
        // 缺席人员 json字符串
        absentMember: this.querypeoplelist(1, this.workForm.absentMember),
        // 迟到人员 json字符串
        overMember: this.querypeoplelist(1, this.workForm.overMember),
        // 会议开始时间 YYYY-MM-DD HH:MM
        startTime: this.workForm.startTime,
        // 	会议纪要
        meetSummary: this.workForm.meetSummary,
        // 会议附件
        fileUrls: this.fileList,
        // 会议阶段 1草稿；2提交
        meetStage: String(val)
      }
      let list = []
      for (let i = 0; i < this.workForm.selfInputList.length; i++) {
        let newobj = {}
        for (let j = 0; j < this.workForm.selfInputList[i].template.length; j++) {
          let arr = []
          if (this.workForm.selfInputList[i].template[j].query == 'executor') {
            for (let x = 0; x < this.workForm.selfInputList[i].template[j].val.length; x++) {
              let obj = {}
              obj.userId = this.workForm.selfInputList[i].template[j].val[x].userId ? this.workForm.selfInputList[i].template[j].val[x].userId : ''
              obj.userName = this.workForm.selfInputList[i].template[j].val[x].userName ? this.workForm.selfInputList[i].template[j].val[x].userName : ''
              obj.orgChain = this.workForm.selfInputList[i].template[j].val[x].mainOrgChain ? this.workForm.selfInputList[i].template[j].val[x].mainOrgChain : ''
              obj.orgId = this.workForm.selfInputList[i].template[j].val[x].orgId ? this.workForm.selfInputList[i].template[j].val[x].orgId : ''
              obj.workNo = this.workForm.selfInputList[i].template[j].val[x].workNo ? this.workForm.selfInputList[i].template[j].val[x].workNo : ''
              arr.push(obj)
            }
            newobj[this.workForm.selfInputList[i].template[j].query] = arr
          } else {
            newobj[this.workForm.selfInputList[i].template[j].query] = this.workForm.selfInputList[i].template[j].val
          }
        }
        list.push(newobj)
      }
      if (list !== [] && list !== null && list.length > 0) {
        data.meetResolutionInput = list
      }
      if (this.savemeetinfoid !== '') {
        data.id = this.savemeetinfoid
      }
      for (let i in data) {
        if (i === 'meetResolutionInput') {
          for (let j in data[i]) {
            if (data[i][j] === '' || data[i][j] === null) {
              delete data[i][j]
            }
          }
          continue
        }
        if (data[i] === 'undefined' || data[i] === undefined || data[i] === null || data[i] === '' || data[i] === [] || data[i].length < 1) {
          delete data[i]
        }
      }
      return data
    },
    // 查询会议草稿内容
    meetdraftinfo() {
      meetdraftinfo().then(res => {
        if (res && res.code === '000000') {
          if (res.data == null) {
            this.watchStatus = true
            return
          }
          this.attachment = []
          this.fileList = []
          let data = res.data
          this.savemeetinfoid = data.id
          this.workForm.reportFor = data.meetTypeId
          this.workForm.meetName = data.meetName
          this.workForm.joinMember = data.joinMember ? this.querypeoplelist(2, data.joinMember) : []
          this.workForm.leaveMember = data.leaveMember ? this.querypeoplelist(2, data.leaveMember) : []
          this.workForm.absentMember = data.absentMember ? this.querypeoplelist(2, data.absentMember) : []
          this.workForm.overMember = data.overMember ? this.querypeoplelist(2, data.overMember) : []
          this.workForm.startTime = data.startTime
          this.workForm.meetSummary = data.meetSummary
          let fillist = data.fileUrls ? data.fileUrls : []
          for (let j = 0; j < fillist.length; j++) {
            let obj = {
              fileName: fillist[j].fileName,
              fileType: fillist[j].fileType,
              fileUrl: fillist[j].fileUrl
            }
            let filobj = {
              fileName: fillist[j].fileName,
              type: fillist[j].fileType,
              url: fillist[j].fileUrl
            }
            this.attachment.push(obj)
            this.fileList.push(filobj)
          }
          let setObj = {}
          let datalistpeople = [...this.workForm.absentMember, ...this.workForm.overMember, ...this.workForm.leaveMember, ...this.workForm.joinMember].filter((element, index) => {
            if (setObj[element.userId]) {
              return false
            } else {
              setObj[element.userId] = true
              return true
            }
          })
          if (data.resolutionOutputList) {
            this.workForm.selfInputList = []
            for (let i = 0; i < data.resolutionOutputList.length; i++) {
              let obj2 = {
                template: [
                  {
                    query: 'title',
                    text: '决议标题',
                    type: 'text',
                    comp: 'el-input',
                    val: data.resolutionOutputList[i].title,
                    rules: [
                      { required: true, message: '决议标题不能为空！', trigger: 'blur' },
                      { min: 1, max: 200, message: '决议标题限制200字以内', trigger: 'blur' }
                    ]
                  },
                  {
                    query: 'executor',
                    comp: 'choose-people',
                    text: '责任人',
                    type: 'persons',
                    val: this.querypeoplelist(2, data.resolutionOutputList[i].executor),
                    rules: [{ required: true, message: '请至少选择一个责任人', trigger: 'blur' }],
                    attrs: {
                      dataList: datalistpeople,
                      remote: false,
                      propsAlias: { value: 'userId', label: 'userName' },
                      disabled: false,
                      multiple: true,
                      reserveKeyword: false
                    }
                  },
                  {
                    query: 'endDate',
                    text: '决议到期日期',
                    type: 'time',
                    val: data.resolutionOutputList[i].endDate,
                    comp: 'el-date-picker',
                    rules: [
                      { required: true, message: '请输入内容', trigger: 'blur' },
                      { message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                    ],
                    attrs: {
                      type: 'date',
                      valueFormat: 'yyyy-MM-dd',
                      placeholder: '请选择会议开始时间'
                    }
                  }
                ]
              }
              this.workForm.selfInputList.push(obj2)
            }
          }
          this.watchStatus = true
        } else {
          Message({
            message: '草稿查询失败！',
            type: 'error'
          })
        }
      })
    },
    // 提交会议和保存会议接口
    savemeetinfo(data) {
      savemeetinfo(data).then(res => {
        if (res && res.code === '000000') {
          if (data.meetStage === '1') {
            Message({
              message: '草稿保存成功！',
              type: 'success'
            })
            this.meetdraftinfo()
          } else {
            Message({
              message: '会议提交成功！',
              type: 'success'
            })
            this.$router.push({ name: 'thrclear-meeting' })
          }
          this.saveBtnStatu = false
          this.subBtnStatu = false
        } else {
          this.saveBtnStatu = false
          this.subBtnStatu = false
        }
      })
    },
    // 上传文件
    fileUpload(val) {
      const formdata = new FormData()
      let that = this
      formdata.append('files', val.file)
      formdata.append('business', 'rpmt-meeting')
      upload(formdata).then(res => {
        if (res && res.code === '000000') {
          let index = val.file.name.lastIndexOf('.')
          let type = val.file.name
          type = type.substring(index + 1, type.length)
          let obj = {
            type: type,
            url: res.data.list[0],
            fileType: type,
            fileUrl: res.data.list[0],
            fileName: val.file.name
          }
          let obj2 = {
            fileType: type,
            fileUrl: res.data.list[0],
            fileName: val.file.name
          }
          that.fileList.push(obj)
          console.log(that.fileList, '上传数据')
          this.attachment.push(obj2)
          // this.attachment = this.attachment == '' ? this.attachment + res.data.list[0] : this.attachment + ',' + res.data.list[0]
        }
      })
    },
    removeFile(index, val) {
      console.log(index, val)
      this.fileList.splice(index, 1)
    },
    //   查询会议类型
    listmeetypes() {
      listmeetypes().then(res => {
        if (res && res.code === '000000') {
          this.dataList = res.data.list
        } else {
          Message({
            message: '会议类型查询失败！',
            type: 'error'
          })
        }
      })
    },
    // 点击继续添加会议决议
    addSelfInputList() {
      if (this.workForm.selfInputList.length > 14) {
        Message({
          message: '最多可添加15组自定义项',
          type: 'error'
        })
        return false
      }
      this.workForm.selfInputList.push(JSON.parse(JSON.stringify(this.templateObj)))
    },
    // 点击保存为草稿或者提交
    reportClickStage(val) {
      if (val === 1) {
        let data = this.getqueryData(val)
        let arr = []
        for (let i in data) {
          if (i === 'meetStage') {
            arr.push('0')
          } else if (data[i] === '' || data[i] === [] || (typeof data[i] === Array && data[i].length < 1)) {
            arr.push('0')
          } else {
            arr.push('1')
          }
        }
        if (arr.includes('1')) {
          this.savemeetinfo(data)
          return
        } else {
          Message({
            message: '请至少填写一项',
            type: 'error'
          })
        }
        return
      }
      this.$refs['formMeeting'].validate(valid => {
        if (valid) {
          if (val == 1) {
            this.subBtnDisabled = true
            this.saveBtnStatu = !this.saveBtnStatu
          } else {
            this.subBtnStatu = !this.subBtnStatu
            this.saveBtnDisabled = true
          }
          if (this.formListState.uploadFiles.isRequired === 1 && this.fileList.length < 1) {
            Message({
              message: '请至少上传一个文件！',
              type: 'error'
            })
            this.saveBtnDisabled = false
            this.subBtnStatu = false
            return
          }
          if (this.formListState.addMeet.isRequired === 1 && this.workForm.selfInputList.length < 1) {
            Message({
              message: '请至少添加一个会议决议！',
              type: 'error'
            })
            this.saveBtnDisabled = false
            this.subBtnStatu = false
            return
          }
          let data = this.getqueryData(val)
          this.savemeetinfo(data)
        } else {
          return false
        }
      })
    },
    // 点击删除会议决议
    deleteSelfList(index) {
      MessageBox.confirm('确定删除当前会议决议吗', '提示', {
        confirmButtonText: `确定`,
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.workForm.selfInputList.splice(index, 1)
        })
        .catch(() => {
          Message.info(`已取消操作`)
        })
    },
    handleRemove(file, fileList) {
      //
    },
    handlePreview(file) {
      //
    },
    handleExceed(files, fileList) {
      Message({
        message: '当前限制选择 10 个文件！',
        type: 'error'
      })
      // this.$message.warning(
      //   `当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length +
      //     fileList.length} 个文件`
      // )
    }
  }
}
</script>

<style lang="scss" scoped>
.#{$css-prefix}-write-dayContent {
  width: 100%;
  height: 100%;
  .threeDown {
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
    .resTitle {
      display: none;
      width: 100%;
      height: 60px;
      background: $white;
      line-height: 60px;
      padding-left: 30px;
      font-size: 18px;
      color: $lightblack;
      font-weight: bold;
    }
    .box {
      height: 100%;
      flex: 1;
      overflow: auto;
      padding: 10px 30px 20px 30px;
      .el-icon-circle-plus-outline {
        padding-right: 10px;
      }
      /deep/.el-select__input {
        margin-left: 0;
        border-bottom: none;
      }
      /deep/.el-input__inner {
        padding-left: 0;
        border-bottom: none;
      }
      .person-selected {
        /deep/.el-select__tags .el-tag {
          margin: 0 10px 0 0;
          line-height: 28px;
          padding: 0 17px 0 12px;
        }
      }
      .carryOn {
        margin-top: 10px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        background-color: white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 15px;
      }
      .carryOn:hover {
        color: $blue;
      }
      .telldiv {
        .tell {
          height: 12px;
          font-size: 12px;
          font-weight: 400;
          color: $lightblack;
          line-height: 12px;
          margin: 6px 0 10px 0;
        }
        .upload-demo {
          display: inline-block;
          width: calc(100% - 15px);
          height: 64px;
          line-height: 64px;
          border: 1px solid #dddddd;
          margin-right: 15px;
          text-align: center;
          margin-bottom: 15px;
        }
        .upload-demo:hover {
          border: 1px solid $blue;
          color: $blue;
        }
        .fileOption {
          margin: 10px 15px 10px 0;
        }
      }
      .scoreLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0 15px 0;
        line-height: 14px;
        .scoreTitle {
          font-size: 14px;
          color: $lightblack;
        }
        .el-icon-delete {
          font-size: 12px;
          color: #666666;
          cursor: pointer;
        }
        .el-icon-delete:hover {
          color: $blue;
        }
      }
    }
    .saveBox {
      height: 64px;
      background: $white;
      box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.05);
      line-height: 64px;
      text-align: right;
      padding-right: 30px;
      z-index: 2;
      .subButton {
        width: 167px;
        height: 44px;
      }
    }
    .redStar {
      color: #f56c6c;
    }
  }
}
</style>
