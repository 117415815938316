<template>
  <!-- 行为规范月度自评弹框 -->
  <el-dialog title="行为规范月度自评" :visible.sync="lookDialog" center width="560px" :before-close="handleClose">
    <div class="container">
      <div class="month">
        <span class="tojoyMonth">{{ selfEvaObj.dateTime.slice(5, 7) }}</span>
        <span class="year">{{ selfEvaObj.dateTime.slice(0, 4) }}</span>
      </div>
      <div style="width:440px">
        <div class="userInfo">
          <div class="imgs">
            <el-avatar :size="46" :class="selfEvaObj.lookUserInfo.avatar ? '' : 'no-avatar'" :src="selfEvaObj.lookUserInfo.avatar">
              <span v-if="selfEvaObj.lookUserInfo.userName">{{ selfEvaObj.lookUserInfo.userName.slice(-2) }}</span>
            </el-avatar>
          </div>
          <div class="info">
            <p>{{ selfEvaObj.lookUserInfo.userName }}</p>
            <span>{{ selfEvaObj.lookUserInfo.jobName }}</span>
          </div>
        </div>
        <div class="box-content" v-for="item in dataList" :key="item.id">
          <p style="color:#333">{{ item.title }}</p>
          <p class="btn" :class="achieveMap[item.achieve].class">{{ achieveMap[item.achieve].text }}</p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    lookDialog: {
      type: Boolean,
      defaule: false
    },
    dataList: {
      type: Array
    },
    selfEvaObj: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      achieveMap: {
        0: { text: '未做到', class: 'not' },
        1: { text: '基本做到', class: 'basic' },
        2: { text: '完全做到', class: 'complete' }
      }
    }
  },
  methods: {
    handleClose() {
      this.$emit('update', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  .month {
    width: 50px;
    height: 50px;
    background: #ffffff;
    border-radius: 3px;
    opacity: 0.99;
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
    margin-right: 15px;
    text-align: center;
    .tojoyMonth {
      display: block;
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 16px;
      margin-top: 7px;
    }
    .year {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 10px;
    }
  }
  .userInfo {
    display: flex;
    padding-bottom: 15px;
    border-bottom: solid 1px #eee;

    .imgs {
      margin-right: 10px;
      .no-avatar {
        background: $blue;
      }
    }
    .info {
      color: #999999;
      font-size: 13px;
      p {
        font-size: 16px;
        color: #333333;
        margin-bottom: 7px;
      }
    }
  }

  .box-content {
    padding: 15px 0;
    border-bottom: solid 1px #eee;
    .btn {
      margin-top: 15px;
      font-size: 14px;
      font-weight: 500;
    }
    .not {
      color: #ff526a;
    }
    .basic {
      color: #3d7eff;
    }
    .complete {
      color: #06ae56;
    }
  }
}
</style>
