<script lang="jsx">
import TojoyRate from '@/components/common/rate'
import OrganizationTip from './organization-tip'
import { DEMOCRACY_RATE_DATA } from './state.js'

export default {
  name: 'democracy-list-item',
  components: {
    TojoyRate,
    OrganizationTip
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  render(h) {
    const {data} = this
    return (
      <div class="democracy-list-item">
        <organization-tip createDate={data.createDate}  reportType={data.reportType} type={data.type} name={data.groupOrgName} />
        <h3 class="democracy-list-item__title">
          <p>{data.peopleNums ?? 0}人参评：</p>
          {/**onClick={() => {this.$router.push({name: 'Home'})}} */}
          <el-button type="text">{data.isFinish ? `${(data.score ?? 0)}分` : '评价中'}</el-button>
        </h3>
        <div class="democracy-list-item__rate-wrap">
          {
            data?.rateList?.map((r, index) => {
              return (
                <tojoy-rate v-model={r.score} disabled={true} title={r.name} />
              )
            })
          }
        </div>
        {
          parseInt(data.suggestNum) ? (
          <div>
            <h4 class="democracy-list-item__desc-title">
              {data.suggestNum ?? 0}条建议
            </h4>
            <ol class="ol__desc-list">
            {
              data?.suggest?.map((item, index) => {
                return (
                  <li key={index}>
                    <span class="info-desc__dist"></span>
                    <p class="info-desc__text">
                      {item}
                    </p>
                  </li>
                )
              })
            }
            </ol>
          </div>
          ) : ''
        }
      </div>
    )
  },
}
</script>
<style lang="scss">
.democracy-list-item {
  margin-left: 16px;
  margin-bottom: 15px;
  &__rate-wrap {
    .tojoy-rate {
      margin-bottom: 11px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    color: $lightblack;
    line-height: 16px;
    padding: 5px 0 15px;
    display: flex;
    justify-content: space-between;
    .el-button {
      padding: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
  }
  &__desc-title {
    font-size: 14px;
    font-weight: bold;
    color: $lightblack;
    line-height: 14px;
    padding: 20px 0 10px;
  }
}
</style>
