<template>
  <div>
    <el-dialog
        class="tojoy-dialog__index"
      :visible.sync="display"
      width="560px"
        center
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="
        () => {
          handleBeforeClose('cancel');
        }
      "
    >
      <template #title>
        <div class="tojoy-dialog__header--title">
          <span>评分</span>
        </div>
      </template>

      <div class="tojoy-dialog__body--form">
        <el-form ref="dialogForm" >
          <el-form-item class="form-item required" label="评分">
            <!--<el-select-->
                <!--class="pleaseWrite"-->
                <!--v-model="score"-->
                <!--placeholder= "请选择"-->
                <!--style="width: 100%"-->
            <!--&gt;-->
              <!--<el-option-->
                  <!--v-for="item in scoreOptions"-->
                  <!--:key="item.value"-->
                  <!--:label="item.label"-->
                  <!--:value="item.value"-->
              <!--&gt;-->
              <!--</el-option>-->
            <!--</el-select>-->
            <write-score v-model="score" :default-score="defaultScore" :max-score-number="maxNumberScore"></write-score>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="primary"
            @click="
              () => {
                handleBeforeClose('ok');
              }
            "
            >完成</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script >
import WriteScore from '../../common/WriteScore';
export default {
  name: 'target-index-lscore',
  components: { WriteScore },
  props: {
    display: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    maxNumberScore: {
      type: Number,
      default: () => {
        return 100;
      },
    },
    defaultScore: {
      type: Number,
      default: () => {
        return null;
      },
    }
  },
  data() {
    return {
      score: '',
    };
  },
  computed:{
    scoreOptions(){
      let length = 0
      let maxScoreNumber = this.maxNumberScore+''
      if((maxScoreNumber).indexOf('.')>-1){
        length =  Math.floor(this.maxNumberScore/1)
      }else{
        length = this.maxNumberScore/1
      }
      let options =  (Object.keys(Array.apply(null, { length })).map(
          (item) => {
            let num = ++item * 1;
            return {
              value: num,
              label: num+'分',
            };
          },
      )).reverse()
      options.push({value:0, label:'0分'})
      if((maxScoreNumber).indexOf('.')>-1){
        options.unshift({value:this.maxNumberScore, label:this.maxNumberScore+'分'})
      }
      return options
    },
  },

  methods: {
    handleBeforeClose(val) {
      this.$emit('before-close', val, this.score);
    },
  },
};
</script>
