<template>
  <div class="tojoy-technological-title">
    <div class="main-title">
      <span>{{ titleContent.title }}</span>
      <el-tooltip
        class="item"
        effect="dark"
        :content="titleContent.titleTip"
        placement="right"
        v-if="titleContent.title != ''"
      >
        <i class="el-icon-warning-outline"></i>
      </el-tooltip>
    </div>
    <div class="sele" v-if="titleContent.isModel">
      <span>{{ titleContent.sleTitle }}</span>
      <el-select v-model="titleContent.model" @change="onChanger">
        <el-option
          v-for="(item, index) in titleContent.selData"
          :label="item.label"
          :value="item.value"
          :key="index"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tojoy-technological-title',
  props: {
    titleContent: {
      type: Object,
      default: () => {
        return {
          title: '',
          titleTip: '',
          sleTitle: '',
          isModel: false,
          model: '',
          sleData: [],
        };
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onChanger(val) {
      this.$emit('technological-change', val);
    },
  },

  watch: {},
};
</script>

<style lang="scss">
.#{$css-prefix}-technological {
  &-title {
    display: flex;
    line-height: 30px;
    .main {
      &-title {
        flex: 1;
        text-align: left;

        i {
          width: 14px;
          height: 14px;
          color: $blue;
          margin-left: 5px;
        }
        span {
          font-size: 14px !important;
          color: $lightblack;
          font-weight: bold !important;
        }
      }
    }
    .sele {
      flex: 1;
      text-align: right;
      color: $lightblack;
      font-size: 14px;
      span {
        font-size: 14px !important;
        font-weight: normal !important;
        color: $darkgrey !important;
      }
    }
    .el-select {
      width: 70px;
      border: 0;
      outline: none;
      .el-input__inner {
        color: $blue;
        padding: 0;
        padding-right: 25px;
        text-align: right;
        border: 0;
        height: 30px;
      }
      .el-input .el-select__caret {
        color: $blue;
      }
    }

    .el-input__prefix,
    .el-input__suffix {
      right: 0;
      top: 6px;
    }
  }
}
</style>
