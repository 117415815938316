<script lang="jsx">

export default {
  name: 'tojoy-approval-process',
  props: {
    leveNodeData: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  data() {
    return {
      defaultLeveNode: {
        isMode: true /**是否功能按钮 */,
        color: '#3D7EFF',
        size: 'large',
      },
      funcType:'data',
      node: [],
    };
  },
  mounted() {
    this.node = this.leveNodeData;
  },
	watch:{
		leveNodeData(val){
			this.node = val;
		}
	},
render() {
    const {$scopedSlots} = this;
    return (

    <div class="tojoy-technological">
     <el-timeline>
        {
          this.node.length &&  this.node.map((item,index) => {
            const { size='large',createTime,name,userName,isFulfill,remark,isMode } = item
            return (
              <el-timeline-item
                  icon={isFulfill?'el-icon-check':''}
                  color={isFulfill ?"#06AE56":''}
                  size={size}
                  timestamp={createTime ? createTime:''}>
              <div class={`tojoy-technological__title`}>
	              {name && <h4 class="name" >{ name }</h4>}
	              {userName && <p class="userName">{ userName }</p>}
	              {remark && <p class="remark"> { remark } </p> }
              </div>
             </el-timeline-item>
            )
          })
        }
      </el-timeline>
     </div>
    )
  }
  }
</script>

<style lang="scss">
.#{$css-prefix}-technological {
  display: flex;

  &__title {
    position: relative;
    .del {
      cursor: pointer;
      position: absolute;
      color: $darkgrey;
      top: 0;
      right: 0;
      i {
        margin-right: 5px;
      }
    }
  }
  &__button {
    cursor: pointer;
    color: $blue;
    i {
      font-weight: 600;
      margin-right: 3px;
    }
  }
  .el-timeline-item__node--large {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    border: 3px solid $white;
  }

  .el-timeline {
    width: 100%;
  }

  .el-timeline-item__wrapper {
    top: -2px;
    padding-left: 20px;
    box-sizing: border-box;
  }
	.name{
		font-size: 15px;
		font-weight: bold;
		color: #333333;
	}
	.userName{
		font-size: 14px;
		font-weight: 400;
		color: #666666;
		line-height: 14px;
		margin-top: 13px;
	}
	.remark{
		font-size: 12px;
		font-weight: 400;
		color: #999999;
		line-height: 12px;
		margin-top: 10px;
	}
}
</style>
