import { getStrideDate } from '@/utils/index.js'
/**
 * 民主互评-收到的评价
 * @param {*} data Object
 */
const handleReceivedData = (data = {}) => {
  let _list = []
  if (!data?.list) return false
  for (let one = 0; one < data?.list?.length; one++) {
    const oneItem = data.list[one]
    _list.push({
      isFinish: oneItem.isFinish,
      month: oneItem.createDate.slice(0, 7),
      score: oneItem.score,
      suggest: oneItem.suggest,
      suggestNum: oneItem.suggestNum,
      peopleNums: oneItem.totalVisitNum,
      groupOrgName: oneItem.groupOrgName,
      type: oneItem.type,
      rateList: oneItem.dimensionScoreOutputList,
      reportType: oneItem.reportType,
      createDate: getStrideDate(oneItem.createDate)
    })
  }
  // 相同的月份数据合并-重新组装
  const _months = Array.from(new Set(data?.list?.map(m => m.createDate.slice(0, 7))))
  let _newList = []
  _months.map(item => {
    const _l = _list.filter(l => l.month === item) ?? []
    _newList.push({
      month: item,
      list: _l
    })
  })
  return {
    total: data.total,
    list: _newList
  }
}

/**
 * 民主互评-待我评价
 * @param {*} data
 */
const handleCommentData = (data = []) => {
  let _list = []
  let total = 0
  for (let one = 0; one < data?.length; one++) {
    let oneItem = data[one]
    let createDates = oneItem.date.split('-')
    let _waitList = []
    for (let w = 0; w < oneItem?.list?.length; w++) {
      const _waitScore = oneItem?.list[w]
      let _rateList = []
      for (let r = 0; r < _waitScore?.dimensionScoreList?.length; r++) {
        let rItem = _waitScore?.dimensionScoreList[r]
        _rateList.push({
          id: rItem.id,
          rate: rItem.score,
          title: rItem.name
        })
      }
      _waitList.push({
        organization: _waitScore.deptName,
        detailId: _waitScore.detailId,
        dimensionScore: _waitScore.dimensionScore,
        rateList: _rateList,
        evaluateOrgId: _waitScore.evaluateOrgId,
        evaluatedOrgId: _waitScore.evaluatedOrgId,
        userid: _waitScore.evaluatedUserId,
        username: _waitScore.evaluatedUserName,
        position: _waitScore.job,
        orgName: _waitScore.orgName,
        isViewSuggest: _waitScore.isViewSuggest,
        type: _waitScore.type,
        reportType: _waitScore.reportType,
        orgId: _waitScore.orgId,
        // groupId: _waitScore.groupId,
        avatar: _waitScore.avatar,
        informTime: _waitScore.informTime,
        approveState: _waitScore.approveState //审批状态
      })
      ++total
    }

    _list.push({
      groupId: oneItem.groupId,
      orgName: oneItem.groupOrgName,
      type: oneItem.type,
      reportType: oneItem.reportType,
      createDate: getStrideDate(oneItem.date),
      //提交按钮显示的时间
      submitDate: createDates,
      informTime: oneItem.informTime,
      started: oneItem.started,
      groupWay: oneItem.groupWay,
      waitList: _waitList
    })
  }
  return {
    list: _list,
    total
  }
}

/**
 * 民主互评-待我评价-提交
 * @param {*} data
 */
const handleCommentSubmitData = (data = []) => {
  let _list = []
  for (let one = 0; one < data?.length; one++) {
    const oneItem = data[one]
    let _rateList = []
    for (let r = 0; r < oneItem?.rateList?.length; r++) {
      const rItem = oneItem?.rateList[r]
      _rateList.push({
        id: String(rItem.id),
        name: String(rItem.title),
        score: String(rItem.rate)
      })
    }
    _list.push({
      detailId: oneItem.detailId,
      evaluateOrgId: oneItem.evaluateOrgId,
      evaluatedOrgId: oneItem.evaluatedOrgId,
      evaluatedUserId: oneItem.userid,
      evaluatedUserName: oneItem.username,
      suggest: oneItem.suggest,
      scoreList: _rateList
    })
  }
  return {
    evaluateNewWaitScoreInput: _list
  }
}

/**
 * 民主互评-我已评的
 * @param {*} data
 */
const handleCompleteData = (data = {}) => {
  let _list = []
  for (let one = 0; one < data?.list?.length; one++) {
    const oneItem = data?.list[one]
    let _scoreList = []
    for (let s = 0; s < oneItem?.scoreListOutputs?.length; s++) {
      const _s = oneItem?.scoreListOutputs[s]
      let _outputs = []
      for (let o = 0; o < _s?.evaluatedScoreOutputList?.length; o++) {
        const oItem = _s?.evaluatedScoreOutputList[o]
        let _rateList = []
        for (let r = 0; r < oItem?.dimensionScoreList?.length; r++) {
          const rItem = oItem?.dimensionScoreList[r]
          _rateList.push({
            id: rItem.id,
            title: rItem.name,
            rate: rItem.score
          })
        }
        _outputs.push({
          avatar: oItem.avatar,
          organization: oItem.deptName,
          rateList: _rateList,
          userid: oItem.evaluatedUserId,
          username: oItem.evaluatedUserName,
          groupId: oItem.groupId,
          orgName: oItem.groupOrgName,
          position: oItem.job,
          month: oItem.month,
          remark: oItem.suggest,
          type: oItem.type
        })
      }
      _scoreList.push({
        groupId: _s.groupId,
        orgName: _s.groupOrgName,
        createDate: getStrideDate(oneItem.createDate),
        type: _s.type,
        reportType: _s.reportType,
        outputs: _outputs
      })
    }
    _list.push({
      scoreList: _scoreList,
      month: oneItem.createDate.slice(0, 7)
    })
  }
  return {
    list: _list,
    total: data.total
  }
}

/**
 * 团队互评-我的团队
 * @param {*} data
 */
const handleTeamReceivedData = (data = {}) => {
  // let _list = []
  let _mList = []
  for (let one = 0; one < data?.list?.length; one++) {
    const oneItem = data?.list[one]

    for (let m = 0; m < oneItem?.list?.length; m++) {
      const mItem = oneItem?.list[m]
      let _rateList = []
      for (let r = 0; r < mItem?.scoreList?.length; r++) {
        const rItem = mItem?.scoreList[r]
        _rateList.push({
          id: rItem.id,
          title: rItem.name,
          rate: rItem.score
        })
      }
      _mList.push({
        userid: mItem.userId,
        username: mItem.userName,
        avatar: mItem.avatar,
        position: mItem.job,
        organization: mItem.deptName,
        score: mItem.score,
        suggestList: mItem.suggestList,
        suggestNum: mItem.suggestNum,
        visitNum: mItem.visitNum,
        type: mItem.type,
        rateList: _rateList,
        reportType: oneItem.reportType,
        createDate: getStrideDate(oneItem.evaluateDate),
        createMonth: oneItem.evaluateDate.slice(0, 7)
      })
    }
    // _list.push({
    //   list: _mList,
    //   month: oneItem.evaluateDate.split('-')
    // })
  }
  // console.log(_mList, '_mList_mList')
  // 相同的月份数据合并-重新组装
  const _months = Array.from(new Set(data?.list?.map(m => m.evaluateDate.slice(0, 7))))
  // console.log(_months, '_months_months')
  let _newList = []
  _months.map(item => {
    const _l = _mList.filter(l => l.createMonth === item) ?? []
    _newList.push({
      list: _l,
      month: item
    })
  })
  return {
    list: _newList,
    total: data.total
  }
}

/**
 * 团队互评-待我审批列表  我已审批数据
 * @param {*} data
 */
const handleApproveList = (data = {}) => {
  let _list = []
  for (let one = 0; one < data?.list?.length; one++) {
    let oneItem = data.list[one]
    let _users = []
    for (let u = 0; u < oneItem?.invitedUserVoList?.length; u++) {
      let userItem = oneItem?.invitedUserVoList[u]
      _users.push({
        avatar: userItem.avatar,
        position: userItem.job,
        userid: userItem.userId,
        username: userItem.userName,
        organization: userItem.department
      })
    }
    _list.push({
      avatar: oneItem.avatar,
      position: oneItem.job,
      userid: oneItem.userId,
      username: oneItem.userName,
      data: _users,
      version: oneItem.version,
      approveState: oneItem.approveState,
      approveStateStr: oneItem.approveStateStr,
      approveRemark: oneItem.approveRemark,
      approveTimeStr: oneItem.approveTimeStr,
      id: oneItem.id,
      inviteUserId: oneItem.inviteUserId
    })
  }
  return {
    list: _list,
    total: data.total
  }
}

export const BF_TYPE = {
  receivedList: 'MINE_RECEIVED_LIST',
  commentList: 'MINE_COMMENT_LIST',
  commontSubmit: 'MINE_COMMENT_SAVE',
  completeList: 'MINE_COMPLETE_LIST',
  approveList: 'MAIN_STATISFACTION_APPROVE_LIST',
  teamReceivedList: 'TEAM_RECEIVE_LIST'
}

const _map = new Map()
_map.set(BF_TYPE.receivedList, handleReceivedData)
_map.set(BF_TYPE.commentList, handleCommentData)
_map.set(BF_TYPE.commontSubmit, handleCommentSubmitData)
_map.set(BF_TYPE.completeList, handleCompleteData)
_map.set(BF_TYPE.approveList, handleApproveList)
_map.set(BF_TYPE.teamReceivedList, handleTeamReceivedData)

export const handleDataBF = (data = {}, type) => {
  return _map.get(type)(data)
}
