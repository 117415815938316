<template>
  <div class="tojoy-pagination-list">
    <div ref="tojoy-pagination-wrap-ref" class="tojoy-pagination-list__wrap show-radius">
      <div class="appraiser">
        <!-- {{ cutType }}----{{ changeType }} -->
        <div class="appraiser-title">
          {{ cutType ? '组织互评' : '你的评价人' }}
          <span v-if="user.reportUsers.length">({{ user.total }})</span>
          <p class="cutType" v-if="changeType" @click="cutTypeBtn">
            <img src="@/assets/images/cut.png" />
            {{ cutType ? '切至员工互评' : '切至组织互评' }}
          </p>
        </div>
        <!-- 组织互评 waytype=0 跳邀请页为展示1是邀请 -->
        <div v-if="cutType" class="appraiser-content">
          <div class="appraiser-l">
            <div class="appraiser-l-peop">
              <div class="block" v-for="(iemInvite, index) in user.reportUsers" :key="index">
                <div v-if="iemInvite.avatar" class="avatar">
                  <img :src="iemInvite.avatar ? iemInvite.avatar : defaultAvatar" />
                </div>
                <div v-else style="text-align: center;">
                  <div class="avatar avatardefault">
                    {{ iemInvite.userName }}
                  </div>
                </div>
                <p>{{ iemInvite.userName }}</p>
              </div>
            </div>
            <div class="allInvite">
              <div
                class="all"
                @click="
                  $router.push({
                    name: 'democracy-inviter',
                    query: {
                      wayType: 0,
                      type: 0,
                      orgNum: user.reportOrgNum
                    }
                  })
                "
              >
                <img src="@/assets/images/omit.png" />
              </div>
              <p>全部</p>
            </div>
          </div>
          <div class="appraiser-r">共参与 {{ user.reportOrgNum }} 个组织互评</div>
        </div>
        <!-- 员工互评 -->
        <div v-else>
          <!-- 员工邀请 -->
          <div v-if="groupWay == 2" class="appraiser-content">
            <div class="appraiser-l" v-if="!user.unreport">
              <div class="appraiser-l-peop" v-if="user.reportUsers.length">
                <div class="block" v-for="(iemInvite, index) in user.reportUsers" :key="index">
                  <div v-if="iemInvite.avatar" class="avatar">
                    <img :src="iemInvite.avatar ? iemInvite.avatar : defaultAvatar" />
                  </div>
                  <div v-else style="text-align: center;">
                    <div class="avatar avatardefault">
                      {{ iemInvite.userName }}
                    </div>
                  </div>
                  <p>{{ iemInvite.userName }}</p>
                </div>
              </div>
              <div v-else class="noPeople">请在民主评议开始前完成邀请并提交审批人审批通过后生效，否则会影响你的本项维度得分</div>
              <div
                class="addInvite"
                @click="
                  $router.push({
                    name: 'democracy-inviter',
                    query: {
                      wayType: 1
                    }
                  })
                "
              >
                <div class="add">
                  <img src="@/assets/images/add1.png" />
                </div>
                <p v-if="!user.reportUsers.length">去邀请</p>
                <p v-else>修改</p>
              </div>
            </div>
            <component :is="'InviterTitleState'" :data="user" :inviteS="approveState" :state="receive" v-if="!user.unreport && user.reportUsers.length && approveState"></component>
            <div class="appraiser-r" v-if="!user.unreport && !approveState">
              <span class="appraiser-r-state">待邀请</span>
              <span class="appraiser-r-name">审批人：-</span>
            </div>
          </div>
          <!-- 策略分组 -->
          <div v-if="groupWay == 1" class="appraiser-content">
            <div class="appraiser-l" v-if="!user.unreport">
              <div class="appraiser-l-peop" v-if="user.reportUsers.length">
                <div class="block" v-for="(iemInvite, index) in user.reportUsers" :key="index">
                  <div v-if="iemInvite.avatar" class="avatar">
                    <img :src="iemInvite.avatar ? iemInvite.avatar : defaultAvatar" />
                  </div>
                  <div v-else style="text-align: center;">
                    <div class="avatar avatardefault">
                      {{ iemInvite.userName }}
                    </div>
                  </div>
                  <p>{{ iemInvite.userName }}</p>
                </div>
              </div>
              <div v-else class="noPeople">
                请联系BP在民主评议开始前完成你的互评人员配置，否则会影响你的本项维度得分
              </div>
              <div
                class="allInvite"
                v-if="user.reportUsers.length"
                @click="
                  $router.push({
                    name: 'democracy-inviter',
                    query: {
                      wayType: 0,
                      type: 1
                    }
                  })
                "
              >
                <div class="all">
                  <img src="@/assets/images/omit.png" />
                </div>
                <p>全部</p>
              </div>
            </div>
            <div class="appraiser-r" v-if="!user.unreport && user.reportUsers.length">
              <p class="useTime">应用于{{ user.beginTime }}后开始的民主评议</p>
            </div>
          </div>
          <!-- 试用期员工 和不参评员工  -->
          <div v-if="user.free || user.unreport" class="appraiser-content">
            <div class="appraiser-l">
              <div class="appraiser-l-peop">
                <div class="block" v-for="index in 10" :key="index">
                  <div class="round"></div>
                  <div class="rectangle"></div>
                </div>
              </div>
            </div>
            <div class="appraiser-r">
              <p v-if="user.free" class="useTime">试用期员工入职次月才需要参与民主互评</p>
              <p v-else class="useTime">{{ user.beginTime }}管理员设置你无需参与员工民主评议</p>
            </div>
          </div>
        </div>
      </div>

      <div class="item-card" v-for="(item, index) in data" :key="index">
        <slot name="item" :data="item"> </slot>
      </div>
    </div>
    <el-pagination @current-page="currentPage" background layout="prev, pager, next" :page-size="size" :total="total" @current-change="handleChange(val)" />
    <!-- <tojoy-blank v-else :blank-data="{ type: 'notdata' }" /> -->
  </div>
</template>

<script>
import { InviterTitleState } from '@/components/democracy/state.js'
import { organReceList, perReceList } from '@/api/api_democracy'
const defaultAvatar = require('@/assets/images/profile.png')
export default {
  // name: 'tojoy-pagination-list',
  components: {
    InviterTitleState
  },
  props: {
    total: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 10
    },
    data: {
      type: Array,
      default: () => []
    },
    schema: {
      type: String,
      default: 'line' // line | block
    },
    currentPage: {
      type: Number,
      default: 1
    },
    loading: {
      type: Boolean,
      default: false
    },
    // nodataSchema: {
    //   type: String,
    //   default: 'notdata'
    // },
    showRadius: {
      type: Boolean,
      default: true
    },
    evalType: {
      type: Number
    }
  },
  watch: {
    currentPageVal: {
      immediate: true,
      handler(val) {
        if (this.scrollDom) {
          this.scrollDom.scrollTop = 0
        }
      }
    }
  },
  data() {
    return {
      user: {
        reportUsers: []
      },

      orgAppraiserList: [], //组织邀请人
      type: 1,
      cutType: false, //如果组织有数据，可以来回切换
      changeType: false, //组织是否有数据 默认没有
      groupWay: null,
      approveState: 'allInvite',
      receive: 'receive',
      stateAttrs: {
        // 0: 'awaitInvite', //待邀请
        1: 'auditing', //审批中
        10: 'auditPass', //审批通过
        12: 'yetReject', //已驳回
        13: 'auditAutoReject', //系统自动驳回
        14: 'auditReject', //管理员驳回
        20: 'amendInvite' //撤回修改
      }
    }
  },
  async updated() {
    await this.$nextTick()
    this.scrollDom = this.$refs['tojoy-pagination-wrap-ref']
  },
  created() {
    this.loadOrgReceivedData()
  },
  methods: {
    handleChange(val) {
      this.currentPageVal = val
      this.resetScrollTop()
      this.$emit('page-change', val)
    },
    resetScrollTop() {
      if (this.scrollDom) {
        this.scrollDom.scrollTop = 0
      }
    },
    // 组织
    loadOrgReceivedData(params, options = {}) {
      let pam = {
        size: 10,
        page: 1
      }
      organReceList({ ...params, ...pam }, options).then((res, data) => {
        //如果组织有数据
        if (res.code == '000000' && res.data?.reportUsers) {
          this.changeType = true
          this.cutType = true
          // console.log(this.cutType, '是否切换111111', this.changeType, '是否有组织数据')
          this.user = res.data ?? {}
        } else {
          this.cutType = false
          this.changeType = false
          // 否则调员工接口
          this.loadReceivedData()
        }
      })
    },
    // 个人员工
    loadReceivedData(params, options = {}) {
      let pam = {
        size: 10,
        page: 1
      }
      perReceList({ ...params, ...pam }, options).then((res, data) => {
        if (res.code === '000000') {
          // this.evalType = 1
          this.user = res.data ?? {}
          this.user.reportUsers = res.data.reportUsers ?? []
          this.groupWay = res.data.groupWay
          this.approveState = this.stateAttrs[res.data.approveState]
          // console.log(this.cutType, '是否切换2222222', this.changeType, '是否有组织数据')
        }
      })
    },
    //组织员工点击切换
    cutTypeBtn() {
      this.cutType = !this.cutType
      if (!this.cutType) {
        this.loadReceivedData()
      } else {
        this.loadOrgReceivedData()
      }
    }
  }
}
</script>

<style lang="scss">
.tojoy-pagination-list {
  .appraiser {
    background-color: #fff;
    padding: 15px 20px 10px 15px;
    border-bottom: solid 1px #eeeeee;
    &-title {
      color: #333;
      font-size: 14px;
      margin-bottom: 10px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      span {
        color: #999999;
      }
      .cutType {
        font-size: 12px;
        margin-left: 20px;
        cursor: pointer;
        span {
          color: #3d7eff;
          margin-left: 6px;
        }
      }
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .appraiser-l {
        display: flex;
        .appraiser-l-peop {
          display: flex;
          .block {
            display: flex;
            flex-direction: column;
            margin-right: 19px;
            justify-content: center;
            text-align: center;
            cursor: pointer;
            .avatar {
              width: 38px;
              height: 38px;
              border-radius: 50%;
              border: 2px solid #fff;
              margin: 0 auto;
              background: #fff;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .avatardefault {
              background: $blue;
              line-height: 32px;
              text-align: center;
              color: #fff;
              font-size: 12px;
              margin-bottom: 3px;
            }
            p {
              width: 42px;
              margin-top: 8px;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .round {
              width: 38px;
              height: 38px;
              background: #f3f3f3;
              border-radius: 50%;
              margin-bottom: 8px;
            }
            .rectangle {
              width: 38px;
              height: 12px;
              background: #f3f3f3;
            }
          }
        }

        .allInvite {
          cursor: pointer;
          .all {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            border: 1px solid #eeeeee;
            margin-bottom: 11px;
            img {
              margin-top: 18px;
              margin-left: 11px;
            }
          }
          p {
            text-align: center;
            color: #3d7eff;
            font-size: 12px;
          }
        }
        .addInvite {
          cursor: pointer;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .add {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            border: 1px solid #eeeeee;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 11px;
            img {
              display: block;
              width: 16px;
              height: 16px;
            }
          }
          p {
            color: #3d7eff;
            font-size: 12px;
          }
        }

        .noPeople {
          background: #fef2e8;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #fe831a;
          width: 400px;
          height: 58px;
          padding: 11px 10px;
          margin-right: 16px;
        }
      }

      .appraiser-r {
        &-state {
          margin-right: 15px;
          color: #fe831a;
        }
        .useTime {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
      & > .inviter-title {
        // 收到评价右边展示
        .extInvite {
          font-size: 14px;
          .passStyle {
            // .passdesc {
            //   max-width: 600px;
            //   padding: 7px 10px;
            //   font-size: 12px;
            //   text-align: end;
            //   text-justify: newspaper;
            //   word-break: break-all;
            //   &--green {
            //     background-color: #e6f6ee;
            //   }
            //   &--red {
            //     background-color: #ffedf0;
            //   }
            // }
          }
          .ext-top {
            margin-bottom: 10px;
            display: flex;
            justify-content: flex-end;
            &-s {
              font-weight: bold;
            }
            .ext-approver {
              margin-left: 15px;
              color: #333;
              font-weight: normal;
            }
          }
        }
        color: $lightblack;
        &--orange {
          color: #fe831a;
        }
        &--blue {
          color: #3d7eff;
        }
        &--green {
          color: $green;
        }
        &--red {
          color: $red;
        }
        .desc {
          max-width: 600px;
          padding: 7px 10px;
          font-size: 12px;
          text-align: end;
          text-justify: newspaper;
          word-break: break-all;
          &--green {
            background-color: #e6f6ee;
          }
          &--orange {
            background-color: #fef2e8;
          }
          &--blue {
            background-color: #ebf2ff;
          }
          &--red {
            background-color: #ffedf0;
          }
        }
        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
        }
      }
    }
  }
}
</style>
