<template>
	<div>
		<el-input
			v-only-number="{length:length
			}"
			:value="userInput"
			:placeholder="`请输入,最高${maxScoreNumber}分（必填*）`"
			@input="handleInput"
			>
		</el-input>
	</div>
</template>

<script>
	export default {
		name: 'WriteScore',
		props:{
			value: {},
			label:{
				type: String,
				default:''
			},
			maxScoreNumber:{
				type: Number,
				default: 100,
			},
			length:{
				type: Number,
				default: 2,
			},
			defaultScore:{
				type: Number,
				default: null,
			}
		},
		data() {
			return {
				currentValue: 0,
				userInput: null,
				firstEnter: true,
			};
		},
		watch: {
			value: {
				immediate: true,
				handler (value) {
					if(this.firstEnter && !value && this.defaultScore){
						this.firstEnter = false
						this.userInput = this.defaultScore
					}else{
						this.userInput = value
					}
					this.$emit('input',this.userInput)
				},
			},
		},
		methods:{
			handleInput(value) {
				let curVal = value
				if(curVal>=this.maxScoreNumber) {
					curVal = this.maxScoreNumber
				}
				this.userInput = curVal
				this.$emit('input',this.userInput)
			},
		},
	};
</script>

<style scoped>

</style>
