<template>
  <div class="tojoy-details-b__title">
    <div class="personnel" v-if="dataSoure.state == 1 || dataSoure.state == 6">
      <div class="personnelItem tl">
        <span class="title" v-if="dataSoure.title != ''">{{
          dataSoure.title
        }}</span>
      </div>
      <div class="personnelInfoItem tr">
        <div class="personnelState">
          目标确认：
          <span class="sele-title" @click="handleOpen"
            >{{ dataSoure.seleName }} <i class="el-icon-arrow-down"></i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'target-details-title-b',
  components: {},
  props: {
    dataSoure: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    delete this.dataSoure.targetIndexOutputs;
  },
  methods: {
    handleOpen() {
      this.$emit('details-sele-open');
    },
  },
};
</script>

<style lang="scss">
.#{$css-prefix}-details-b {
  &__title {
    .personnel {
      display: flex;
      height: 64px;
      border-bottom: 1px solid #cccccc57;
      align-items: center;
      span {
        &.title {
          line-height: 76px;
          font-size: 15px;
          font-weight: 400;
          color: $darkgrey;
          padding-left: 15px;
        }
        &.sele {
          &-title {
            cursor: pointer;
            font-size: 15px;
            font-weight: 400;
            color: $lightblack;
            i {
              color: $grey;
            }
          }
          &-title:hover{
            @include font-hover-color();
          }
        }
      }
    }
    .personnelState {
      width: 100%;
      line-height: 76px;
      padding-right: 15px;
      font-size: 15px;
      span {
        font-size: 14px;
      }
    }
    .el-icon-arrow-down{
      margin-left: 5px
    }
  }
}
</style>
