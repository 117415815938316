<template>
  <div>
    <el-dialog
        class="tojoy-dialog__index"
        :visible.sync="display"
        width="560px"
        center
        :close-on-click-modal="false"
        :append-to-body="true"
        :before-close="
        () => {
          handleBeforeClose('cancel');
        }
      "
    >
      <template #title>
        <div class="tojoy-dialog__header--title">
          <span>{{ affirmState == '3' ? '同意' : '驳回' }}</span>
        </div>
      </template>

      <div class="tojoy-dialog__body--form">
        <el-form ref="dialogForm" >
          <el-form-item class="form-item" label="意见反馈">
            <el-input
                v-filteremoji
                class="pleaseWrite"
                v-model="remark"
                type="textarea"
                resize="none"
                rows="5"
                show-word-limit
                maxlength="300"
                :placeholder="(affirmState == '3' ? '同意' : '驳回') +'：意见反馈（选填）'"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="tojoy-dialog__footer">
          <el-button
              type="primary"
              @click="
              () => {
                handleBeforeClose('ok');
              }
            "
          >完成</el-button
          >
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script >
  export default {
    name: 'target-index-affirm',
    components: {},
    props: {
      display: {
        type: Boolean,
        default: () => {
          return false;
        },
      },
      affirmState:   // 默认不选中 ！3 ｜ ！6-- 同意3， 拒绝6
          {
            type: Number,
            default: () => {
              return 3;
            },
          }
    },
    data() {
      return {
        remark: '',
      };
    },

    methods: {
      handleBeforeClose(val) {
        this.$emit('before-close', val, this.remark);
      },
    },

    watch: {
      display(val) {
        if (val) this.remark = '';
      },
    },
  };
</script>
