<script lang="jsx">

export default {
  name: 'tojoy-technological',
  props: {
    leveNodeData: {
      type: Array,
      default: () => {
        return []
      },
    },

    /**  xxx  String添加按钮文案   */
    addButtonTxt: {
      type: String,
      default: () => {
        return '添加'
      }
     
    },
    /** 5  Number  最多可以添加多少节点 */
     maxAddNode:{
         type: Number,
          default: () => {
            return '5'
          }
      }
  },
  data() {
    return {
      defaultLeveNode: {
        isDelNode: false/**是否可以删除节点 */,
        isMode: true /**是否功能按钮 */,
        color: '#ccc',
        size: 'large',
      },
      oneExecNumber:0,
      funcType:'data',
      node: [],
    };
  },
  mounted() {
    this.defaultLeveNode ={...this.defaultLeveNode,...{
      leveName: this.addButtonTxt,
      maxAddNode:this.maxAddNode
    }}
    this.node =  this.leveNodeData;
  
  },
  methods: {
    delNode(index, item) {
      this.funcType = 'del'
      this.node.splice(index, 1);
    },
    addNode(item) {
      this.funcType = 'add'
      if( this.node.length > 1){
          
          let leveNode = {...{},...this.node[0]}
          leveNode.name = "";
          leveNode.leveName = this.addButtonTxt;
          leveNode.isDelNode = true;
          this.node.splice(this.node.length -1 , 0, leveNode );
      }
          
    },
  },

  watch: {
    node(nodeArr) {
      this.oneExecNumber = 0
      if (nodeArr.length > this.defaultLeveNode.maxAddNode){
          nodeArr.splice(nodeArr.length - 1, 1);
          ++ this.oneExecNumber
      }
            
      if (nodeArr.length < this.defaultLeveNode.maxAddNode)
        if (
          !nodeArr.filter((item) => {
            return item.isMode;
          }).length
        ) {
          nodeArr.push(this.defaultLeveNode);
          ++this.oneExecNumber
        }
        
        if(this.oneExecNumber <= 0)
          this.$emit('node-callback',this.funcType,this.node.filter(item=>{
            return !item.isMode
          }))

    },
  },

render() {
    const {$scopedSlots,delNode ,addNode} = this;
    return (

    <div class="tojoy-technological ">
    <div class="tojoy-technological__el">
     <el-timeline>
        {
          this.node.length &&  this.node.map((item,index) => {
            const { icon, color, size, timestamp,leveName,isDelNode,isMode } = item
            return (
              <el-timeline-item 
                  icon={icon}
                  color={color}
                  size={size}
                  timestamp={timestamp}>
              <div class={`tojoy-technological__title ${isMode && 'tojoy-technological__button'}`}>
                
                  {
                    isMode && <i class="el-icon-plus"></i> 
                  }
                  <span onClick={(val) => {isMode && addNode(index)}}> {leveName } </span>
        
                  {
                  
                   isDelNode ? <p class="del"  onClick={(val) => {delNode(index)}}>
                      <i class="el-icon-delete"></i>
                      删除节点 
                    </p> : ''
                  }
            
              </div>
               {
                    !isMode &&  $scopedSlots.exec({
                        data: item
                      })
                }
               
             </el-timeline-item>
            )
          })
        }
      </el-timeline>
      </div>
     </div>
    )
  }
  }
</script>

<style lang="scss">
.#{$css-prefix}-technological {
  display: flex;

  &__title {
    position: relative;
    .del {
      cursor: pointer;
      position: absolute;
      color: $darkgrey;
      top: 0;
      right: 0;
      i {
        margin-right: 5px;
      }
    }
  }
  &__button {
    cursor: pointer;
    color: $blue;
    span {
      color: $blue !important;
    }
    i {
      font-weight: 600;
      margin-right: 3px;
    }
  }
}
.tojoy-technological .tojoy-technological__el {
  width: 100%;
  .el-timeline-item__node--large {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    border: 3px solid $white;
  }

  .el-timeline {
    width: 100%;
  }

  .el-timeline-item__node--large {
    left: 0;
  }

  .el-timeline-item__tail {
    left: 6px;
  }

  .el-timeline-item__wrapper {
    top: -2px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  .el-timeline-item__node--large {
    width: 14px;
    height: 14px;
  }
  .el-timeline-item__tail {
    left: 6px;
    border-left: 2px solid #e4e7ed;
  }
  .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
    display: none;
  }
  .el-timeline .el-timeline-item .el-timeline-item__tail {
    display: block;
  }
  .el-timeline-item__wrapper {
    top: -2px;
    padding-left: 20px;
    box-sizing: border-box;
    span {
      font-size: 14px;
      font-weight: 400;
      color: $lightblack;
    }
  }
}
</style>
