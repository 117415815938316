<template>
  <div class="tojoy-dialog__lead">
    <el-dialog
      :visible.sync="display"
      width="560px"
      :before-close="
        () => {
          handleBeforeClose('cancel')
        }
      "
    >
      <template #title>
        <div class="tojoy-dialog__lead--title">三清提交人员统计（{{ allNo-unDoNo }}/{{ allNo }}）</div>
      </template>
      <div class="tojoy-dialog__lead--body">
        <div class="tojoyExamInfo" v-for="(item, index) in urgeUserList" :key="index">
          <small-user-card
            :size="38"
            :data="{
              userid: item.userId || '',
              avatar: item.avatar || '',
              username: item.userName || '',
              position: item.jobName || ''
            }"
            @user-card="
              u => $router.push({ name: 'homepage-navigation', query: { userId: u.userid } })
            "
          >
            <div slot="right" @click.stop>
              <span :class="['tag', item.isDone ? 'yes' : 'no']">{{
                item.isDone ? '已交' : '未交'
              }}</span>
            </div>
          </small-user-card>
        </div>
      </div>
      <template #footer>
        <p class="txt">共{{ unDoNo }}人未交三清</p>
        <span class="dialog-footer">
          <el-button
            :disabled="Number(unDoNo) <= 0"
            type="primary"
            size="small"
            @click="
              () => {
                handleBeforeClose('ok', calUnpaidNumber)
              }
            "
            >一键提醒</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import SmallUserCard from '@/components/democracy/small-user-card.vue'
import { reportNextlevelundo } from '@/api/thrclear'
export default {
  name: 'submit-remind',
  components: {
    SmallUserCard
  },
  props: {
    display: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      urgeUserList: [],
      unDoNo: '',
      allNo: ''
    }
  },
  computed: {
    calUnpaidNumber() {
      return [...this.urgeUserList.filter(item => item.isDone === 0)]
    }
  },
  created() {
    this.getTargetUrgeUser()
  },
  methods: {
    init() {
      this.getTargetUrgeUser()
    },

    async getTargetUrgeUser() {
      reportNextlevelundo().then(res => {
        if (res.code === '000000') {
          if (!res.data) {
            return false
          }
          this.allNo = res.data.allNo
          this.unDoNo = res.data.unDoNo
          this.urgeUserList = res.data.outList
        }
      })
    },
    handleBeforeClose(val, people) {
      this.$emit('before-close', val, people)
    }
  },
  watch: {
    display(val) {
      val && this.init()
    }
  }
}
</script>
<style lang="scss" scoped>
.#{$css-prefix}-dialog {
  &__lead {
    &--title {
      font-size: 18px;
      font-weight: 500;
      color: $lightblack;
      text-align: center;
    }
    &--body {
      max-height: 300px;
      overflow: auto;
      .tojoyExamInfo {
        border-bottom: 1px solid #f1f1f1;
        padding: 16px 0;
        position: relative;
        display: flex;
        .small-user-card {
          flex: 1;
          &__right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .tag {
              display: inline-block;
              padding: 5px 6px;
              border-radius: 3px;
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
              &.yes {
                background: #e1f2ff;
                color: #5c77ac;
              }
              &.no {
                background: #ffeef4;
                color: #db7387;
              }
            }
          }
        }
      }
      .tojoyAvatarTitle {
        font-size: 12px;
        font-weight: 400;
        color: $grey;
      }
      .tojoyAvatarName {
        height: 21px;
        font-size: 15px;
        font-weight: 400;
        color: $lightblack;
        line-height: 15px;
      }
      .tojoyAvatar {
        width: 38px;
        height: 38px;
        background: $red;
        border-radius: 50%;
        display: inline-block;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .tojoyAvatarInfo {
        margin-left: 10px;
        display: inline-block;
        flex-grow: 1;
      }
    }
    .el-dialog__footer {
      .txt {
        color: $darkgrey;
        font-size: 12px;
        line-height: 30px;
      }
      z-index: 10;
      height: 90px;
      text-align: center;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      position: relative;
    }
  }
}
</style>
