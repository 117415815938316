<template>
  <div class="tojoy-details__title">
    <div class="personnel">
      <div class="personnelItem tl">
        <router-link :to="{ name: 'homepage-navigation', query: { userId: dataSoure.userId || userId }}">
          <div style="display: inline-block;height: 46px;width: 46px;" class="target-avatar-wrap">
            <img  v-if="dataSoure.avatar" :src="dataSoure.avatar || defaultAvatar" />
            <span v-else class="target-avatar target-avatardefault">{{  dataSoure.userName? dataSoure.userName.slice(-2):'' }}</span>
          </div>
        </router-link>
        <div class="personnelInfo">
          <p class="name">{{  dataSoure.userName }}</p>
          <p class="post">{{  dataSoure.jobName }}</p>
        </div>
      </div>
      <div class="personnelInfoItem tr">
        <div class="personnelState">
          <!--进度-->
          <template v-if="stateType || (dataSoure.state!=1 && dataSoure.state!=2)">
          <span class="progress"

          >总进度：
            <span class="progressValue">{{ dataSoure.progress || 0}}%</span></span
          >
            <span class="tojoyProgressNum">
            <el-progress
                :percentage="dataSoure.progress || 0"
                :show-text="false"
            ></el-progress>
            </span>
          </template>
          <!--状态-->
          <span :class="`state ${defaultState[dataSoure.state].cl}`">{{
            defaultState[dataSoure.state].tx
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const defaultAvatar = require('@/assets/images/profile.png')

export default {
  name: 'target-details-title-a',
  components: {},
  props: {
    dataSoure: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      stateType: this.$route.query.stateType,
      defaultAvatar,
      defaultState: {
        1: {
          tx: '待提交',
          cl: 'yellow',
        },
        2: {
          tx: '待确认',
          cl: 'blue',
        },
        3: {
          tx: '待自评',
          cl: 'yellow',
        },
        4: {
          tx: '待考评',
          cl: 'blue',
        },
        5: {
          tx: '已完成',
          cl: 'grey',
        },
        6: {
          tx: '已驳回',
          cl: 'yellow',
        },
      },
    };
  },
  mounted() {
    delete this.dataSoure.targetIndexOutputs;
  },
  methods: {},
};
</script>

<style lang="scss">
.#{$css-prefix}-details {
  &__title {
    .personnel {
      display: flex;
      height: 76px;
      border-bottom: 1px solid #cccccc57;
      align-items: center;
      span {
        &.title {
          line-height: 76px;
          font-size: 15px;
          font-weight: 400;
          color: $darkgrey;
          padding-left: 15px;
        }
      }
      .personnelItem {
        flex: 1;
      }
      .personnelItem img {
        width: 46px;
        height: 46px;
        vertical-align: middle;
        border-radius: 100%;
        margin: 0;
      }
      .personnelItem .personnelInfo {
        display: inline-block;
        width: 60%;
        vertical-align: middle;
        margin-left: 1vh;
      }
      .personnelItem .personnelInfo .name {
        font-size: 16px;
        color: #333;
        font-weight: bold;
      }
      .personnelItem .personnelInfo .post {
        font-size: 13px;
        color: #999;
      }
    }
    .personnelState {
      width: 100%;
      line-height: 76px;
      padding-right: 15px;
      font-size: 15px;
      span {
        font-size: 14px;
        &.yellow {
          color: $yellow;
        }
        &.blue {
          color: $blue;
        }
        &.grey {
          color: $grey;
        }
      }
      .progress{
        font-size: 12px;
      }
      .progressValue {
        font-size: 12px;
        color: $blue;
        line-height: 76px;
      }
    }
    .progress {
      display: inline-block;
      line-height: 76px;
    }
    .tojoyProgressNum {
      display: inline-block;
      position: relative;
      margin-left: 1vh;
      width: 60px;
      height: 6px;
      background: $grey650;
      border-radius: 3px;
      top: -2px;
    }
    .lineValue {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 6px;
      background: $blue;
      border-radius: 3px;
    }
    .state {
      display: inline-block;
      font-size: 14px;
      color: $blue;
      margin-left: 2vh;
      line-height: 76px;
    }
  }
}
</style>
