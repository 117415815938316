<template>
  <div class="tojoy-dialog__index">
    <el-dialog
      :visible.sync="display"
      width="560px"
      center
      :close-on-click-modal="false"
      :before-close="
        () => {
          handleBeforeClose('cancel')
        }
      "
    >
      <template #title>
        <div class="tojoy-dialog__header--title">
          <span>更新进度</span>
        </div>
      </template>
      <div class="tojoy-dialog__body--form">
        <el-form ref="dialogForm">
          <div v-if="formData.ype == 1">
            <div class="form-item updateLabel">
              <span class="form-item-label">指标进度<em>（可选择指标值类型，默认为正数）</em></span>
            </div>
            <div style="margin:10px 0">
              <el-radio-group v-model="formData.isPositive" size="mini" class="radio-group">
                <el-radio :label="true" border
                  >正数
                  <img v-if="formData.isPositive" class="okimg" src="@/assets/images/target/selected_ok@2x.png" alt="" />
                </el-radio>
                <el-radio :label="false" border
                  >负数
                  <img v-if="!formData.isPositive" class="okimg" src="@/assets/images/target/selected_ok@2x.png" alt="" />
                </el-radio>
              </el-radio-group>
            </div>
            <el-input
              v-only-number="{ length: 6 }"
              :maxlength="15"
              class="pleaseWrite"
              v-model="formData.taskValue"
              placeholder="输入本人完成的指标值"
              controls-position="right"
              :controls="false"
              style="width: 60%"
              :rules="[
                {
                  required: formData.taskValue ? true : false
                },
                {
                  pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,7})?$/,
                  trigger: ['change', 'blur']
                }
              ]"
            >
            </el-input>
            <span>{{ formData.taskUnit }}</span>
          </div>
          <el-form-item class="form-item" label="指标进度" v-if="formData.ype > 1">
            <el-select class="pleaseWrite" v-model="formData.progress" placeholder="请选择" style="width: 100%">
              <el-option v-for="item in weightOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            v-preventClick
            type="primary"
            @click="
              () => {
                handleBeforeClose('ok')
              }
            "
            >提交更新</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'target-index-progress',
  components: {},
  props: {
    display: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    formData: {
      type: Object,
      default: () => {
        return {
          ype: ''
        }
      }
    }
  },
  data() {
    return {
      weightOptions: Object.keys(Array.apply(null, { length: 11 }))
        .map(item => {
          let num = 0
          return {
            value: num + item * 10,
            label: num + item * 10 + '%'
          }
        })
        .reverse()
    }
  },
  mounted() {},
  watch: {
    display: function(val) {
      if (val) {
        if (this.formData.progress === '' || this.formData.progress === null) {
          this.formData.progress = 0
        }
      }
    }
  },
  methods: {
    handleBeforeClose(val) {
      this.$emit('before-close', val)
    }
  }
}
</script>
<style lang="scss" scoped>
.tojoy-dialog__body--form{
  .updateLabel{
    em{
      font-style: normal;
      color: #999;
    }
  }
}
.radio-group {
  .el-radio.is-bordered.is-checked {
    background-color: #ebf3ff;
  }
  /deep/ .el-radio {
    width: 70px;
    height: 32px;
    padding: 8px 15px 0 10px;
    margin-right: 10px;
    position: relative;
    .el-radio__input {
      display: none;
    }
    .el-radio__label {
      margin-top: 4px;
      font-size: 14px;
      .okimg {
        position: absolute;
        right: 5px;
        bottom: 5px;
        width: 8px;
        height: 6px;
      }
    }
  }
}
</style>
