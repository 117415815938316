<template>
  <div class="tojoy-dialog__lead">
    <el-dialog
        class="tojoy-dialog__index"
      :visible.sync="display"
      width="560px"
      :before-close="
        () => {
          handleBeforeClose('cancel');
        }
      "
    >
      <template #title>
        <div class="tojoy-dialog__lead--title">
          目标提交人员统计（{{ urgeUserList.length -calUnpaidNumber().length }}/{{
            urgeUserList.length
          }}）
        </div>
      </template>
      <div class="tojoy-dialog__lead--body">
        <div
          class="tojoyExamInfo"
          v-for="(item, index) in urgeUserList"
          :key="index"
        >
          <div class="tojoyAvatar target-avatar-wrap"  @click="goPersonDetail(item.userId)">
            <img  v-if="item.avatar" :src="item.avatar || defaultAvatar" />
            <span v-else class="target-avatar target-avatardefault">{{  item.userName? item.userName.slice(-2):'' }}</span>
          </div>
          <div class="tojoyAvatarInfo">
            <p class="tojoyAvatarName">{{ item.userName }}</p>
            <p class="tojoyAvatarTitle">{{ item.jobName }}</p>
          </div>
          <div class="tag">
            <el-tag :type="item.isNonDelivery ? 'info' : 'danger'">{{
              item.isNonDelivery ? '已交' : '未交'
            }}</el-tag>
          </div>
        </div>
      </div>
      <template #footer>
        <p class="txt">共{{ calUnpaidNumber().length }}人未交目标</p>
        <span class="dialog-footer">
          <el-button
              v-preventClick
            type="primary"
            @click="
              () => {
                handleBeforeClose('ok');
              }
            "
            >一键提醒</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script >
import { ApiTargetUrgeUser } from '@/api/api_target';
const defaultAvatar = require('@/assets/images/profile.png')

export default {
  name: 'target-lead-subordinate',
  components: {},
  props: {
    display: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      urgeUserList: [],
      defaultAvatar
    };
  },
  created() {
    this.getTargetUrgeUser();
  },
  methods: {
    goPersonDetail(id) {
      this.$router.push({ path: '/homepage/navigation', query: { userId: id } })
    },
    init() {
      this.getTargetUrgeUser();
    },

    async getTargetUrgeUser() {
      const { code, data } = await ApiTargetUrgeUser();
      if (code === '000000') {
        this.urgeUserList = data.list;
      }
    },
    calUnpaidNumber() {
      return this.urgeUserList.filter((item) => item.isNonDelivery == 0);
    },
    handleBeforeClose(val) {
      this.$emit('before-close', val, this.calUnpaidNumber().length);
    },
  },
  watch: {
    display(val) {
      val && this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.#{$css-prefix}-dialog {
  &__lead {
    /deep/ .el-dialog__footer{
      padding-top: 5px;
    }
    /deep/ .el-dialog__body{
      padding-top: 0;
      padding-bottom: 0;
    }
    &--title {
      font-size: 18px;
      line-height: 18px;
      height: 18px;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    &--body {
      max-height: 350px;
      overflow: auto;
      .tag {
        float: right;
        margin-right: 20px;
        margin-top: 13px;
      }
      /deep/ .el-tag {
        height: 20px;
        line-height: 20px;
      }
      .tojoyExamInfo {
        border-bottom: 1px solid #f1f1f1;
        padding: 16px 0;
        position: relative;
        display: flex;
      }
      .tojoyAvatarTitle {
        font-size: 12px;
        font-weight: 400;
        color: $grey;
      }
      .tojoyAvatarName {
        height: 21px;
        font-size: 15px;
        font-weight: 400;
        color: $lightblack;
        line-height: 15px;
      }
      .tojoyAvatar {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: inline-block;
        overflow: hidden;
        margin: 0;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        .target-avatar{
          font-size: 14px;
        }
      }
      .tojoyAvatarInfo {
        margin-left: 10px;
        display: inline-block;
        flex-grow: 1;
      }
    }
    /deep/ .el-dialog__footer {
      .txt {
        color: $darkgrey;
        font-size: 12px;
        line-height: 30px;
      }
      z-index: 10;
      height: 80px;
      text-align: center;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      position: relative;
    }
    /deep/ .el-button--primary {
      color: $white;
      width: 100px;
      height: 32px;
      background: $blue;
      border-radius: 3px;
      line-height: 32px;
    }
    /deep/ .el-button {
      padding: 0;
    }
  }
}
</style>
