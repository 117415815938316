export const __LIST_STATE__ = {
  1: '待提交',
  2: '待确认',
  3: '待自评',
  4: '待考评',
  5: '已完成',
  6: '已驳回'
}
export const __LIST__ORG__ = { 1: '组织目标', 2: '个人目标' }

export const __TARGET__TYPE__ = {
  1: '定量',
  2: '定性',
  3: '例行'
}

export const __PROCESS_DATA__ = {
  1: [
    {
      leveName: '考评1',
      evalWeight: 100,
      sort: 1,
      size: 'large',
      color: '#3D7EFF',
      icon: 'itc',
      lists: [
        {
          titleContent: {
            title: '目标确认',
            titleTip: '设置目标确认人员，通常选择直属上级'
          },
          processContent: {
            maxAddNode: 6,
            addButtonTxt: '审批节点',
            lists: [
              {
                leveName: '审批节点',
                leveValue: 0,
                flowType: 1,
                name: '',
                isDelNode: false /**是否可以删除节点 */,
                size: 'large',
                color: '#3D7EFF'
              }
            ]
          }
        },
        {
          titleContent: {
            title: '目标考评',
            titleTip: '目标考评支持多个考评节点，以最后节点考评人分数为准'
          },
          processContent: {
            maxAddNode: 6,
            addButtonTxt: '考评节点',
            lists: [
              {
                leveName: '目标自评',
                name: '',
                leveValue: -1,
                flowType: 2,
                isDelNode: false /**是否可以删除节点 */,
                size: 'large',
                color: '#3D7EFF'
              },
              {
                leveName: '考评节点',
                name: '',
                leveValue: 0,
                flowType: 2,
                isDelNode: false /**是否可以删除节点 */,
                size: 'large',
                color: '#3D7EFF'
              }
            ]
          }
        }
      ]
    }
  ],
  2: [
    {
      leveName: '考评1',
      evalWeight: 50,
      sort: 1,
      size: 'large',
      color: '#3D7EFF',
      icon: 'itc',
      lists: [
        {
          titleContent: {
            title: '目标确认',
            titleTip: '设置目标确认人员，通常选择直属上级'
          },
          processContent: {
            maxAddNode: 6,
            addButtonTxt: '审批节点',
            lists: [
              {
                leveName: '审批节点',
                flowType: 1,
                name: '',
                leveValue: 0,
                isDelNode: false /**是否可以删除节点 */,
                size: 'large',
                color: '#3D7EFF'
              }
            ]
          }
        },
        {
          titleContent: {
            title: '目标考评',
            titleTip: '目标考评支持多个考评节点，以最后节点考评人分数为准'
          },
          processContent: {
            maxAddNode: 6,
            addButtonTxt: '考评节点',
            lists: [
              {
                leveName: '目标自评',
                flowType: 2,
                name: '',
                leveValue: -1,
                isDelNode: false /**是否可以删除节点 */,
                size: 'large',
                color: '#3D7EFF'
              },
              {
                leveName: '考评节点',
                name: '',
                flowType: 2,
                leveValue: 0,
                isDelNode: false /**是否可以删除节点 */,
                size: 'large',
                color: '#3D7EFF'
              }
            ]
          }
        }
      ]
    },
    {
      leveName: '考评2',
      evalWeight: 50,
      sort: 2,
      size: 'large',
      color: '#3D7EFF',
      icon: 'itc',
      lists: [
        {
          titleContent: {
            title: '目标确认',
            titleTip: '设置目标确认人员，通常选择直属上级'
          },
          processContent: {
            maxAddNode: 6,
            addButtonTxt: '审批节点',
            lists: [
              {
                leveName: '审批节点',
                flowType: 1,
                leveValue: 0,
                name: '',
                isDelNode: false /**是否可以删除节点 */,
                size: 'large',
                color: '#3D7EFF'
              }
            ]
          }
        },
        {
          titleContent: {
            title: '目标考评',
            titleTip: '目标考评支持多个考评节点，以最后节点考评人分数为准'
          },
          processContent: {
            maxAddNode: 6,
            addButtonTxt: '考评节点',
            lists: [
              {
                leveName: '目标自评',
                flowType: 2,
                leveValue: -1,
                name: '',
                isDelNode: false /**是否可以删除节点 */,
                size: 'large',
                color: '#3D7EFF'
              },
              {
                leveName: '考评节点',
                flowType: 2,
                leveValue: 0,
                name: '',
                isDelNode: false /**是否可以删除节点 */,
                size: 'large',
                color: '#3D7EFF'
              }
            ]
          }
        }
      ]
    }
  ]
}
