<template>
  <div class="tojoy-details-mine-view">
    <section class="tab-comment">
      <div class="rightDownContent">
        <div class="tojoyDate">
          <span class="tojoyMonth">{{ details.month }}</span>
          <span class="tojoyYear">{{ details.year }}</span>
        </div>
        <div class="threeDetail">
          <div
            v-for="(item, index) in details.targetAssembleOutputs"
            :key="index"
            v-show="tabName === tabNameArray[index]"
            class="tabs-box"
          >
            <div>
              <target-details-title-a
                :dataSoure="handleTitleSource(item, index)"
              ></target-details-title-a>
            </div>
            <!-- 考评权重显示 1.1.6 -->
            <eval-weight-show-comp
              :data="{
                evalType: details.evalType,
                evalWeight: uriPathQuery.evalWeight,
                sort:uriPathQuery.sort
              }"
              schema="details"
            />
            <div class="main">
              <div class="mainLeft">
                <div v-if="item.targetIndexOutputs.length">
                  <target-details-lists
                    v-for="(list, index) in item.targetIndexOutputs"
                    :key="index"
                    :formConfig = "targetConfigList[list.type-1]"
                    :dataSoure="
                      ((data) => {
                      data.state = item.state
                        return data;
                      })(list)
                    "
                    :stateType="uriPathQuery.stateType"
                  ></target-details-lists>
                  <div class="work-month" v-if="targetAssembleOutputs[tabName] && (targetAssembleOutputs[tabName].state == 4 || targetAssembleOutputs[tabName].state == 5)">
	                  <template v-if="details.highlight">
		                  <h3 class="work-month__title">本月工作亮点</h3>
		                  <p class="work-month__desc">
			                  {{ details.highlight }}
		                  </p>
	                  </template>
	                  <template v-if="details.improve">
		                  <h3 class="work-month__title">本月待改进点</h3>
		                  <p class="work-month__desc">
			                  {{ details.improve }}
		                  </p>
	                  </template>
                  </div>
                </div>
                <div class="not-data" v-else>
                  <img :src="notDataImg" alt="" class="not-data__img" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="record-process">
          <h3>流程记录</h3>
          <div class="record-process__box">
            <tojoy-process-record
              :recordNodeData="processData"
              v-if="processData.length"
            >
              <template slot-scope="scope">
                <!-- <div>{{ scope.list }}</div> -->
                <div>
                  <p class="name">
                    {{ scope.list.name }}
                  </p>
                  <p class="content">
                    {{ scope.list.content }}
                  </p>
                </div>
              </template>
            </tojoy-process-record>
          </div>
        </div>
      </div>
    </section>
    <!--确认按钮-->
    <div
      class="tojoy-target-button"
      v-if="
        stateType == '1-1' &&
        targetAssembleOutputs[tabName] &&
        targetAssembleOutputs[tabName].state == 2
      "
    >
      <div class="target-button">
        <el-button
          class="submit-peject"
          @click="
            () => {
              affirmDisplayState = true;
              affirmState = 6;
            }
          "
          >驳回</el-button
        >
        <el-button
          type="primary"
          class="submit"
          @click="
            () => {
              affirmDisplayState = true;
              affirmState = 3;
            }
          "
          >同意</el-button
        >
      </div>
    </div>
    <!--已确认：上一位、下一位显示-->
    <div
        class="tojoy-target-button"
      v-if="
      stateType=='1-2'
      "
    >
      <div class="target-button">
        <el-button class="submit-prev"  @click="changePerson(lastPerson.detailId)" v-if="lastPerson.detailId">上一位:{{ lastPerson.name }}</el-button>
        <el-button type="primary" class="submit"  @click="changePerson(nextPerson.detailId)" v-if="nextPerson.detailId"
          >下一位:{{ nextPerson.name }}</el-button
        >
      </div>
    </div>
    <!--已考评：考评分数显示-->
    <div
        class="tojoy-target-button"
      v-if="
        stateType=='2-2' || stateType=='2-1'
      "
    >
      <div class="target-button">
        <!--评分-->
        <span class="score">{{ scoreTypeDesc }}<span class="view-score">{{ showScore }}</span>分</span>
        <!--评分按钮-->
        <el-button
          type="primary"
          class="submit"
          @click="lscoreDisplayState = true"
          v-if="stateType=='2-1'"
          >评分</el-button
        >
        <el-button
            disabled
            type="primary"
            class="submit"
            @click="lscoreDisplayState = true"
            v-if="stateType=='2-2'"
        >我已评</el-button
        >
      </div>
    </div>
    <!--确认弹框-->
    <target-index-affirm
      :display="affirmDisplayState"
      :affirmState = "affirmState-0"
      @before-close="submitAffirm"
    ></target-index-affirm>
    <target-index-lscore
      :display="lscoreDisplayState"
      @before-close="submitLscore"
      :maxNumberScore="maxNumberScore"
      :defaultScore="showScore"
    >
    </target-index-lscore>
  </div>
</template>

<script>
import { homeusedapp } from '@/config/config.js';
import TojoyProcessRecord from '@/components/business/process-record';
import TargetDetailsTitleA from '@/components/mytarget/details-title-a';
import TargetDetailsLists from '@/components/mytarget/details-list';
import TargetIndexAffirm from '@/components/mytarget/target-index-affirm';
import TargetIndexLscore from '@/components/mytarget/target-index-lscore';
import evaluationDone from './evaluation-done';
import EvalWeightShowComp from '@/components/mytarget/eval-weight-show';

import {
  ApiTargetDetailList,
  ApiTargetFlowLogList,
  ApiTargetScore,
} from '@/api/api_target';
import { ApiDimensionScore, ApiTargetConfigForm, ApiTargetLeadList, ApiTargetLeadListCore } from '../../api/api_target';
import { mapGetters } from 'vuex';
import { Message } from 'element-ui'

export default {
  name: 'thrclear-mine',
  components: {
    TojoyProcessRecord,
    TargetDetailsTitleA,
    TargetDetailsLists,
    TargetIndexAffirm,
    TargetIndexLscore,
    EvalWeightShowComp
  },
  data() {
    return {
      currentPage: 1,
      notDataImg: require('@/assets/images/300.png'),
      uriPathQuery: this.$route.query,
      showScore:'',
      scoreType: 1,
      homeusedapp: homeusedapp,
      targetAssembleOutputs: {},
      detailId: {},
      seleData: {
        evalType: 1,
      },
      affirmDisplayState: false,
      lscoreDisplayState: false,
      affirmState: 3,
      tabName: 'approve',
      tabNameArray: ['approve', 'comment'],
      // 详情数据集合
      details: {},
      // 详情标题头 -带用户信息 一级
      titleDataSourcea: {
        headImg: require('@/assets/images/300.png'),
        headUrl: '/',
        state: 5,
        speed: 90,
      },
      processData: [],
      tabs: [
        {
          name: 'approve',
          label: '考评1',
        },
        {
          name: 'comment',
          label: '考评2',
        },
      ],
      scoreTypeObj:{
        1 : '综合自评',
        2 : '综合评分',
        3 : '我的评分' // 目标得分
      },
      // 目标提交考评表单数据
      formEvaData: {
        highlight: '',
        improve: '',
      },
      beforeScore:'',
      lastPerson:{
        detailId:'',
        name:''
      },
      nextPerson:{
        detailId:'',
        name:''
      },
      maxNumberScore: 100,
	    targetConfigList:[]
    };
  },
  created() {
    this.stateType = this.uriPathQuery.stateType
    this.currentPage = this.uriPathQuery.page || 1
	  this.targetQueryConfigForm()
  },
  computed:{
    ...mapGetters(['userId']),
    scoreTypeDesc(){
      return this.scoreTypeObj[this.scoreType]
    },
  },
  watch:{},
  mounted() {
    this.getTargetDetails();
    // 查询列表当前页数据
    this.initPersonInfo()
  },
  methods: {
	  async targetQueryConfigForm() {
		  const { code, data } = await ApiTargetConfigForm({
			  type: '',
		  });
		  if (code === '000000') {
			  data.list.forEach((list)=>{
				  let eleObj = {}
				  list.map((item)=>{
					  eleObj[item.codeName] = item
				  })
				  this.targetConfigList.push(eleObj)
			  })
		  }
	  },
    // 获取配置最高分
    async getMaxScore(isOrg = 0){
      let {code,data} = await ApiDimensionScore({code:'target'})
      if(code==='000000'){
        this.maxNumberScore = isOrg ? data.orgScore : data.score
      }
    },
    // 加载上一位 下一位数据
    async initPersonInfo(detailId = this.uriPathQuery.detailId){
      let flowType = this.stateType.split('-')[0];
      let state = this.stateType.split('-')[1];
      this.lastPerson.detailId = ''
      this.lastPerson.name = ''
      this.nextPerson.detailId = ''
      this.nextPerson.name = ''
      let params={
        'year': '',
        'month': '',
        flowType,
        state,
        page: this.currentPage || 1,
        pageSize: 10
      }
      const { code, data } = await ApiTargetLeadList(params);
      if (code === '000000') {
        let affirmListByMonth = data.list
        let currentIndex = affirmListByMonth.findIndex((item => {
          return item.detailId-0 == detailId-0
        }))
        if(currentIndex-1 > -1){
          this.lastPerson.detailId = affirmListByMonth[currentIndex-1].detailId
          this.lastPerson.name = affirmListByMonth[currentIndex-1].userName
        }
        if(currentIndex+1 < affirmListByMonth.length){
          this.nextPerson.detailId = affirmListByMonth[currentIndex+1].detailId
          this.nextPerson.name = affirmListByMonth[currentIndex+1].userName
        }
      }
    },
    // 上一位、下一位切换
    changePerson(nId){
      this.initPersonInfo(nId)
      this.getTargetDetails(nId)
      let path = this.$router.history.current.path;
      //对象的拷贝
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      // 地址栏的参数值赋值
      newQuery.detailId = nId
      this.$router.replace({ path, query: newQuery });
    },
    // 目标确认 状态[state= 3 || 6]
    submitAffirm(type, val) {
      if (type == 'ok') {
        evaluationDone.methods.subTargetLeadListAffirm(
          [
            {
              detailId: this.uriPathQuery.detailId - 0,
              remark: val,
              state: this.affirmState,
            },
          ],
          () => {
            // 同意｜驳回后的回掉函数
            this.$router.go(-1)
          },
        );
        return;
      }
      this.affirmDisplayState = false;
    },
    // 目标评分
    async submitLscore(type, val) {
      if(type == 'ok'){
        if(!val && val!==0){
          Message.error('请输入评分')
          return;
        }
      }
      let form = [
        {
          detailId: this.uriPathQuery.detailId - 0,
          score: val,
        },
      ]
      if (type == 'ok') {
        const { code, msg } = await ApiTargetLeadListCore({
          targetList: form,
        });
        if (code === '000000') {
          Message.success('评分成功')
          setTimeout(()=>{
            this.$router.go(-1)
          }, 1000)
        }else{
          Message.error(msg)
        }
      }
      this.lscoreDisplayState = false;
    },

    // 考评流程查询
    async getTargetFlowLogListQuery(tId) {
      const { code, data } = await ApiTargetFlowLogList({
        detailId: tId,
        source: 2, //  source  (1、我的目标详情页面 2、领导端查看目标详情页面)
      });
      if (code === '000000') {
        this.processData = data;
        this.$forceUpdate();
      }
    },
    /**
     * 详情数据
     */
    async getTargetDetails(detailId = this.uriPathQuery.detailId) {
      const { code, data } = await ApiTargetDetailList({
        detailId: detailId - 0,
      });
      if (code === '000000') {
        this.details = data;
        if(this.details.targetAssembleOutputs.length){
          this.showScore = this.details.targetAssembleOutputs[0].showScore
          this.scoreType = this.details.targetAssembleOutputs[0].scoreType || 1
        }
        this.getMaxScore(this.details.isOrg)
        this.seleData.evalType = 1;
        this.$nextTick(() => {
          // 获取考评流程
          let id = this.detailId[this.tabName];
          this.getTargetFlowLogListQuery(id - 0);
        });
      }
    },
    handleTitleSource(item, index) {
      // 单双线-数据缓存
      this.targetAssembleOutputs[this.tabNameArray[index]] = item;
      // 单双线- 分类划分detailId
      this.detailId[this.tabNameArray[index]] = item.detailId;
      let _data = {
        ...{
          jobName: this.details.userJob,
          userName: this.details.userName,
          userId: this.details.userId,
	        avatar:this.details.avatar,
          sort: this.uriPathQuery.sort ?? 1
        },
        ...this.titleDataSourcea,
        ...item,
      };
      return _data;
    },
  },
};
</script>

<style lang="scss">
.#{$css-prefix}-details-mine-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .score {
    font-size: 15px;
    font-weight: 400;
    color: $darkgrey;
    margin-right: 20px;
    .view-score {
      color: #FE831A;
    }
  }

  .wordColor {
    width: 97px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;
    display: inline-block;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: $white;
    margin-left: 15px;
    i {
      margin-right: 7px;
    }
  }
  .dateColor {
    background: $blue;
    border: 1px solid $blue;
  }
  .weekColor {
    background: $green;
    border: 1px solid $green;
  }
  .tab-comment {
    height: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    &__main {
      flex: 1;
      margin: 20px 30px;
      background: $white;
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: $white;
      padding: 0 30px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      p {
        font-size: 12px;
        font-weight: 400;
        color: $yellow;
        line-height: 12px;
        margin-right: 20px;
      }
      .el-button {
        height: 44px;
      }
    }
  }
  .tojoy-pagination-list {
    flex: 1;
  }

  .rightDownContent {
    height: 100%;
    background: $white;
    margin: 20px 30px;
    display: flex;
    border-radius: 5px;
    overflow: auto;
    .tojoyDate {
      width: 50px;
      height: 50px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
      border-radius: 3px;
      opacity: 0.99;
      border: 1px solid #eeeeee;
      text-align: center;
      margin: 13px 20px 15px;
    }
    .tojoyYear {
      width: 29px;
      height: 10px;
      font-size: 12px;
      font-weight: 400;
      color: $grey;
      line-height: 10px;
    }
    .tojoyMonth {
      display: block;
      width: 21px;
      height: 16px;
      font-size: 20px;
      font-weight: 600;
      color: $lightblack;
      line-height: 16px;
      margin-left: 14px;
      margin-top: 7px;
    }
  }
  .record-process {
    width: 380px;
    box-shadow: -1px 0px 0px 0px $linecolor;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &__box {
      overflow: auto;
      height: 100%;
      margin-left: 20px;
      p {
        &.name {
          font-size: 14px;
          font-weight: 400;
          color: $darkgrey;
          line-height: 32px;
        }
        &.content {
          font-size: 12px;
          font-weight: 400;
          color: $grey;
          line-height: 22px;
        }
      }
    }
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: $lightblack;
      line-height: 78px;
      padding-left: 20px;
    }
  }
  .threeDetail {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .tabs-box {
      display: flex;
      flex-direction: column;
    }
    .work-month {
      width: 98%;
      margin: 0 15px;
      color: $darkgrey;
      overflow: auto;
      &__title {
        font-size: 15px;
        font-weight: bold;
        color: $lightblack;
        padding: 15px 0;
      }
      &__desc {
        font-size: 14px;
        font-weight: 400;
        box-shadow: 0px 1px 0px 0px $linecolor;
        padding: 0 0 20px 0;
      }
	    &__desc:last-of-type{
		    box-shadow: 0px 0px 0px 0px $linecolor;
	    }
    }
  }
  .num {
    width: auto;
    padding: 0px 5px;
    color: $lightblack;
    border: 1px solid $d9d9d9;
    margin-right: 15px;
    background: transparent;
    i {
      color: $grey;
      margin: 0;
    }
    span {
      color: $blue;
    }
  }

  .tl {
    text-align: left;
  }
  .tr {
    text-align: right;
  }

  .main {
    height: 100%;
    background: #fff;
    overflow: auto;
    overflow-x: hidden;
  }
  .mainLeft {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    background: #fff;
    padding-left: 15px;
    border-right: 1px solid #eee;
    box-sizing: border-box;
  }

  .personnelItem {
    flex: 1;
  }
  .personnelItem img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    vertical-align: middle;
    margin: 15px 10px 15px 15px;
  }
  .personnelItem .personnelInfo {
    display: inline-block;
    width: 60%;
    vertical-align: middle;
    margin-left: 1vh;
  }
  .personnelItem .personnelInfo .name {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  .personnelItem .personnelInfo .post {
    font-size: 13px;
    color: #999;
  }

  .not-data {
    width: 100%;
    margin-bottom: 30px;
    &__img {
      display: block;
      width: 284px;
      height: 248px;
      margin: 60px auto 40px auto;
    }
    &__txt {
      font-size: 14px;
      font-weight: 400;
      color: $darkgrey;
      text-align: center;
      p {
        &:last-child {
          margin-top: 15px;
        }
      }
      span {
        cursor: pointer;
        color: $blue;
      }
    }
  }
  .not-button {
    position: absolute;
    bottom: 70px;
    left: 50%;
    margin-left: -46px;
    cursor: pointer;
    width: 92px;
    height: 50px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 5px 10px 0px rgba(63, 127, 254, 0.2);
    border-radius: 25px;
    border: 1px solid rgba(158, 190, 255, 0.5);
    color: $blue;
    text-align: center;
    i {
      vertical-align: -4px;
      font-weight: 600;
      font-size: 16px;
    }
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
</style>
