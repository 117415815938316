<template>
  <div class="tojoy-democracy-mine-view three-mine-view">
    <SubmitRemind @before-close="setTargetUrge" :display="remindStatus"></SubmitRemind>
    <tojoy-title-content title="三清">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
          <div class="colorContain">
            <div v-if="allNo && allNo > 0" @click="remindStatus = true" class="unsubmitted">
              <span class="unsubcolor">{{ unDoNo }}人未交</span>
              <span>/{{ allNo }}</span>
              <i class="el-icon-arrow-down"></i>
            </div>

            <el-button v-for="(item, index) in homeusedapp.filter(h => h.scen.includes('thrClear'))" :key="index" :type="item.btnType" :icon="item.icon" @click="handleJumtThrclear(item)">{{
              item.name
            }}</el-button>
          </div>
        </div>
      </template>
    </tojoy-title-content>
    <section class="tab-comment">
      <div class="rightDownContent">
        <div class="threeDetail">
          <!-- 如果是批量审阅页面 -->
          <div class="search-wrap" v-if="tabName == 'complete3' || tabName == 'personBatch'">
            <span class="search-label"> 类型：</span>
            <el-checkbox-group v-model="reportType" @change="datecheckchange($event, 2)">
              <el-checkbox v-for="(item, index) in reportListType" :key="index" :label="item.index">{{ item.val }}</el-checkbox>
            </el-checkbox-group>
            <span class="search-label date">日期范围：</span>
            <el-date-picker
              @change="datepickchange($event, 2)"
              v-model="reportDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              class="search-date-picker"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              range-separator="~"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker
            ><el-button type="primary" size="small" @click="searchthreeclear(2)">筛选</el-button>
          </div>

          <div class="search-wrap" v-else>
            <span class="search-label"> 类型：</span>
            <el-checkbox-group v-model="reportType" @change="datecheckchange($event, 1)">
              <el-checkbox v-for="(item, index) in reportListType" :key="index" :label="item.index">{{ item.val }}</el-checkbox>
            </el-checkbox-group>
            <span class="search-label date">日期范围：</span>
            <el-date-picker
              @change="datepickchange($event, 1)"
              v-model="reportDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              class="search-date-picker"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              range-separator="~"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker
            ><el-button type="primary" size="small" @click="searchthreeclear(1)">筛选</el-button>
          </div>
          <batch-pageList
            v-if="tabName == 'complete3' || tabName == 'personBatch'"
            :loading="loading"
            :total="total"
            :data="completeData"
            :tabName="tabName"
            :enableAllOk="enableAllOk"
            :pitchList="pitchList"
            :allchecked="allchecked"
            :noComment="noComment"
            :yesComment="yesComment"
            :isIndet="isIndet"
            :codeOfconducdList="codeOfconducdList"
            @change-allCheck="changeAllCheck"
            @get-batchList="getBatchList"
          >
            <template #item="{ data }">
              <el-checkbox class="check-box" v-model="data.isCheck" @change="changeCheck"></el-checkbox>
              <three-item
                :key="data.id"
                :data="data"
                :tabName="tabName"
                :form-config="formConfig"
                @follow-click="handleFollowClick"
                @click-praise="handleClickPraise"
                @argue-discuss="handleArgueDiscuss"
                @check-approve="clickCheckApprove"
                @approve="handleApproveComment"
                @reply-comment="clickReply"
                @submit-discuss="handleSubmitDiscuss"
                @shift-comment="handleShiftComment"
                :codeOfconducdList="codeOfconducdList"
                @openDialog="batchlookButton"
              />
            </template>
          </batch-pageList>
          <tojoy-pagination-list v-else ref="tojoy-pagination-list-ref" @page-change="pageChange" :loading="loading" :total="total" :currentPage="page" :data="completeData">
            <template #item="{ data }">
              <three-item
                :key="data.id"
                :data="data"
                :tabName="tabName"
                :form-config="formConfig"
                @follow-click="handleFollowClick"
                @click-praise="handleClickPraise"
                @argue-discuss="handleArgueDiscuss"
                @check-approve="clickCheckApprove"
                @approve="handleApproveComment"
                @reply-comment="clickReply"
                @submit-discuss="handleSubmitDiscuss"
                @shift-comment="handleShiftComment"
              />
            </template>
          </tojoy-pagination-list>
        </div>
        <div class="threeDate">
          <div class="calendar-title">
            <span>三清统计</span>
            <span class="el-dropdown-link">
              <el-date-picker
                :clearable="false"
                size="mini"
                @change="datechange"
                value-format="yyyy-MM"
                format="yyyy-MM"
                v-model="statisticsDate"
                type="month"
                prefix-icon="el-icon-arrow-down"
                placeholder="选择月"
              >
              </el-date-picker>
            </span>
          </div>
          <div class="calendar-conts">
            <tojoy-calendar :dateList="dateList" :statisticsDate="statisticsDate" />
          </div>
        </div>
      </div>
    </section>
    <el-dialog title="审阅" :visible.sync="dialogVisible" center width="640px" :close-on-click-modal="false" @close="handleClose" class="dialog-box">
      <el-form :model="ruleForm" :rules="rules" label-position="top" ref="ruleForm" class="demo-ruleForm">
        <el-form-item v-if="actionMainId" label="行为规范月度自评" prop="pointsId">
          <el-select v-model="ruleForm.pointsId" placeholder="请选择" class="select-box">
            <el-option v-for="item in codeOfconducdList" :key="item.id" :label="item.label" :value="item.id">
              <el-popover placement="right" trigger="hover" :title="item.label"
                ><span slot="reference">{{ item.label }}</span>
              </el-popover>
            </el-option>
          </el-select>
          <el-button class="look-button" type="primary" size="small" @click="lookButton">查看</el-button>
        </el-form-item>
        <!-- :rules="[{ required: this.yesComment || this.noComment ? true : false }]" -->
        <el-form-item label="审阅批注" prop="comment">
          <el-input
            class="area-input"
            resize="none"
            v-filteremoji
            type="textarea"
            :autosize="{ minRows: 9, maxRows: 9 }"
            placeholder="请输入批注内容"
            maxlength="800"
            show-word-limit
            v-model="ruleForm.comment"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button style="color:#FF526A;border: 1px solid #DDDDDD;" plain @click="handleApproveComment(4)">审阅不通过</el-button>
        <el-button style="background-color: #3D7EFF;color: #fff;border: 1px solid #3D7EFF;" plain @click="handleApproveComment(3)">审阅通过</el-button>
      </span>
    </el-dialog>
    <SelfEvaDialog :lookDialog="lookDialog" :dataList="selfEvaDetailList" :selfEvaObj="selfEvaObj" @update="updateDialog"></SelfEvaDialog>
  </div>
</template>

<script>
import { MessageBox, Message } from 'element-ui'
import { mapState } from 'vuex'
import SubmitRemind from '@/components/thrclear/submit-remind.vue'
import SelfEvaDialog from '@/components/thrclear/selfEva-dialog'
import { homeusedapp } from '@/config/config.js'
import { ruleQuery, selfEvaDetail } from '@/api/thrclear.js'
import {
  list_reportall,
  comment,
  comReview,
  reviewDel,
  listCommentBatch,
  configQuery,
  praise,
  cancel,
  attentionsave,
  reportNextlevelundo,
  reportCalendar,
  reportRemind,
  deleteComment,
  fetchReportCheck,
  reportRedTips,
  delReportRedTips
} from '@/api/thrclear.js'
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyPaginationList from '@/components/business/pagination-list'
import BatchPageList from '@/components/business/batch-pageList'
import TojoyCalendar from '@/components/common/calendar'
import TojoyTabs from '@/components/business/tabs'
import ThreeItem from '@/components/thrclear/three-item'
export default {
  name: 'thrclear-mine',
  components: {
    TojoyTitleContent,
    TojoyPaginationList,
    TojoyCalendar,
    SubmitRemind,
    TojoyTabs,
    ThreeItem,
    BatchPageList,
    SelfEvaDialog
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        pointsId: 0,
        comment: ''
      },
      rules: {
        pointsId: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      total: 0,
      formConfig: {},
      dateList: [],
      remindStatus: false,
      statisticsDate: '',
      defaultImg: '',
      completeData: [],
      unDoNo: '',
      allNo: '',
      tabs: [
        {
          name: 'received',
          label: '全部',
          index: '0',
          badge: false
        },
        {
          name: 'complete2',
          label: '我关注的',
          index: '4',
          badge: false
        },
        {
          name: 'comment',
          label: '汇报给我',
          index: '1',
          badge: false
        },
        {
          name: 'complete',
          label: '抄送给我',
          index: '2',
          badge: false
        },
        {
          name: 'complete1',
          label: '我发出的',
          index: '3',
          badge: false
        },
        {
          name: 'complete3',
          label: '批量审阅',
          index: '5',
          badge: false
        }
      ],
      reportListType: [
        {
          val: '日清',
          index: 1
        },
        {
          val: '周清',
          index: 2
        },
        {
          val: '月清',
          index: 3
        }
      ],
      tabName: 'received',
      batchTab: JSON.parse(sessionStorage.getItem('await-Obj')), //批量审阅tab标识
      // 报告类型 1 日报 2 周报 3 月报
      reportType: [],
      // 当前页
      page: 1,
      // 日期选择器
      reportDate: [],
      // 每页条数
      size: 10,
      textarea1: '',
      homeusedapp: homeusedapp,
      // 三清入口校验信息
      checkInfo: {},
      loading: false,
      reId: 0,
      scrollDom: {},
      allchecked: false, //全部选中
      yesComment: false, //通过批注必填
      noComment: false, //不通过批注必填
      pitchList: [],
      enableAllOk: null, //批量审阅全部同意开关
      isIndet: false,
      codeOfconducdList: [], //行为规范自评扣分列表
      lookDialog: false, //查看弹框
      selfEvaDetailList: [],
      selfEvaObj: {
        dateTime: '', //时间
        lookUserInfo: {} //人信息
      }, //查看行为规范月度自评人信息
      actionMainId: null
    }
  },
  computed: {
    ...mapState({
      userName: state => state.userInfo.userName,
      userId: state => state.userInfo.userId
    })
  },
  async created() {
    this.loading = true
    // 当日三清直属下级未交人员统计[宋红飞]
    this.reportNextlevelundo()
    // 获取当前年月
    this.getnowDate()
    // 处理日期格式
    this.datechange()
    // 当日三清是否需要填写与是否已提交
    this.fetchReportCheck()
    // // 三清红点查询
    this.reportRedTips()
    this.reportCalendar(this.statisticsDate.replace(/-/gi, ''))
    //审阅配置
    this.getconfig()
    // 如果待办进来个人批量审阅页
    if (this.batchTab) {
      this.tabs.push({
        name: 'personBatch',
        label: `${this.batchTab.userName}批量审阅`,
        index: '6',
        badge: false
      })
      this.tabName = 'personBatch'
      await this.getBatchList()
    } else {
      this.tabName = 'received'
      // 查询三清列表
      await this.list_reportall(this.datamethods())
    }
    // console.log(this.batchTab, '是不是从待审阅进来的')
    sessionStorage.removeItem('await-Obj')
    this.ruleQueryList()
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.$refs.ruleForm.resetFields()
    },
    updateDialog(val) {
      this.lookDialog = val
    },
    getconfig() {
      configQuery({}).then(res => {
        if (res.code == '000000') {
          //如果后台没开启批量审阅,截取批量审阅
          if (!res.data.enableBatch) {
            this.tabs.forEach((item, i) => {
              if (item.index === '5') {
                this.tabs.splice(i, 1)
              }
            })
          }
          this.enableAllOk = res.data.enableAllOk
          this.yesComment = res.data.yesComment
          // console.log('🚀 ~ file: mine.vue:389 ~ configQuery ~ yesComment:', this.yesComment)
          this.noComment = res.data.noComment
          // console.log('🚀 ~ file: mine.vue:391 ~ configQuery ~ noComment:', this.noComment)
        }
      })
    },
    //点击选中
    changeCheck() {
      this.pitchList = []
      this.completeData.forEach(item => {
        if (item.isCheck) {
          this.pitchList.push(item)
        }
      })
      if (this.pitchList.length == this.completeData.length || !this.pitchList.length) {
        this.isIndet = false
      } else if (0 < this.pitchList.length < this.completeData.length) {
        this.isIndet = true
      }
      this.allchecked = this.pitchList.length == this.completeData.length
    },
    //批量审阅点击全选
    changeAllCheck(val) {
      this.completeData.forEach(item => {
        item.isCheck = val
        //如果= true
        if (val) {
          this.pitchList = this.completeData
        } else {
          this.pitchList = []
        }
      })
      if (this.pitchList.length == this.completeData.length || !this.pitchList.length) {
        this.isIndet = false
      } else if (0 < this.pitchList.length < this.completeData.length) {
        this.isIndet = true
      }
      this.allchecked = this.pitchList.length == this.completeData.length
    },

    // 三清红点清除
    delReportRedTips(data) {
      delReportRedTips(data).then(res => {
        if (res.code !== '000000') {
          Message.error(res.msg)
        }
      })
    },
    // 分页
    async pageChange(val) {
      this.loading = true
      this.page = val
      await this.list_reportall(this.datamethods())
    },
    /**
     * 当日三清是否需要填写与是否已提交
     */
    async fetchReportCheck() {
      const { code, data } = await fetchReportCheck()
      if (code === '000000') {
        this.checkInfo = data
      }
    },
    /**
     * 跳转三清
     */
    handleJumtThrclear(item) {
      // console.log('🚀 ~ file: mine.vue:401 ~ handleJumtThrclear ~ item:', item)
      let val = ''
      for (let i = 0; i < this.checkInfo.outputList.length; i++) {
        if (Number(item.reportType) === Number(this.checkInfo.outputList[i].reportType)) {
          if (this.checkInfo.outputList[i].isRepeat === 1) {
            if (item.reportType === '1') {
              val = '日清'
            } else if (item.reportType === '2') {
              val = '周清'
            } else {
              val = '月清'
            }
            this.$warning(`${val}已经提交！`)
            return false
            // }
            // else if(this.checkInfo.outputList[i].isAble === 0) {
            //   this.$warning('今日无需提交三清！')
            //   return false;
          } else {
            this.$router.push({
              path: item.path,
              query: { reportType: item.reportType }
            })
          }
        }
      }
    },
    // 三清红点查询
    reportRedTips(index) {
      reportRedTips().then(res => {
        if (res && res.code === '000000') {
          if (res.data) {
            let list = res.data.list
            for (let j = 0; j < this.tabs.length; j++) {
              for (let i = 0; i < list.length; i++) {
                if (list[i].isTips === 1 && Number(list[i].reportObjectType) === Number(this.tabs[j].index)) {
                  this.$set(this.tabs[j], 'badge', true)
                }
              }
            }
          } else {
            for (let i = 0; i < this.tabs.length; i++) {
              this.$set(this.tabs, i, { ...this.tabs[i], badge: false })
            }
          }
          if (index) {
            this.$set(this.tabs[index], 'badge', false)
          }
        }
      })
    },

    // 点击tab切换页面
    handleTabClick({ name, index }) {
      //如果待审阅进去 点击其他标签个人批量审阅消失
      if (index != '6') {
        this.batchTab = null
        this.tabs.forEach((item, i) => {
          if (item.index === '6') {
            this.tabs.splice(i, 1)
          }
        })
      }

      let itemTabs = this.tabs.find(item => item.name === name)
      if (itemTabs.badge) {
        this.delReportRedTips({ reportObjectType: itemTabs.index })
      }
      this.page = 1
      this.reportRedTips(index)
      this.reportType = []
      this.reportDate = []
      this.pitchList = []
      // 如果不是批量审阅页面
      if (name != 'complete3' && name != 'personBatch') {
        this.list_reportall(this.datamethods())
        if (this.$refs['tojoy-pagination-list-ref']) {
          this.$refs['tojoy-pagination-list-ref'].resetScrollTop()
        }
      } else {
        if (name == 'complete3') {
          this.tabName = 'complete3'
        } else {
          this.tabName = 'personBatch'
        }
        this.getBatchList()
        if (this.$refs['batch-pagination-list-ref']) {
          this.$refs['batch-pagination-list-ref'].scrollTop = 0
        }
      }
    },
    //批量审阅页数据
    getBatchList(reportTypes, reportDate) {
      // console.log(reportTypes, '类型参数', reportDate, '时间参数')
      this.allchecked = false
      this.isIndet = false
      this.pitchList = []
      let params = {
        //如果是待审阅进来的，传审阅id
        ofUserId: this.batchTab ? this.batchTab.userId : null,
        reportTypes: reportTypes ? reportTypes : [1, 2, 3],
        startDate: reportDate ? reportDate[0] : null,
        endDate: reportDate ? reportDate[1] : null
      }
      listCommentBatch(params).then(res => {
        if (res.code == '000000') {
          this.formConfig = res.data.formConfigMap
          this.completeData = res.data.list.map(item => {
            item.isCheck = false
            // 默认展示审核通过 0
            if (item.deductionApprove) {
              item.deductionApprove.pointsId = 0
            }
            return item
          })
        }
        this.loading = false
      })
    },

    // 查询三清列表的接口
    list_reportall(data, loading = true) {
      list_reportall(data, { loading: loading }).then(result => {
        if (result && result.code === '000000') {
          this.total = result.data.total
          this.completeData = []
          this.formConfig = result.data.formConfigMap
          if (result.data.list) {
            let reg = /\n/g
            this.completeData = result.data.list.map(item => {
              item.isArgue = false
              item.comment = ''
              if (item.commentOutputList) {
                item.commentOutputList.map(its => {
                  its.content = its.content.replace(reg, '<br/>')
                })
              }
              return item
            })
          } else {
            this.total = 0
          }
        }
        this.loading = false
      })
    },
    // 日期选择发生变化的时候触发
    datepickchange(val, type) {
      // console.log(this.datamethods(), '666', val)
      this.page = 1
      this.reportDate = val
      if (type == 1) {
        this.list_reportall(this.datamethods())
      } else {
        let typearr = []
        this.getBatchList(typearr, this.reportDate)
      }
    },
    // 类型选择发生变化的时候触发
    datecheckchange(val, type) {
      this.page = 1
      if (type == 1) {
        this.reportType = val
        this.list_reportall(this.datamethods())
      } else {
        let date = []
        this.getBatchList(val, date)
      }
    },
    /**
     * 删除评论
     *
     */
    async handleShiftComment({ id, content, reportId, userName }) {
      MessageBox.confirm(`确定删除这条评论吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteComment({ content, reportId, userName, id })
          .then(res => {
            if (res.code === '000000') {
              // TODO 删除评论-处理成局部刷新
              this.completeData.map(c => {
                if (Number(c.id) === Number(reportId)) {
                  c.commentOutputList = c.commentOutputList.filter(cc => cc.id !== id)
                }
                return c
              })
              this.$message({
                type: 'success',
                message: res.msg
              })
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
      })
    },
    // 一键提醒
    setTargetUrge(type, val) {
      if (type == 'ok') {
        let obj = {}
        let arr = []
        for (let i = 0; i < val.length; i++) {
          arr.push(val[i].userId)
        }
        obj.userIdList = arr
        obj.reportType = 1
        obj.userName = this.userName
        reportRemind(obj).then(res => {
          if (res.code === '000000') {
            this.remindStatus = false
            Message.success('已提醒' + this.unDoNo + '人！')
          }
        })
      } else {
        this.remindStatus = false
      }
    },
    // 处理日期格式
    datechange() {
      this.reportCalendar(this.statisticsDate.replace(/-/gi, ''))
    },
    // 获取当前年月
    getnowDate() {
      const myDate = new Date()
      let month = Number(myDate.getMonth()) + 1

      if (month < 10) {
        month = '0' + month.toString()
      }
      this.statisticsDate = myDate.getFullYear() + '-' + month
    },

    // 点击评论
    handleArgueDiscuss(item) {
      this.reId = 0
      item.comment = '' //重置评论内容
      this.completeData = this.completeData.map(ite => {
        ite.isArgue = item.id === ite.id ? !ite.isArgue : ite.isArgue
        return ite
      })
    },
    // 点击审阅
    clickCheckApprove(item) {
      // console.log('item', item)
      this.selfEvaObj.dateTime = item.dueDate
      this.selfEvaObj.lookUserInfo = item.userInfoOutput
      this.actionMainId = item?.deductionApprove?.actionMainId
      this.reportId = item.id //三清ID
      // this.ruleForm = item
      item.comment = ''
      // 审阅
      switch (item.approveStatus) {
        case 1:
          // this.completeData = this.completeData.map(ite => {
          //   ite.isArgue = item.id === ite.id ? !ite.isArgue : ite.isArgue
          //   return ite
          // })
          this.dialogVisible = true
          break
        //审阅超时
        case 101:
          // this.completeData = this.completeData.map(ite => {
          //   ite.isArgue = item.id === ite.id ? !ite.isArgue : ite.isArgue
          //   return ite
          // })
          this.dialogVisible = true
          break
        // 撤销审阅
        case 5:
          MessageBox.confirm(`是否确认撤销审阅结果？`, '提示', {
            confirmButtonText: '撤销审阅结果',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              let params = {
                reportId: item.id,
                actionMainId: this.actionMainId //月度自评id
              }
              // console.log('params', params)
              reviewDel(params).then(res => {
                if (res && res.code === '000000') {
                  this.list_reportall(this.datamethods(), false)
                  this.$msgSuccess('审阅已撤销')
                }
              })
            })
            .catch(() => {
              Message.info(`已取消操作`)
            })
          break
      }
    },
    ruleQueryList() {
      ruleQuery({}).then(res => {
        // console.log('rrrs', res)
        if (res.code === '000000') {
          res.data.list.unshift({ id: 0, score: '不扣分', title: '审核通过' })
          this.codeOfconducdList = res.data.list.map(item => {
            return {
              ...item,
              label: item.title == '审核通过' ? item.title + '(不扣分)' : item.title + `(-${item.score})`
            }
          })
          // console.log('this.codeOfconducdList', this.codeOfconducdList)
        }
      })
    },
    // 查看行为规范月度自评
    lookButton() {
      this.lookDialog = true
      this.getselfEvaDetail(this.actionMainId)
    },
    // 批量的查看
    batchlookButton(val) {
      // console.log('vv', val)
      this.lookDialog = true
      this.selfEvaObj.dateTime = val.dueDate
      this.selfEvaObj.lookUserInfo = val.userInfoOutput
      this.getselfEvaDetail(val.deductionApprove.actionMainId)
    },
    // 获取月度自评详情
    getselfEvaDetail(id) {
      selfEvaDetail({
        id
      }).then(res => {
        // console.log('sss', res)
        if (res.code === '000000') {
          this.selfEvaDetailList = res.data.list
        }
      })
    },

    //审阅通过/不通过
    handleApproveComment(type) {
      console.log('参数', this.ruleForm, type)
      this.$refs.ruleForm.validate(val => {
        if (val) {
          console.log('hhaha', this.ruleForm)
          //不通过
          if (type === 4) {
            if (this.noComment && !this.ruleForm.comment) {
              this.$warning('审阅不通过批注必填')
            } else {
              this.approveComment(type)
            }
          } else {
            if (this.yesComment && !this.ruleForm.comment) {
              this.$warning('审阅通过批注必填')
            } else {
              this.approveComment(type)
            }
          }
          // this.approveComment(type)
        }
      })
    },

    // 通过不通过接口
    approveComment(type) {
      console.log('form', this.ruleForm)
      let params = {
        commentType: 1,
        approveStatus: type,
        // content: val.comment ? val.comment : null,
        content: this.ruleForm.comment,
        // reportId: val.id,
        reportId: this.reportId,
        userName: this.userName,
        approveActionInput: {
          actionMainId: this.actionMainId, //月度自评id
          approveStatus: this.ruleForm.pointsId === 0 ? 2 : 3, //审阅状态:0待审阅，2通过，3不通过
          pointsId: this.ruleForm.pointsId, //扣分项ID
          pointsName: this.ruleForm.pointsId === 0 ? '' : this.codeOfconducdList.find(item => item.id == this.ruleForm.pointsId).title,
          score: this.ruleForm.pointsId === 0 ? '' : this.codeOfconducdList.find(item => item.id == this.ruleForm.pointsId).score
        }
      }
      console.log('审阅传参', params)
      comReview(params).then(res => {
        if (res && res.code === '000000') {
          this.dialogVisible = false
          this.$msgSuccess('审阅完成')
          this.list_reportall(this.datamethods())
          this.$refs.ruleForm.resetFields()
          // this.completeData = this.completeData.map(ite => {
          //   ite.isArgue = val.id === ite.id ? !ite.isArgue : ite.isArgue
          //   return ite
          // })
        }
      })
    },
    // 点击发布
    handleSubmitDiscuss(val) {
      if (val.comment) {
        let obj = {
          content: val.comment,
          reportId: val.id,
          userName: this.userName,
          parentId: this.reId
        }
        comment(obj).then(res => {
          if (res && res.code === '000000') {
            this.reId = 0
            this.list_reportall(this.datamethods(), false)
            this.completeData = this.completeData.map(ite => {
              ite.isArgue = val.id === ite.id ? !ite.isArgue : ite.isArgue
              return ite
            })
          }
        })
      }
    },
    //点击回复
    clickReply(val, id) {
      this.reId = id
      this.completeData = this.completeData.map(ite => {
        ite.isArgue = val.id == ite.id ? !ite.isArgue : ite.isArgue
        return ite
      })
    },

    /**
     * 点赞 | 取消点赞
     */
    async handleClickPraise({ id, isPraise }, operation) {
      const { code, data } = await praise({ reportId: id, userName: this.userName })
      if (code === '000000') {
        // TODO 点赞处理成局域更新
        this.completeData.map(c => {
          if (c.id === id) {
            c.isPraise = operation
          }
          return c
        })
        this.list_reportall(this.datamethods(), false)
      }
    },
    /**
     * 点击关注|取消关注
     */
    async handleFollowClick({ isAttention, userId, id }) {
      if (isAttention == 1) {
        // 已关注，点击取消关注
        const { code, data, msg } = await cancel({ attentionId: userId })
        if (code === '000000') {
          // TODO 取消关注 处理成局部刷新
          // this.list_reportall(this.datamethods())
          this.completeData.forEach(c => {
            if (c.userId === userId) {
              c.isAttention = 0
            }
          })
        }
      } else {
        // 未关注，点击关注
        const { code, data, msg } = await attentionsave({ attentionId: userId })
        if (code === '000000') {
          // TODO 取消关注 处理成局部刷新
          // this.list_reportall(this.datamethods())
          this.completeData.forEach(c => {
            if (c.userId === userId) {
              c.isAttention = 1
            }
          })
        }
      }
    },
    // 点击筛选
    searchthreeclear(type) {
      if (type == 1) {
        this.list_reportall(this.datamethods())
      } else {
        this.getBatchList(this.reportType, this.reportDate)
      }
    },
    // 处理获取请求参数
    datamethods() {
      let reportObjectType = 0
      for (let i = 0; i < this.tabs.length; i++) {
        if (this.tabName == this.tabs[i].name) {
          reportObjectType = this.tabs[i].index
        }
      }
      let data = {
        // 当前页
        page: this.page,
        // 	三清日期开始时间字符串
        reportDateStartStr: this.reportDate ? this.reportDate[0] : '',
        // 三清日期结束时间字符串
        reportDateEndStr: this.reportDate ? this.reportDate[1] : '',
        // tab分类类型 全部 0；1 汇报给我的；2 抄送给我的；3我发出的；4我关注的
        reportObjectType: Number(reportObjectType),
        // 报告类型 1 日报 2 周报 3 月报
        reportType: this.reportType,
        // 每页条数
        size: this.size
      }
      return data
    },
    // 当日三清直属下级未交人员统计[宋红飞]
    reportNextlevelundo() {
      reportNextlevelundo({}, { loading: false }).then(res => {
        if (res && res.code === '000000') {
          if (!res.data) {
            return false
          }
          this.allNo = res.data.allNo
          this.unDoNo = res.data.unDoNo
        }
      })
    },
    // APP-日历三清异常提示[宋红飞]
    reportCalendar(val) {
      let reportStatus = {
        1: '正常',
        2: '超时',
        3: '未交',
        4: '补交'
      }
      let reportType = {
        1: '日清',
        2: '周清',
        3: '月清'
      }
      reportCalendar({ monthDate: val }, { loading: false }).then(res => {
        if (res && res.code === '000000') {
          this.dateList = res.data.list.map(item => {
            item.meetDate = item.reportDate
            item.tips = `${reportType[item.reportType] + reportStatus[item.reportStatus]}`
            return item
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__list {
      .el-select-dropdown__item {
        display: block;
        width: 600px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.three-mine-view {
  /deep/ .dialog-box {
    .el-dialog__body {
      padding-top: 0;
    }
  }
  /deep/ .demo-ruleForm {
    .el-form-item .el-form-item__label {
      padding: 0;
    }
  }
  /deep/.select-box {
    display: block;
    position: relative;
    .el-input {
      .el-input__inner {
        width: 450px;
        border: none;
        border-bottom: solid 1px #eee;
        padding: 0;
        color: #222222;
        font-size: 15px;
      }
      .el-input__suffix {
        // display: none;
        right: 100px;
        top: 0px;
      }
    }
  }
  /deep/ .area-input {
    .el-textarea__inner {
      border: none;
      border-bottom: solid 1px #eee;
      color: #222222;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
  .look-button {
    position: absolute;
    top: -2px;
    right: 0;
  }
  // .container {
  //   display: flex;
  //   .month {
  //     width: 46px;
  //     height: 46px;
  //     background: #ffffff;
  //     box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
  //     border-radius: 3px;
  //     border: 1px solid #eeeeee;
  //     margin-right: 15px;
  //     text-align: center;
  //     p {
  //       width: 46px;
  //       font-size: 20px;
  //       font-family: PingFangSC-Semibold, PingFang SC;
  //       font-weight: 600;
  //       color: #333333;
  //     }
  //     .year {
  //       font-size: 10px;
  //       font-weight: 400;

  //       color: #999999;
  //     }
  //   }
  //   .userInfo {
  //     display: flex;
  //     padding-bottom: 15px;
  //     border-bottom: solid 1px #eee;

  //     .imgs {
  //       width: 46px;
  //       height: 46px;
  //       margin-right: 10px;
  //       img {
  //         display: block;
  //         border-radius: 50%;
  //         width: 100%;
  //         height: 100%;
  //       }
  //     }
  //     .info {
  //       color: #999999;
  //       font-size: 13px;
  //       p {
  //         font-size: 16px;
  //         color: #333333;
  //         margin-bottom: 7px;
  //       }
  //     }
  //   }

  //   .box-content {
  //     padding: 15px 0;
  //     border-bottom: solid 1px #eee;
  //     .btn {
  //       margin-top: 15px;
  //       font-size: 14px;
  //       font-weight: 500;
  //     }
  //     .not {
  //       color: #ff526a;
  //     }
  //     .basic {
  //       color: #3d7eff;
  //     }
  //     .complete {
  //       color: #06ae56;
  //     }
  //   }
  // }
  .tojoy-pagination-list {
    flex: 1;
    margin-right: 1px;
    &__wrap {
      margin: 0;
      padding: 0;
    }
    &__notdata {
      margin: 0;
    }
    &__loading {
      margin: 0;
    }
  }
}
.#{$css-prefix}-democracy-mine-view {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .tab-comment {
    height: 100%;
    overflow: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    &__main {
      flex: 1;
      margin: 20px 30px 0;
      margin-bottom: 20px;
      background: $white;
      overflow: auto;
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: $white;
      height: 80px;
      padding: 0 30px;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      p {
        font-size: 12px;
        font-weight: 400;
        color: $yellow;
        line-height: 12px;
        margin-right: 20px;
      }
      .el-button {
        height: 44px;
      }
    }
  }
  .time {
    height: 12px;
    font-size: 12px;

    font-weight: 400;
    color: $grey;
    line-height: 12px;
  }
  .colorContain {
    position: absolute;
    right: 30px;
    display: flex;
    // margin-bottom: 16px;
    .unsubmitted {
      background: $white;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
      font-size: 14px;
      font-weight: 400;
      padding: 0 16px;
      margin-right: 30px;
      height: 32px;
      line-height: 32px;
      display: flex;
      align-items: center;
      .unsubcolor {
        color: $blue;
      }
      .el-icon-arrow-down {
        padding-left: 12px;
      }
    }
    .iconblue {
      font-size: 14px;
      padding-right: 7px;
    }
    .el-button {
      padding: 8px 20px;
      border-radius: 2px;
    }
  }
  .dateColor {
    background: $blue;
    border: 1px solid $blue;
  }
  .weekColor {
    background: $green;
    border: 1px solid $green;
  }
  .monColor {
    background: $yellow;
    border: 1px solid $yellow;
  }
  .rightDownContent {
    height: 100%;
    background: $white;
    margin: 20px 30px 0;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
  }
  .threeDate {
    width: 380px;
    box-shadow: -1px 0px 0px 0px $linecolor;
    flex: none;
    flex-direction: column;
    display: flex;
    .el-icon-arrow-down {
      color: #666666;
    }
    .el-dropdown-link {
      cursor: pointer;
      .el-input__prefix {
        left: 80px;
        text-align: right;
      }
      cursor: pointer;
    }
    .el-date-editor {
      width: 110px;
    }
    .el-input__inner {
      cursor: pointer;
      width: 110px !important;
      border: none;
    }
    .calendar {
      &-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;
        margin-top: 20px;
      }
      &-conts {
        height: 100%;
        overflow: auto;
        display: flex;
      }
    }
  }
  .threeDetail {
    flex-grow: 1;
    overflow: auto;
    flex-direction: column;
    display: flex;
    .search-wrap {
      display: flex;
      align-items: center;
      height: 64px;
      border-bottom: 1px solid $linecolor;
      padding-left: 15px;
      flex: none;
      .search-label {
        font-size: 14px;
        font-weight: 400;
        color: $darkgrey;
        line-height: 14px;
        width: 44px;
        &.date {
          width: 74px;
        }
      }
      .el-checkbox {
        margin-right: 15px;
      }
      .el-button {
        margin-left: 15px;
      }
      .search-date-picker {
        .el-range-separator,
        .el-range__close-icon {
          line-height: 26px;
        }
      }
    }
  }
  .check-box {
    margin-right: 15px;
  }
  .el-range-editor.el-input__inner {
    width: 290px;
    height: 32px;
  }
}
</style>
