<template>
  <div class="tojoy-process-record">
    <tojoy-technological v-if="recordNodeData.length"
      :leveNodeData="recordNodeData"
      :maxAddNode="recordNodeData.length"
    >
      <template #exec="{ data }">
        <slot :list="data"></slot>
      </template>
    </tojoy-technological>
  </div>
</template>
<script >
import TojoyTechnological from '@/components/common/technological/target-process';

export default {
  name: 'tojoy-process-record',
  components: {
    TojoyTechnological,
  },
  props: {
    recordNodeData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss">
.#{$css-prefix}-process-record {
  .tojoy-technological .el-timeline-item__node--large {
    left: 8px;
    top: 5px;
  }

  .tojoy-technological .el-timeline-item__tail {
    left: 15px;
  }
  .el-timeline-item__node--large {
    width: 16px;
    height: 16px;
  }
  .tojoy-technological .el-timeline-item__node--large {
    box-shadow: 0px 0px 0px 5px #ffffff;
    border: 0;
  }
  .tojoy-technological .el-timeline-item__wrapper {
    top: 3px;
    padding-left: 40px;
    box-sizing: border-box;
  }
  .el-timeline-item__content {
    span {
      font-size: 15px;
      font-weight: 500;
      color: #333333;
    }
  }
}
</style>
