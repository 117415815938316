<template>
  <div>
    <el-dialog
        class="tojoy-dialog_index tojoy-dialog__lib"
        :visible.sync="display"
        width="800px"
        :before-close="
        () => {
          handleBeforeClose('cancel');
        }
      "
    >
      <template #title>
        <div class="tojoy-dialog__header--title tojoy-dialog__lib--title">
          <span
              class="back"
              @click="
              () => {
                handleBeforeClose('cancel');
              }
            "
          >返回</span
          >
          指标库({{ totalPager }})
        </div>
      </template>
      <div class="tojoy-dialog__lib--body" v-if="!loading && (isSearch || tableData.length)">
        <!--指标库搜索-->
        <el-form
            :inline="true"
            :model="formInline"
            class="search-form"
            style="display: flex"
            @submit.native.prevent
        >
          <el-form-item label="">
            <el-input v-model="formInline.name"
                      @keyup.native.enter="searchLib"
                      placeholder="请输入指标名称">
              <template #prefix>
                <i class="el-icon-search"></i>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button
                type="primary"
                @click="searchLib"
                class="search-btn"
            >搜索</el-button
            >
          </el-form-item>
          <el-form-item style="flex-grow: 1; text-align: right">
            <el-button
                @click="onManageAllButton"
                :icon="allButtonState ? 'el-icon-delete' : 'icon iconfont iconmanage'"
                style="width: 79px; height: 32px;"
            >{{ allButtonState ? '清空' : '管理' }}</el-button
            >
            <el-button
                v-if="allButtonState"
                @click="onManageAllButton($event,'cancel')"
                style="width: 79px; height: 32px"
            >取消</el-button
            >
          </el-form-item>
        </el-form>
        <!--指标库数据-->
        <el-table
            class="lib-table"
            height="360"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :highlight-current-row="false"
        >
          <el-table-column width="30" v-if="!allButtonState">
            <template slot-scope="scope">
              <el-radio
                  class="radio"
                  v-model="scope.row.radio"
                  :label="scope.row.isSelected"
                  @change="handleRadio(scope.row.id, scope.row)"
              >&nbsp;
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column width="280" label="名称" show-overflow-tooltip>
            <template #default="scope">{{ scope.row.name }}</template>
          </el-table-column>
          <el-table-column label="类别" width="120">
            <template #default="scope">
              <el-tag
                  :type="`${targetType[scope.row.type].tag}`"
                  disable-transitions
              >{{ targetType[scope.row.type].name }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column width="200" label="所属年月">
            <template #default="scope">
              <span> {{ scope.row.createTimeStr }}创建</span>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot-scope="scope" v-if="allButtonState">
              <span class="line">|</span>
              <el-button
                  @click="del([scope.row.id],true)"
                  type="text"
                  icon="el-icon-delete"
                  style="width: 79px; height: 32px"
              >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            class="pagin"
            background
            :page-size="this.formInline.size"
            :current-page="this.formInline.page"
            @current-change="handleChangePage"
            layout="prev, pager, next"
            :total="totalPager">
        </el-pagination>
      </div>
      <no-data v-if="!isSearch && !loading &&!tableData.length" :blankData="blankData"></no-data>
      <!--提示-->
      <div v-if="!isSearch && !loading && !tableData.length">
        <p class="tojoySmallTips">小贴士：</p>
        <p class="tojoyUpTips">
          创建指标时，可以选择是否保存到指标库，以备后续快速调用
        </p>
      </div>
      <!--指标库底部-->
      <template #footer v-if="tableData.length">
        <span class="dialog-footer">
          <el-button
              type="primary"
              @click="
              () => {
                handleBeforeClose('ok');
              }
            "
          >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script >
  import { MessageBox, Message } from 'element-ui';
  import {
    ApiTargetQueryLib /**指标库查询*/,
    ApiTargetDeleteLib /**指标库删除 */,
  } from '@/api/api_target';
  import NoData from '@/components/business/blank/blank.vue'

  export default {
    name: 'target-index-lib',
    components: {
      NoData
    },
    props: {
      display: {
        type: Boolean,
        default: () => {
          return false;
        },
      },
    },
    data() {
      return {
        loading: true,
        allButtonState: false,
	      isSearch:false, // 是否是搜索
	      totalPager: 0,
        tableData: [],
        formInline: {
          name: '',
          page: 1,
          size: 10,
        },
        itemData: null,
        targetType: {
          1: {
            name: '定量指标',
            tag: 'success',
          },
          2: {
            name: '定性指标',
            tag: 'warning',
          },
          3: {
            name: '例行指标',
            tag: '',
          },
        },
        blankData:{
          type: 'notdata'
        },
      };
    },
	  mounted(){
		  this.init();
	  },
    methods: {
      // 点击分页
      handleChangePage(val) {
        this.formInline.page = val
        this.getTargetQueryLib();
      },
      init() {
      	this.formInline.page = 1
	    this.formInline.name = ''
        this.itemData = null;
        this.allButtonState = false;
        this.loading = true
	    this.isSearch = false
	    this.totalPager = 0
        this.getTargetQueryLib();
      },
	    searchLib(){
          this.formInline.page = 1
          this.isSearch = true
		    this.getTargetQueryLib();
	    },
      async getTargetQueryLib() {
        this.allButtonState = false;
        const { code, data } = await ApiTargetQueryLib(this.formInline);
        if (code === '000000') {
          this.loading = false
          if (data && data.list.length) {
            this.tableData = data.list.map((item) => {
              item.radio = '1';
              return item;
            });
            this.totalPager = data.total;
          }else{
            this.tableData = []
            this.totalPager = 0
          }
        }
      },
      del(ids,isSigle) {
        MessageBox.confirm(isSigle ?'确认删除？':' 确认清空指标库吗？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          ApiTargetDeleteLib({
            indexLibIds: ids,
          }).then((data) => {
            if (data.code === '000000') {
              Message.success(isSigle ? '删除成功':'指标库清空成功')
              this.getTargetQueryLib();
            }
          });
        });
      },
      handleRadio(id, data) {
        this.tableData = this.tableData.map((item) => {
          if (item.id != id) item.radio = '0';
          return item;
        });
        this.itemData = data;
      },

      onManageAllButton(e, flag) {
        if(flag=='cancel' && this.allButtonState){
          this.allButtonState = false
          return;
        }
        if (this.allButtonState) {
          this.del([]);
        } else if (this.tableData.length) {
          this.allButtonState = !this.allButtonState;
        }
      },

      onSubmit() {
      },
      handleBeforeClose(val) {
        if (!this.itemData && val == 'ok') {
          return Message({
            message: '请选择一个指标',
            type: 'error',
          });
        }
        this.$emit('before-close', val, this.itemData);
      },
    },
    watch: {
      display(val) {
        this.loading = true
	      this.isSearch = false
        val && this.init();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .#{$css-prefix}-dialog {
    &__lib {
      /deep/ .errorPage{
        height: 370px;
      }
      /deep/ .el-dialog__body {
        padding-top: 9px;
        height: 512px;
      }
      /deep/ .el-dialog__header {
        background: #ffffff;
        border-bottom: 1px solid #f2f2f2;
        padding: 25px 30px 19px;
      }
      &--title {
        font-size: 18px;
        font-weight: bold;
        color: $lightblack;
        .back {
          cursor: pointer;
          font-size: 14px;
          font-weight: 400;
          color: $darkgrey;
          margin-right: 50px;
        }
      }
      &--body {
        /deep/ .el-input__inner {
          width: 290px;
          height: 32px;
          line-height: 32px;
        }
        /deep/ .el-tag {
          height: 21px;
          line-height: 21px;
        }
        .pagin {
          text-align: center;
          margin-top: 30px;
        }
        .line {
          color: $grey600;
        }
        .search-form{
          .search-btn{
            width: 62px;
            font-size: 14px;
            line-height: 14px;
            height: 32px;
          }
          .el-input__prefix{
            left:12px;
          }
          .el-form-item{
            margin-bottom: 10px;
          }
        }
        .el-table th{
          padding: 5px 0!important;
          font-size: 14px!important;
          line-height: 14px;
        }
        .el-table td{
          padding: 13px 0!important;
          font-size: 16px!important;
        }
      }
      /deep/ .el-button--primary {
        width: 120px;
        height: 32px;
        border-radius: 3px;
        line-height: 32px;
      }
      /deep/ .el-button {
        padding: 0;
        .iconmanage{
          font-size: 14px!important;
          margin-right: 5px;
        }
      }
      /deep/ .el-dialog__footer {
        z-index: 10;
        height: 64px;
        text-align: center;
        box-sizing: border-box;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        padding: 16px 0;
        position: relative;
      }
    }
  }
  .tojoySmallTips {
    font-size: 14px;
    font-weight: 400;
    color: $darkgrey;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  .tojoyUpTips {
    font-size: 12px;
    font-weight: 400;
    color: $grey;
    margin-bottom: 10px;
  }

</style>
