<script lang="jsx">
import TojoyRate from '@/components/common/rate'
import SmallUserCard from './small-user-card'
import { DEMOCRACY_RATE_DATA, SATISFACTION_RATE_DATA } from './state.js'

export default {
  name: 'democracy-complete-item',
  components: {
    TojoyRate,
    SmallUserCard,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      isShow: false,
    }
  },
  methods: {
    filterBeforeEnter (el) {
      el.style.height = 0
    },
    filterEnter (el) {
      el.style.height = el.scrollHeight !== 0 ? el.scrollHeight + 'px' : 0
    },
    filterLeave (el) {
      el.style.height = 0
    },
  },
  render(h) {
    const { data, rateList, isShow } = this
    return (
      <div class="democracy-complete-item">
        <small-user-card data={{
          avatar: data.avatar,
          userid: data.userid,
          username: data.username,
          position: data.position,
          organization: data.organization}}
          on-user-card={u => this.$emit('click-user-card', u)}/>
        <div class="democracy-complete-item__wrap">
          <el-button type="text" onClick={() => this.isShow=!this.isShow}>
            {
              isShow ? '收起评价' : '展开评价'
            }
            <i class={isShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'}></i>
          </el-button>
          <transition
            onBeforeEnter={(el) => this.filterBeforeEnter(el)}
            onEnter={(el) => this.filterEnter(el)}
            onLeave={(el) => this.filterLeave(el)}>
            {
              isShow ? (
                <div class="hidden-block">
                  {
                    data.rateList.map((r, index) => {
                      return (
                        <tojoy-rate v-model={r.rate} disabled={true} title={r.title} />
                      )
                    })
                  }
                  {
                    data.remark ? (
                      <div class="democracy-complete-item__text">
                        <h4 class="label">建议</h4>
                        <p class="content">{data.remark ?? '暂无'}</p>
                      </div>
                    ) : ''
                  }
                </div>
              ) : ''
            }
          </transition>
        </div>
      </div>
    )
  }
}
</script>

<style lang="scss">
.democracy-complete-item {
  margin-left: 20px;
  padding-top: 16px;
  &__wrap {
    padding-bottom: 14px;
    border-bottom: 1px solid $linecolor;
    margin-left: 48px;
    overflow: hidden;
    .hidden-block {
      max-height: 500px;
      transition: all 0.2s ease-in-out;
    }
    .el-button {
      font-size: 13px;
      font-weight: 400;
      color: $blue;
      line-height: 13px;
      padding-bottom: 0;
      i {
        color: $grey550;
        margin-left: 5px;
      }
    }
    .tojoy-rate {
      margin-bottom: 11px;
      &:first-of-type {
        margin-top: 12px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__text {
    margin-top: 20px;
    .label {
      font-size: 14px;
      font-weight: bold;
      color: $lightblack;
      line-height: 14px;
    }
    .content {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: $darkgrey;
      line-height: 18px;
    }
  }
  &:first-of-type {
    padding-top: 0px;
  }
  // &:last-of-type {
  //   .democracy-complete-item__wrap {
  //     border-bottom: none;
  //   }
  // }
}
</style>
