<template>
  <div class="tojoy-dialog" v-if="display">
    <el-dialog
        :title="title"
        :visible.sync="display"
        :close-on-click-modal="false"
        width="560px"
        :before-close="
        () => {
          handleBeforeClose('cancel');
        }
      "
    >
      <div class="tojoy-dialog__header">
        <div class="tabWrap" v-if="tabBar.length > 1">
          <div
              v-for="(item, index) in tabBar"
              :key="index"
              :class="`tabItem ${defaultForm.evalType == item ? 'active' : ''}`"
              :style="
              tabBar.length > 1 &&
              tabBar.length - 1 == index &&
              'margin-left: 3%'
            "
              @click="changeIndex(item)"
          >
            <p class="title">{{ tabsTitleBlock[item].title }}</p>
            <p class="fu">{{ tabsTitleBlock[item].disc }}</p>
          </div>
        </div>
      </div>
      <section :class="['tojoy-dialog__body',tabBar.length < 2 ? 'dialog_body_height':'']">
        <div
            v-if="defaultForm.evalType == 1"
            style="width: 90%; margin-left: 5%"
        >
          <h3 class="h3">考评流程</h3>

          <div v-if="rProcessConfig">
            <div
                v-for="(item, index) in rProcessConfig[defaultForm.evalType][0]
                .lists"
                :key="index"
            >
              <tojoy-technological-title
                  class="mb20"
                  :titleContent="item.titleContent"
                  @technological-change="technologicalChange"
              />
              <tojoy-technological
                  :leveNodeData="item.processContent.lists"
                  :maxAddNode="item.processContent.maxAddNode"
                  :addButtonTxt="item.processContent.addButtonTxt"
                  @node-callback="nodeCallback"
              >
                <template #exec="{ data }">
                  <div class="sele-box" :class="[data.leveName=='目标自评' ? 'self-box-by': '']" v-if="data.name">
                    {{ data.name
                    }}<i
                      v-if="data.leveName!=='目标自评'"
                      class="el-icon-error"
                      @click="deletePerson(data)"
                  />
                  </div>
                  <choose-people
                      v-else
                      :multipleLimit="1"
                      v-model="data.name"
                      :remote="true"
                      :isItemObj="true"
                      @input="dealPerson($event,data)"
                      :placeholder="data.leveName=='审批节点'?'请选择审批人':'请选择考评人'"
                      :propsAlias="{ value: 'userId', label: 'userName' }"
                      :disabled="false"
                      :multiple="false"
                      :disableDataList ="disableDataList"
                  >
                  </choose-people>
                </template>
              </tojoy-technological>
            </div>
          </div>
        </div>
        <div
            v-if="defaultForm.evalType == 2"
            style="width: 90%; margin-left: 5%"
        >
          <h3 class="h3">考评流程</h3>

          <tojoy-technological-number
              :numberNodeData="rProcessConfig[defaultForm.evalType]"
          >
            <template #select="{ data }">
              <div class="select-flex">
                <p>评分权重</p>
                <el-select
                    v-model="data.evalWeight"
                    class="sele-item"
                    @change="
                    (val) => {
                      handleChangeWeight(
                        val,
                        data.sort,
                        rProcessConfig[defaultForm.evalType],
                      );
                    }
                  "
                >
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </template>
            <template #exec="{ data }">
              <div v-for="(item, index) in data.lists" :key="index">
                <tojoy-technological-title
                    class="mb20"
                    :titleContent="item.titleContent"
                    @technological-change="technologicalChange"
                />
                <tojoy-technological
                    :leveNodeData="item.processContent.lists"
                    :maxAddNode="item.processContent.maxAddNode"
                    :addButtonTxt="item.processContent.addButtonTxt"
                    @node-callback="nodeCallback"
                >
                  <template #exec="{ data }">
                    <div class="sele-box" v-if="data.name">
                      {{ data.name
                      }}<i
                        v-if="data.leveName!=='目标自评'"
                        class="el-icon-error"
                        @click="
                          () => {
                            data.name = '';
                          }
                        "
                    />
                    </div>

                    <choose-people
                        v-else
                        :multipleLimit="1"
                        v-model="data.name"
                        :remote="true"
                        :isItemObj="true"
                        @input="dealPerson($event,data)"
                        :placeholder="data.leveName=='审批节点'?'请选择审批人':'请选择考评人'"
                        :propsAlias="{ value: 'userId', label: 'userName' }"
                        :disabled="false"
                        :multiple="false"
                        :disableDataList ="disableDataList"

                    >
                    </choose-people>
                  </template>
                </tojoy-technological>
              </div>
            </template>
          </tojoy-technological-number>
        </div>
      </section>
      <template #footer>
        <span class="dialog-footer">
          <el-button
              v-prevent-click
              type="primary"
              @click="
              () => {
                handleBeforeClose('ok');
              }
            "
          >{{ tabBar.length > 1 ? '确认' : '保存' }}</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script >
  import { __PROCESS_DATA__ } from '@/views/mytarget/target.cfg.js';
  import TojoyTechnologicalTitle from '@/components/common/technological-title';
  import TojoyTechnological from '@/components/common/technological';
  import ChoosePeople from '@/components/common/tojoy-select/person-selected.vue';
  import TojoyTechnologicalNumber from '@/components/common/technological-number';
  import {
    ApiTargetFlowList,
    ApiTargetQueryLeader,
    ApiTargetQueryByName,
  } from '@/api/api_target';
  import { mapGetters, mapState } from 'vuex';
  import Message from 'element-ui/packages/message/src/main';
  export default {
    name: 'tojoy-eval-selected',
    components: {
      TojoyTechnologicalTitle,
      TojoyTechnological,
      ChoosePeople,
      TojoyTechnologicalNumber,
    },
    props: {
      title: {
        type: String,
        default: () => {
          return '标题';
        },
      },

      form: {
        type: Object,
        default: () => {
          return {};
        },
      },
      tabBar: {
        type: Array,
        default: () => {
          return [1, 2];
        },
      },
      display: {
        type: Boolean,
        default: () => {
          return false;
        },
      },
    },
    data() {
      return {
        uriQuery: this.$route.query,
        options: Object.keys(Array.apply(null, { length: 21 })).map((item) => {
          let num = 0;
          return {
            value: num+item * 5,
            label: num+item * 5 + '%',
          };
        }).reverse(),
        tabsTitleBlock: {
          1: {
            title: '单线考核',
            disc: '普通岗位请选择此流程',
          },
          2: {
            title: '双线考核',
            disc: '需要双线汇报的岗位',
          },
        },
        report: [],
        // 是否清除选择内容的记录（页面生命周期内）
        isEraseMemory: true,
        /**
         *
         * 基础表单配置
         *  mode: 'A' 模块模式 A => 添加 E =>修改
         * evalType: 1考评方式 1、单线考评 2、双线考评
         * isOrg: 1 是否为组织目标 1、组织目标 2、 个人目标
         * month: 0, 月份
         * targetId: 0 目标id
         * year: 0 年份
         */
        defaultForm: {
          mode: 'A',
          evalType: 1,
          isOrg: 1,
          month: 0,
          year: 0,
        },
        // 流程配置
        rProcessConfig: null,
        leaderObj:{
          userId: 0,
          userName:''
        },
        index: 1,
        disableDataList:[], // 不可选择人组件
      };
    },
    computed: {
      ...mapGetters(['userId','userName']),
    },
    mounted() {
      this.initProcessData()
    },
    methods: {
      deletePerson(data){
        this.$set(data, 'name', '')
        this.$set(data, 'leveValue', null)
      },
      dealPerson(params, data){
        data.leveValue = params.userId;
        data.name = params.userName;
        // if(params.userId === this.userId){
        //   Message({
        //     message: `请添加审批人`,
        //     type: 'error',
        //     customClass:'zZindex'
        //   })
        //   this.$set(data, 'name', '')
        //   this.$set(data, 'leveValue', null)
        // }
      },
      //初始化流程选择数据
      async initProcessData() {
        this.rProcessConfig = JSON.parse(JSON.stringify(__PROCESS_DATA__));
        this.defaultForm = { ...this.defaultForm, ...this.form };
        // 获取直接领导人
        await this.getTargetQueryLeader();
        // 流程回显数据初始
        this.getTargetFlowList(this.uriQuery.targetId - 0);
        // this.disableDataList = [{userId: this.userId}]
      },

      // 考评流程记录查询
      async getTargetFlowList(tId) {
        const { code, data } = await ApiTargetFlowList({
          targetId: tId,
        });
        if (code === '000000') {
          if (data&&data.list.length) {
            if(data.list[0].targetFlowDetailOutputList?.length == 0  ){
              this.rProcessConfig['1'].forEach((item)=>{
                this.dealEvaluationItem(item,this.leaderObj.userId,this.leaderObj.userName)
              })
              this.rProcessConfig['2'].forEach((item)=>{
                this.dealEvaluationItem(item,this.leaderObj.userId,this.leaderObj.userName)
              })
              return
            }
            this.moreConfigData(data.list);
            // 流程初始另一种考评方式初始化
            let len = data.list.length
            this.defaultForm.evalType = len    // 1 单线 2 双线
            this.rProcessConfig[this.defaultForm.evalType===1?2:1].forEach((item)=>{
              this.dealEvaluationItem(item,this.leaderObj.userId,this.leaderObj.userName)
            })
          } else {
            this.rProcessConfig['1'].forEach((item)=>{
              this.dealEvaluationItem(item,this.leaderObj.userId,this.leaderObj.userName)
            })
            this.rProcessConfig['2'].forEach((item)=>{
              this.dealEvaluationItem(item,this.leaderObj.userId,this.leaderObj.userName)
            })
          }
        }
      },

      async getTargetQueryLeader() {
        const { code, data } = await ApiTargetQueryLeader({
          type: 3,
        });
        if (code === '000000') {
          this.leaderObj.userId = data?.userId || null
          this.leaderObj.userName = data?.userName || ''
        }
      },
      dealEvaluationItem(obj,userId,userName){
        obj.lists[0].processContent.lists[0].name = userName
        obj.lists[0].processContent.lists[0].leveValue = userId
        obj.lists[1].processContent.lists[0].name = this.userName
        obj.lists[1].processContent.lists[0].leveValue = -1
        obj.lists[1].processContent.lists[1].name = userName
        obj.lists[1].processContent.lists[1].leveValue = userId
      },

      async searchTargetUserByName(name) {
        if (!name) return;
        const { code, data } = await ApiTargetQueryByName({
          isAccess: 0,
          name: name,
        });
        if (code === '000000') {
          this.report = data.userList;
        }
      },

      // 权重选择数据联动效果
      handleChangeWeight(val, index, data) {
        data.filter((item) => {
          return item.sort != index;
        })[0].evalWeight = 100 - val;
      },

      // 弹窗按钮事件处理
      handleBeforeClose(val) {
        this.isCanSave = true
        if(val == 'ok'){
          this.defaultFormAssemblyData(
              this.rProcessConfig[this.defaultForm.evalType],
          );
        }
        if(this.isCanSave){
          this.$emit('before-close', val, this.defaultForm);
        }
      },

      // 历史列表数据回填
      moreConfigData(data) {
        let leng = data.length;
        let _this = this;
        data.forEach((element, index) => {
          // 考评循环
          _this.rProcessConfig[leng][index].evalWeight = element.evalWeight;
          // 目标和考评
          let nodeListData1 = element.targetFlowDetailOutputList.filter((item) => {
            return item.flowType === 1 // 目标确认数据
          })
          let nodeListData2 = element.targetFlowDetailOutputList.filter((item) => {
            return item.flowType === 2 // 目标考评数据
          })
          let processContent1 =  _this.rProcessConfig[leng][index].lists[0].processContent
          let processContent2 =  _this.rProcessConfig[leng][index].lists[1].processContent
          let levelNode1 =  processContent1.lists[0]    // 审批节点模版
          let levelNode2 =  processContent2.lists[1]    // 自评节点模版
          // 审批节点回显
          nodeListData1.map((item, index) => {
            let levelNode = { ...{}, ...levelNode1 }
            if(index>0){
              levelNode.isDelNode = true;
              levelNode.leveName = processContent1.addButtonTxt;
              processContent1.lists.splice(processContent1.lists.length-1, 0, levelNode);
            }
            this.$set(processContent1.lists[index], 'name', item.checkName)
            processContent1.lists[index].leveValue = item.checkId
            processContent1.lists[index].flowType = item.flowType
            processContent1.lists[index].level = item.level
          })
          // 自评节点
          nodeListData2.forEach((item, index) => {
            if(nodeListData2.length>2 && index >1){
              let levelNode = { ...{}, ...levelNode2 }
              levelNode.name = "";
              levelNode.leveName = processContent2.addButtonTxt;
              levelNode.isDelNode = true;
              processContent2.lists.splice(processContent2.lists.length - 1, 0, levelNode);
            }
            this.$set(processContent2.lists[index], 'name', item.checkName)
            processContent2.lists[index].leveValue = item.checkId
            processContent2.lists[index].flowType = item.flowType
            processContent2.lists[index].level = item.level
          })
        });
      },

      // 请求数据封装
      defaultFormAssemblyData(data) {
        let levelKey = {
          1: 0,
          2: 0,
        };
        this.defaultForm.targetDetailSaveInputs = [];
        try {
          data.forEach((element) => {
            this.defaultForm.targetDetailSaveInputs.push({
              evalWeight: element.evalWeight,
              sort: element.sort,
              targetFlowBasicInputs: (() => {
                let arr = [];
                element.lists.forEach((item, index) => {
                  item.processContent.lists.forEach((itemData) => {
                    if (itemData.leveValue != undefined){ // leveValue： 0 null undefined 正常值
                      if( itemData.name) {
                        // if (itemData.leveValue && itemData.leveValue == this.userId) {
                        //   Message({
                        //     message: `请添加审批人`,
                        //     type: 'error',
                        //     customClass:'zZindex'
                        //   })
                        //   this.isCanSave = false
                        //   throw new Error('跳出循环');
                        // }
                        arr.push({
                          name: itemData.name,
                          leveName: itemData.leveName,
                          isDelNode: itemData.isDelNode,
                          checkId: itemData.leveValue,
                          flowType: itemData.flowType,
                          level: ++levelKey[itemData.flowType],
                        });
                      }else{
                        Message({
                          message: `请添加审批人`,
                          type: 'error',
                          customClass:'zZindex'
                        })
                        this.isCanSave = false
                        throw new Error('跳出循环');
                      }
                    }else if(itemData.leveValue === null){
                      Message({
                        message: `请添加审批人`,
                        type: 'error',
                        customClass:'zZindex'
                      })
                      this.isCanSave = false
                      throw new Error('跳出循环');
                    }else{
                      if(itemData.name!=undefined && !itemData.name){
                        Message({
                          message: `请添加审批人`,
                          type: 'error',
                          customClass:'zZindex'
                        })
                        this.isCanSave = false
                        throw new Error('跳出循环');
                      }
                    }
                  });
                });
                return arr;
              })(),
            });
          });
        }catch (e) {
          console.log(e)
        }
      },
      changeIndex(index) {
        this.defaultForm.evalType = index;
      },
      nodeCallback(type, data) {
      },
      technologicalChange(val) {
      },
    },
    watch: {
      display(val) {
        if (val) this.initProcessData();
      },
    },
  };
</script>
<style lang="scss">
  .#{$css-prefix}-dialog {
    &__header {
      display: flex;
      z-index: 10;
      position: relative;
      .tabWrap {
        width: 100%;
        height: 110px;
        padding: 0 5%;
        box-sizing: border-box;
        display: flex;
        margin-top: 15px;
        background: #ffffff;
        box-shadow: 0 7px 10px #6b6b6b0a;
        align-items: center;
        justify-content: center;
        margin-top: -5px;
        .tabItem {
          flex: 1;
          height: 70px;
          cursor: pointer;
          border: 1px solid #eee;
          border-radius: 3px;
          padding: 11px 20px;
          box-sizing: border-box;
          position: relative;
        }

        .spanCheck {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        .active {
          height: 70px;
          border: 1px solid #3d7eff;
          position: relative;
          transition: all 0.5s ease;
        }
        .active:after {
	        content: '';
	        background: url('../../../assets/images/target/process01.png') no-repeat top left /30px 30px;/*兼容没测*/
	        position: absolute;
	        bottom: -1px;
	        right: -1px;
	        z-index: 2;
	        width: 30px;
	        height: 30px;
	        -moz-border-radius-bottomright: 3px;
	        overflow: hidden;
        }
        .tabItem .title {
          font-size: 16px;
          color: #333;
          font-weight: bold;
        }

        .tabItem .fu {
          font-size: 12px;
          color: $grey;
          line-height: 28px;
        }
      }
    }

    &__body {
      height: 400px;
      overflow-y: auto;
      .sele-item {
        margin-top: -5px;
      }
      .el-select .el-input .el-select__caret {
        color: $blue;
      }
      .select-flex {
        color: $darkgrey;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        line-height: 30px;
        .el-input__inner {
          border: 0px solid transparent;
          width: 80px;
          font-size: 14px;
          font-weight: 400;
          color: $blue;
        }
      }
      .sele-box {
        border-radius: 17px;
        border: 1px solid $grey600;
        font-size: 12px;
        font-weight: 400;
        color: $lightblack;
        line-height: 24px;
        padding: 0 8px 0 12px;
        display: inline-block;
        margin-top: 15px;
        i {
          cursor: pointer;
          width: 13px;
          height: 13px;
          color: $grey600;
          margin-left: 5px;
        }
      }
      .sele-box:hover {
        color: #3d7eff;
        border-color: #3d7eff;
        cursor: pointer;
      }
      .sele-box.self-box-by:hover{
        color: $lightblack;
        border: 1px solid $grey600;
        cursor: default;
      }
      .el-icon-info {
        font-size: 14px;
        color: $blue;
      }
      .h3 {
        font-size: 18px;
        font-weight: bold;
        color: $lightblack;
        margin: 30px 0;
      }
      .mb20 {
        margin-bottom: 20px;
      }
    }
    .dialog_body_height{
      height: 510px!important;
    }
    .el-dialog__body {
      padding: 0;
      .self-box-by{
        padding: 0 12px;
      }
    }
    .el-dialog__header {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 18px;
    }
    .el-dialog__footer {
      z-index: 10;
      height: 64px;
      text-align: center;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 16px 0;
      position: relative;
    }
    .el-button--primary {
      color: #fff;
      width: 100px;
      height: 32px;
      background: #3d7eff;
      border-radius: 3px;
      line-height: 32px;
    }
    .el-button {
      padding: 0;
    }
    p.del{
      @include font-hover-color();
    }
  }
</style>
