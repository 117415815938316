<script lang="jsx">
import {cloneDeep} from 'lodash'
import TojoyRate from '@/components/common/rate'
import SmallUserCard from './small-user-card.vue'

export default {
  name: 'democracy-comment-item',
  components: {
    TojoyRate,
    SmallUserCard,
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    started:{
      type:Boolean
    }
  },
  data(){
    return {
      rateList: [...cloneDeep(this.data.rateList)],
      onceFlag: false,
    }
  },
  methods: {
    handleChangeRate(rate, {id: prop}) {
      this.data.rateList.map(item => {
        item.rate = item.id === prop ? rate : item.rate
        return item
      })
      this.$emit('change-rate', {id: this.data.id, rate, rateId: prop})
      // 校验该组是否全部打分,如完成,加完成标识
      const _hadrate = this.rateList.findIndex(item => item.rate === 0)
      if(_hadrate === -1 && !this.data?.hadrate) {
        this.$emit("complete-rate")
        this.data['hadrate'] = true
      }
      const _index = this.rateList.findIndex(item => item.id === prop)
      this.rateList.map(item => {
        if(item.id === prop) {
          item.rate = rate
        }
        return item
      })
      this.$set(this.rateList, _index, {...this.rateList[_index], rate})

    },
    jumpTo(userdata) {
      // TODO:跳转到该用户个人主页
    }
  },
  render(h) {
    const {data, rateList, jumpTo, handleChangeRate,started} = this
    return (
      <div class="democracy-comment-item">
        <small-user-card data={data} triggerData={{username: true}} on-user-card={u => this.$emit('click-user-card', u)}/>
        {
          rateList.map((r, index) => {
            return (
              <tojoy-rate
                v-model={r.rate}
                title={r.title}
                disabled={!started}
                onChanges={(val) => {r.rate = val; handleChangeRate(val, r)}}
              />
            )
          })
        }
        <div class="democracy-comment-item__text">
          {
            data.isViewSuggest ? (
              <div>
                <label class="label">匿名建议：</label>
                <el-input
                  v-filteremoji
                  autosize
                  type="textarea"
                  resize="none"
                  placeholder="填写建议内容(选填)"
                  v-model={data.suggest}
                  disabled={!started}
                  maxlength="300"
                  show-word-limit
                />
              </div>
            ): ''
          }
        </div>
      </div>
    )
  },
}
</script>
<style lang="scss">
.democracy-comment-item {
  width: 100%;
  padding: 15px 15px 5px;
  .small-user-card {
    margin-bottom: 14px;
    .el-icon-arrow-right {
      color: $grey550;
    }
  }
  .tojoy-rate {
    margin-bottom: 18px;
    margin-left: 48px;
    .icon-class {
      width: 19px;
      height: 18px;
      font-size: 18px;
      &.hover {
        transform: none;
        -webkit-transform: none;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 48px;
    border-bottom: 1px solid $linecolor;
    // &:last-child {
    //   border-bottom: none;
    // }
    .label {
      font-size: 14px;
      font-weight: bold;
      color: $lightblack;
      line-height: 14px;
      margin-bottom: 15px;
    }
    .el-textarea__inner {
      border: none;
      padding-left: 0;
      padding-right: 35px;
    }
  }
}
</style>
