<script lang="jsx">
import { reportStatus } from '@/config/config'
export default {
  name: 'democracy-organization-tip',

  props: {
    type: { // 0 - 组织 1 - 个人
      type: Number,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    createDate: {
      type: String,
      default: ''
    },
    reportType: { //日周旬月类型
      reportType: Number,
      required: true
    },
    star:{
      type:Boolean,
      default: true
    }
  },
  computed: {
    nameVal({type, name}) {
      return type === 0 ? `${name}` : name
    },
    reportTypeStr({type}){
      return type === 0 ? '组织互评' : reportStatus()[this.reportType]
    },
    starVal({star}){
      return star ? '' : '（未开始）'
    }
  },

  render(h) {
    const { type, nameVal, name,reportType,createDate,reportTypeStr,starVal } = this
    // console.log(nameVal,reportType,type,'传参',name,createDate);
    return name ? (
      <div class={[`democracy-organization-tip tip_${type}`]}>
        <span class="day">{createDate}</span>
        <span style="color:#f23">{starVal}</span>
        <span style="color:#ccc">|</span>
        <span style="margin-left: 4px;">{nameVal}</span>
        <span class={[`evalType tp_${type}`]}>{reportTypeStr}</span>
      </div>
    ) : ''
  },
}
</script>
<style lang="scss">
.democracy-organization-tip {
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  // padding: 7px 10px;
  margin-bottom: 15px;
  .day {
    color: #333;
    font-size: 12px;
    margin: 0 6px 0 10px;
  }
  &.tip_0 {
    color: $green;
    background: $e6f6ee;
  }
  &.tip_1 {
    color: $blue;
    background: $ebf2ff;
  }
  .evalType {
    color: #e6f6ee;
    float: right;
    width: 65px;
    position: relative;
    text-align: center;

    &.tp_0 {
      background-color: #75d1a1;
      &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 10px solid #75d1a1;
        position: absolute;
        top: 1px;
        left: -10px;
      }
    }
    &.tp_1 {
      background-color: #94b7ff;
      &::before {
        content: '';
        width: 0px;
        height: 0px;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 10px solid #94b7ff;
        position: absolute;
        top: 1px;
        left: -10px;
      }
    }
  }
}
</style>
