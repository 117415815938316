<template>
  <div class="choose-people">
    <div v-if="!show">
      <el-select
        v-model="currentValue"
        :multiple="multiple"
        :filterable="filterable"
        :disabled="disabled"
        :loading="loading"
        :clearable="clearable"
        :multipleLimit="multipleLimit"
        :remote="remote"
        :reserve-keyword="reserveKeyword"
        :remote-method="remoteMethod"
        :placeholder="placeholder"
        :value-key="propsAlias.value"
        @change="changePeople"
      >
        <el-option
          v-for="item in options"
          :key="item[propsAlias.value]"
          :label="item[propsAlias.label]"
          :value="multiple || isItemObj ? item : item[propsAlias.value]"
          :disabled="item[propsAlias.value] | hasDisable(disableDataList, propsAlias.value)"
        >
        </el-option>
      </el-select>
    </div>
    <div class="tojoy-tag" v-if="show && multiple">
      <el-tag
        v-for="item in currentValue"
        :key="item[propsAlias.value]"
        type="info"
        effect="plain"
      >
        {{ item[propsAlias.label] }}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: 'choose-people',
  props: {
    type: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    isItemObj: {
      type: Boolean,
      default: false
    },
    value: {
      require: true,
    },
    dataList: {
      type: Array,
      default: () => {
        return [];
      },
      require: true,
    },
    // 数据项属性别名
    propsAlias: {
      type: Object,
      default: () => {
        return {
          value: 'value',
          label: 'label',
        };
      },
    },
    remote: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    multipleLimit: {
      type: Number,
      default: 0,
    },
    multiple: {
      // 单多选
      type: Boolean,
      default: true,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default() {
        return '请选择邀请人';
      },
    },
    reserveKeyword: {
      type: Boolean,
      default: true,
    },
    disableDataList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {},
  data() {
    return {
      currentValue: [],
      options: [],
    };
  },
  filters:{
    hasDisable:function(val, dataList, key){
      let result = dataList.findIndex((k)=>{
        return k[key] == val
      })
      return result>-1 ? true : false
    }
  },
  watch: {
    value(newVal) {
      this.currentValue = newVal;
    },
    dataList(newVal) {
      this.options = newVal;
    },
  },
  mounted() {
    this.currentValue = this.value;
    this.options = this.dataList;
  },
  methods: {
    changePeople(data) {
      if(this.multiple || this.isItemObj){
        this.$emit('input', data);
      }else{
        let obj = this.dataList.find((item) => {
          //这里的carslist就是上面遍历的数据源
          return item[this.propsAlias.value] === data; //筛选出匹配数据
        });
        this.$emit('input', data, obj[this.propsAlias.label]);
      }
    },
    remoteMethod(query) {
      this.$emit('remoteMethod', query);
    },
  },
};
</script>

<style lang="scss">
.choose-people {
  .el-form-item {
    margin-bottom: 10px;
    &__label {
      padding-bottom: 0;
    }
    &__error {
      padding-left: 10px;
    }
  }
  .el-select {
    width: 100%;
    &__tags {
      .el-tag {
        margin-right: 10px;
        margin-bottom: 10px;
        background: $white;
        border-radius: 17px;
        padding: 0 17px;
        font-size: 12px;
        font-weight: 400;
        color: $lightblack;
        &--small {
          height: 32px;
          line-height: 30px;
        }
      }
    }
    .el-input__inner {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $linecolor;
      padding: 0 10px;
    }
  }
  .tojoy-tag {
    .el-tag {
      margin-right: 10px;
      margin-bottom: 10px;
      background: #ffffff;
      border-radius: 17px;
      padding: 0 17px;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
    }
    .el-input .el-input__inner {
      background: none;
      border-bottom: 0px solid $linecolor;
    }
  }
}
</style>
