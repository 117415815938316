<template>
  <div class="tojoy-mytarget-eval-view">
    <tojoy-title-content class="target-evaluate-done">
      <template #footer>
        <div class="tojoy-title-content__footer mytarget-eval-header">
          <!--tab-->
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" ></tojoy-tabs>
          <!--选择条件-->
          <div class="colorContain">
            <div
              class="searchPeople hover-border"
              v-if="leadPendingData.total"
              @click="leadSubordinate = true"
            >
              {{targetMont}}月目标：
              <span class="blue">{{ leadPendingData.nonDelivery }}人未交</span
              >/{{ leadPendingData.total }}
              <i class="el-icon-arrow-down" />
            </div>
            <template v-if="approveData.length">
              <div
                  class="searchPeople target-operate-cus-btn"
                  v-if="tabName == 'waitConfirm' || tabName == 'waitEvaluation'"
                  @click="handelBranch(stateType)"
              >
                <i class="el-icon-coin" />
                {{ isFuncBatch[stateType] ? '取消批量' : '批量操作' }}
              </div>
            </template>
          </div>
        </div>
      </template>
    </tojoy-title-content>


    <tojoy-pagination-list
      v-for="(item, index) in tabs"
      :key="index"
      v-show="tabName === `${item.name}`"
      :data="approveData"
      :total="total"
      schema="block"
      :loading="isLoading"
      @page-change="handleChangePage"
    >
      <template #item="{ data }">
        <div  @click="goDetail(data)" style="width: 100%">
          <target-evaluation-list
              :sourceData="data"
              :state="stateType"
              :buttonForm="fnButtonForm"
              :isFuncBatch="isFuncBatch[stateType]"
              :maxNumberScoreObj = "maxNumberScoreObj"
              @submitAffirm="submitAffirm"
              @submitLscore="submitLscore"
          ></target-evaluation-list>
        </div>
      </template>
    </tojoy-pagination-list>
    <div class="tojoy-target-button"
      v-if="isFuncBatch[stateType]"
    >
      <div class="taret-button">
        <el-button
          type="primary"
          class="submit"
          v-if="stateType == '1-1'"
          @click="branchSubmitForm"
          >批量提交确认<span></span
        ></el-button>
        <el-button
          type="primary"
          class="submit"
          v-if="stateType == '2-1'"
          @click="branchSubmitForm"
          >提交评分<span></span
        ></el-button>
      </div>
    </div>
    <target-lead-subordinate
      :display="leadSubordinate"
      @before-close="setTargetUrge"
    ></target-lead-subordinate>
  </div>
</template>

<script>
import { MessageBox, Message } from 'element-ui';
import TojoyTitleContent from '@/components/common/initiate-team/title-content';
import TojoyPaginationList from '@/components/business/pagination-list';
import targetEvaluationList from '@/components/mytarget/target-evaluation-list';
import TargetLeadSubordinate from '@/components/mytarget/target-lead-subordinate';
import TojoyTabs from '@/components/business/tabs'

let _this = null;
import {
  ApiTargetLeadList /**目标考核所有目标列表查询*/,
  ApiTargetLeadListAffirm /**目标列表批量确认 */,
  ApiTargetLeadListCore /** 目标列表批量评分*/,
  ApiTargetLeadPending,
  ApiTargetUrge,
} from '@/api/api_target';
import { ApiDimensionScore, ApiTargetMonth } from '../../api/api_target';


// 状态映射关系对照表
const __TABS_MAP__ = {
  '1-1': 'waitConfirm',
  '1-2': 'alreadyConfirm',
  '2-1': 'waitEvaluation',
  '2-2': 'alreadyEvaluation',
};

export default {
  name: 'evaluation-done',
  components: {
    TojoyTitleContent,
    TojoyPaginationList,
    targetEvaluationList,
    TargetLeadSubordinate,
    TojoyTabs
  },
  data() {
    return {
      isLoading: true,
      isTabChankClearBatch: true /**是否切换还原批量操作 */,
      leadSubordinate: false,
      leadPendingData: {
        nonDelivery: 0,
        notAffirm: 0,
        notEval: 0,
        total: 0,
      },
      isFuncBatch: {
        '1-1': false,
        '2-1': false,
      } /**批量操作按钮状态 */,
      targetLists: [],
      listForm: {
        flowType: 1,
        month: '',
        state: 1,
        year: '',
        page: 1,
        size: 10,
      } /**列表请求数据 */,
      total: 0,
      fnButtonForm: [] /**列表功能按钮请求数据 */,

      funcEval: {
        detailId: 0,
        score: 0,
      } /**列表功能按钮请求数据(考评) */,
      stateType: '',
      maxNumberScoreObj: {
        orgScore: 100,
        score: 100
      },
      datePickerMonth: '',
      approveData: [],
      tabs: [
        {
          name: 'waitConfirm',
          label: '待我确认',
        },
        {
          name: 'alreadyConfirm',
          label: '我已确认',
        },
        {
          name: 'waitEvaluation',
          label: ' 待我考评',
        },
        {
          name: 'alreadyEvaluation',
          label: '我已考评',
        },
      ],

      tabName: 'waitConfirm',
      tabNameVal: Object.keys(__TABS_MAP__).map((key) => {
        this[__TABS_MAP__[key]] = (callback) => {
          this.stateType = this[__TABS_MAP__[key]]['key'];
          this.init(this.stateType);
          callback && callback.call(this);
        };
        this[__TABS_MAP__[key]]['key'] = key;
        return __TABS_MAP__[key];
      }),
      targetYear:'',
      targetMont:''
    };
  },
  created() {
    _this = this;
    // 动态设定tab选项卡 选中效果
    let uriType = this.formDataStting();
    this.tabName = __TABS_MAP__[uriType.flowType + '-' + uriType.state];
  },
  mounted(){
    this.loadTargetLeadPending();
    // 是否开启默认当前日期 ----
    this.dateNow();
    this.loadTargetLeadData();
    // 入口执行
    this[this.tabName]();
    // 获取配置最高分
    if(this.stateType == '2-1'){
      this.getMaxScore()
    }
  },
  methods: {
    // 单条确认
    submitAffirm(params){
      let {type, val, reqParams} = params
      if (type == 'ok') {
        this.subTargetLeadListAffirm(
            [
              reqParams,
            ],
            () => {},
        );
      }
    },
    // 单条评分
    submitLscore(params){
      let {type, reqParams} = params
      if (type == 'ok') {
        this.subTargetLeadListCore(
            [
              reqParams
            ],
            () => {
            },
        );
      }
    },
    // 获取配置最高分
    async getMaxScore(){
      let {code,data} = await ApiDimensionScore({code:'target'})
      if(code==='000000'){
        this.maxNumberScoreObj.orgScore = data.orgScore
        this.maxNumberScoreObj.score = data.score
        }
      },
    goDetail(data){
      this.$router.push({
        path:'/mytarget/ldeatils',
        query:{
          detailId: data.detailId,
          stateType: this.stateType,
          page:this.listForm.page,
          sort: data.sort,
          evalWeight: data.evalWeight
        }
      })
    },
    handleChangePage(val) {
      this.listForm.page = val
      this.loadTargetLeadData();
    },
    // 入口
    init(key) {
      let path = this.$router.history.current.path;
      //对象的拷贝
      let newQuery = JSON.parse(JSON.stringify(this.$route.query));
      // 地址栏的参数值赋值
      newQuery.state = key.split('-')[1];
      newQuery.type = key.split('-')[0];
      this.$router.replace({ path, query: newQuery }).catch(err => {
        console.log('输出报错', err);
      })
    },

    refreshData(operate){
      if(operate){
        this.loadTargetLeadData();
        this.loadTargetLeadPending()
      }
    },

    //一键提醒
    async setTargetUrge(type, number) {
      if (type == 'ok') {
        const { code, msg } = await ApiTargetUrge();
        if (code === '000000') {
          this.leadSubordinate = false;
          Message.success(`成功提醒${number}人`);
        }else{
          Message.error(msg);
        }
      } else {
        this.leadSubordinate = false;
      }
    },

    /**
     * 查询待办的数据
     */
    async loadTargetLeadPending() {
      const { code, data } = await ApiTargetLeadPending();
      if (code === '000000') {
        this.leadPendingData = data;
        this.tabs.map((item)=>{
          if((this.leadPendingData.notAffirm&& item.name == 'waitConfirm') || (this.leadPendingData.notEval && item.name == 'waitEvaluation')){
            this.$set(item, 'badge', true)
          }else{
            this.$set(item, 'badge', false)
          }
        })
      }
    },
    /**
     * 目标列表数据
     */
    async loadTargetLeadData() {
      this.isLoading = true
      const { code, data } = await ApiTargetLeadList(_this.listForm);
      if (code === '000000') {
        this.approveData = data ? this.fromDrawingData(data.list || []):[]
        this.total = data ? data.total : 0
        this.isLoading = false
      }
    },
    /**
     * 目标列表[批量]确认 提交
     */
    async subTargetLeadListAffirm(form, callback = () => {},isFunBatch) {
      if(form.length === 0){
        Message.warning('请选择需要确认的目标')
        return;
      }
      if(!isFunBatch){
        this.saveTargetLeadListAffirm(form, callback, isFunBatch);
      }else{
        MessageBox.confirm(`确定要批量提交处理吗？`, '提示', {
          confirmButtonText: '提交',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          this.saveTargetLeadListAffirm(form, callback, isFunBatch);
        })
        .catch(() => {
          Message.info(`已取消操作`);
        });
      }
    },

    // 确认，驳回 【批量操作】
    async saveTargetLeadListAffirm(form, callback, isFunBatch) {
      let rejectNumber = form.filter(item=>{return item.state == '6'})?.length
      let approveNumber = form.filter(item=>{return item.state == '3'})?.length
      const { code } = await ApiTargetLeadListAffirm({
        targetList: form,
      });
      if(!isFunBatch){
        if (code === '000000') {
          callback();
          let msg = form[0].state == '3'? '确认':'驳回'
          Message.success(`目标${msg}成功`);
          this.refreshData('refresh');
        }
      }else{
        if (code === '000000') {
          callback();
          Message.success(`确认成功\n 确认成功${approveNumber}位，驳回${rejectNumber}位`);
          this.refreshData('refresh');
        }
      }
    },


    /**
     * 目标列表[批量] 评分 提交
     */
    async subTargetLeadListCore(form, callback = () => {}, isFunBatch) {
      if(form.length === 0 && isFunBatch){
        Message.warning('请填写至少一个评分')
        return;
      } else if(form.length === 1 && form[0].score === ''){
        Message.error('请填写评分')
        return;
      }
      if(isFunBatch){
        MessageBox.confirm(`确定批量提交考评吗？`, '提示', {
          confirmButtonText: '提交',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          this.saveTargetLeadListCore(form, callback,isFunBatch);
        })
        .catch(() => {
          Message.info(`已取消操作`);
        });
      }else{
        this.saveTargetLeadListCore(form, callback,isFunBatch);
      }
    },

    // 评分 【批量操作】
    async saveTargetLeadListCore(form, callback, isFunBatch) {
      const { code } = await ApiTargetLeadListCore({
        targetList: form,
      });
      if (code === '000000') {
        callback();
        if(isFunBatch){
          Message.success(`评分成功,共完成${form.length}位员工评分`);
        }
        this.refreshData('refresh')
      }
    },

    // 选择当前日期
   async dateNow(val){
      let year = '';
      let month = '';
     if (val) {
       year = val.split('-')[0];
       month = val.split('-')[1];
     }else{
       let resDate = await ApiTargetMonth();
       year = resDate.data.year;
       month = resDate.data.month;
       this.targetYear = year;
       this.targetMont = month;
       month = month.toString().padStart(2, '0');
     }
     this.datePickerMonth = `${year}-${month}`
     this.listForm.year = year
     this.listForm.month = month
    },

    // 批量提交数据
    branchSubmitForm() {
      switch (this.stateType) {
        case '1-1':
          this.subTargetLeadListAffirm(
            this.filterFromDrawingData(this.approveData, 'affirm'),()=>{},true
          );
          break;
        case '2-1':
          this.subTargetLeadListCore(
            this.filterFromDrawingData(this.approveData, 'score'),()=>{}, true
          );
          break;
      }
    },

    // 过滤数据&拼装
    filterFromDrawingData(data, key) {
      let optionArr = [];
      if(key == 'affirm'){
        data.forEach((element) => {
          if (element[key].rejectChecked || element[key].approveChecked) optionArr.push(element[key]);
        });
      }else if(key == 'score'){
        data.forEach((element) => {
          if (element[key].score-0==0 || element[key].score-0>0) optionArr.push(element[key]);
        });
      }
      return optionArr;
    },

    // 绘制功能按钮请求数据
    fromDrawingData(data) {
      return data.map((item) => {
        /** 批量确认数据 */
        item.affirm = {
          rejectChecked: false,
          approveChecked: true,
          detailId: item.detailId,
          remark: '',
          state: '3' /**默认不选中 ！3 ｜ ！6-- 同意3， 拒绝6 */,
        };
        /** 批量考评数据 */
        item.score = {
          checked: true,
          detailId: item.detailId,
          score: undefined,
        };
        return item;
      });
    },

    // 根据路由参数动态变化--请求参数
    formDataStting() {
      this.listForm.flowType = this.$route.query.type ?? 1;
      this.listForm.state = this.$route.query.state ?? 1;
      return {
        flowType: this.listForm.flowType,
        state: this.listForm.state,
      };
    },

    // 批量操作按钮
    handelBranch(type) {
      this.isFuncBatch[type] = !this.isFuncBatch[type];
    },

    handleTabClick(tab) {
      this[this.tabName](this.formDataStting);
      if (this.isTabChankClearBatch)
        this.isFuncBatch = {
          '1-1': false,
          '2-1': false,
        };
      this.refreshData('refresh')
    },
  },
};
</script>

<style lang="scss">
.#{$css-prefix}-mytarget-eval-view {
  .mytarget-eval-header{
    position: relative;
  }
  .errorPage {
    width: auto !important;
    margin: 20px 30px !important;
  }
  .tojoy-pagination-list--block .item-card{
    cursor: pointer;
  }

  .tojoy-pagination-list--block .tojoy-pagination-list__wrap{
    /*margin-top: 0;*/
  }
  .colorContain {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: $lightblack;
    position: absolute;
    right: 30px;
    bottom: 10px;
    .searchPeople {
      margin-left: 30px;
    }
    .searchDate {
      .el-input__prefix,
      .el-input__suffix {
        top: -4px;
      }
      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 133px;
      }
      .el-input__inner {
        height: 32px;
        line-height: 32px;
        border-radius: 2px;
      }
      .el-input__inner:hover {
        cursor: pointer;
        border: 1px solid #3D7EFF !important;
      }
    }
    .searchPeople {
      height: 32px;
      line-height: 32px;
      background: $white;
      border-radius: 2px;
      border: 1px solid $d9d9d9;
      padding: 0 15px;
      i {
        color: $grey550;
      }
      .blue {
        color: $blue;
      }
    }
  }
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .tojoy-pagination-list {
    flex: 1;
  }
  .title-contents {
    padding: 0;
    text-align: right;
    .submit {
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      font-weight: 400;
      color: $white;
      background: $blue;
      padding: 0 50px;
      margin: 20px 30px 20px 0;
    }
  }
  .target-evaluate-done {
    .content-top{
      min-height: 20px!important;
    }
  }
}
</style>
