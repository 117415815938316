var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.display)?_c('div',{staticClass:"tojoy-dialog"},[_c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.display,"close-on-click-modal":false,"width":"560px","before-close":function () {
        _vm.handleBeforeClose('cancel');
      }},on:{"update:visible":function($event){_vm.display=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('span',{staticClass:"dialog-footer"},[_c('el-button',{directives:[{name:"prevent-click",rawName:"v-prevent-click"}],attrs:{"type":"primary"},on:{"click":function () {
              _vm.handleBeforeClose('ok');
            }}},[_vm._v(_vm._s(_vm.tabBar.length > 1 ? '确认' : '保存'))])],1)]},proxy:true}],null,false,3949658498)},[_c('div',{staticClass:"tojoy-dialog__header"},[(_vm.tabBar.length > 1)?_c('div',{staticClass:"tabWrap"},_vm._l((_vm.tabBar),function(item,index){return _c('div',{key:index,class:("tabItem " + (_vm.defaultForm.evalType == item ? 'active' : '')),style:(_vm.tabBar.length > 1 &&
            _vm.tabBar.length - 1 == index &&
            'margin-left: 3%'),on:{"click":function($event){return _vm.changeIndex(item)}}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.tabsTitleBlock[item].title))]),_c('p',{staticClass:"fu"},[_vm._v(_vm._s(_vm.tabsTitleBlock[item].disc))])])}),0):_vm._e()]),_c('section',{class:['tojoy-dialog__body',_vm.tabBar.length < 2 ? 'dialog_body_height':'']},[(_vm.defaultForm.evalType == 1)?_c('div',{staticStyle:{"width":"90%","margin-left":"5%"}},[_c('h3',{staticClass:"h3"},[_vm._v("考评流程")]),(_vm.rProcessConfig)?_c('div',_vm._l((_vm.rProcessConfig[_vm.defaultForm.evalType][0]
              .lists),function(item,index){return _c('div',{key:index},[_c('tojoy-technological-title',{staticClass:"mb20",attrs:{"titleContent":item.titleContent},on:{"technological-change":_vm.technologicalChange}}),_c('tojoy-technological',{attrs:{"leveNodeData":item.processContent.lists,"maxAddNode":item.processContent.maxAddNode,"addButtonTxt":item.processContent.addButtonTxt},on:{"node-callback":_vm.nodeCallback},scopedSlots:_vm._u([{key:"exec",fn:function(ref){
              var data = ref.data;
return [(data.name)?_c('div',{staticClass:"sele-box",class:[data.leveName=='目标自评' ? 'self-box-by': '']},[_vm._v(" "+_vm._s(data.name)),(data.leveName!=='目标自评')?_c('i',{staticClass:"el-icon-error",on:{"click":function($event){return _vm.deletePerson(data)}}}):_vm._e()]):_c('choose-people',{attrs:{"multipleLimit":1,"remote":true,"isItemObj":true,"placeholder":data.leveName=='审批节点'?'请选择审批人':'请选择考评人',"propsAlias":{ value: 'userId', label: 'userName' },"disabled":false,"multiple":false,"disableDataList":_vm.disableDataList},on:{"input":function($event){return _vm.dealPerson($event,data)}},model:{value:(data.name),callback:function ($$v) {_vm.$set(data, "name", $$v)},expression:"data.name"}})]}}],null,true)})],1)}),0):_vm._e()]):_vm._e(),(_vm.defaultForm.evalType == 2)?_c('div',{staticStyle:{"width":"90%","margin-left":"5%"}},[_c('h3',{staticClass:"h3"},[_vm._v("考评流程")]),_c('tojoy-technological-number',{attrs:{"numberNodeData":_vm.rProcessConfig[_vm.defaultForm.evalType]},scopedSlots:_vm._u([{key:"select",fn:function(ref){
              var data = ref.data;
return [_c('div',{staticClass:"select-flex"},[_c('p',[_vm._v("评分权重")]),_c('el-select',{staticClass:"sele-item",on:{"change":function (val) {
                    _vm.handleChangeWeight(
                      val,
                      data.sort,
                      _vm.rProcessConfig[_vm.defaultForm.evalType]
                    );
                  }},model:{value:(data.evalWeight),callback:function ($$v) {_vm.$set(data, "evalWeight", $$v)},expression:"data.evalWeight"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]}},{key:"exec",fn:function(ref){
                  var data = ref.data;
return _vm._l((data.lists),function(item,index){return _c('div',{key:index},[_c('tojoy-technological-title',{staticClass:"mb20",attrs:{"titleContent":item.titleContent},on:{"technological-change":_vm.technologicalChange}}),_c('tojoy-technological',{attrs:{"leveNodeData":item.processContent.lists,"maxAddNode":item.processContent.maxAddNode,"addButtonTxt":item.processContent.addButtonTxt},on:{"node-callback":_vm.nodeCallback},scopedSlots:_vm._u([{key:"exec",fn:function(ref){
                  var data = ref.data;
return [(data.name)?_c('div',{staticClass:"sele-box"},[_vm._v(" "+_vm._s(data.name)),(data.leveName!=='目标自评')?_c('i',{staticClass:"el-icon-error",on:{"click":function () {
                          data.name = '';
                        }}}):_vm._e()]):_c('choose-people',{attrs:{"multipleLimit":1,"remote":true,"isItemObj":true,"placeholder":data.leveName=='审批节点'?'请选择审批人':'请选择考评人',"propsAlias":{ value: 'userId', label: 'userName' },"disabled":false,"multiple":false,"disableDataList":_vm.disableDataList},on:{"input":function($event){return _vm.dealPerson($event,data)}},model:{value:(data.name),callback:function ($$v) {_vm.$set(data, "name", $$v)},expression:"data.name"}})]}}],null,true)})],1)})}}],null,false,2666446969)})],1):_vm._e()])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }