<script>
export default {
  name: 'eval-weight-show',
  props: {
    data: {
      type: Object,
      require: true
    },
    schema: {
      type: String,
      default: 'list' // details
    },
  },
  render() {
    const { data: {evalType, sort, evalWeight}, schema } = this
    const className = `eval-weight-wrap eval-weight-wrap--${schema}`
    return(
      evalType === 2 ? (
        <div class={className}>
          {`考评${sort}： 考评权重${evalWeight}%`}
        </div>
      ): ''
    )
  }
}
</script>
<style lang="scss">
.eval-weight-wrap {
  font-size: 16px;
  font-weight: 600;
  color: $lightblack;
  line-height: 16px;
  &--list {
    padding: 10px 0;
  }
  &--details {
    font-size: 18px;
    line-height: 18px;
    padding: 20px 0 10px 15px;
  }
}
</style>