var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tojoy-target-evaluation__list"},[_c('div',{staticClass:"tojoyAddTarget"},[_c('div',{staticClass:"tojoyDate"},[_c('span',{staticClass:"tojoyMonth"},[_vm._v(_vm._s(_vm.sourceData.month))]),_c('span',{staticClass:"tojoyYear"},[_vm._v(_vm._s(_vm.sourceData.year))])]),_c('div',{staticClass:"tojoyExamInfo"},[_c('div',{staticClass:"tojoyExmHeader"},[_c('div',{staticClass:"tojoyAvatar target-avatar-wrap"},[(_vm.sourceData.avatar)?_c('img',{attrs:{"src":_vm.sourceData.avatar || _vm.defaultAvatar}}):_c('span',{staticClass:"target-avatar target-avatardefault"},[_vm._v(_vm._s(_vm.sourceData.userName? _vm.sourceData.userName.slice(-2):''))])]),_c('div',{staticClass:"tojoyHeaderContent"},[_c('div',{staticClass:"tojoyAvatarInfo"},[_c('p',{staticClass:"tojoyAvatarName"},[_vm._v(_vm._s(_vm.sourceData.userName))]),_c('p',{staticClass:"tojoyAvatarTitle"},[_vm._v(_vm._s(_vm.sourceData.userJob))])]),_c('div',{staticClass:"tojoyProgress",style:((_vm.state == '1-1' && 'right:200px;') ||
            (_vm.state == '1-2' && 'right:0;') ||
            (_vm.state == '2-1' && 'right:260px;') ||
            (_vm.state == '2-2' && 'right:160px;'))},[_c('span',{staticClass:"tojoyProTotal"},[_vm._v(" 总进度：")]),_c('span',{staticClass:"tojoyProgressNumber"},[_vm._v(_vm._s(_vm.sourceData.progress || 0)+"%")]),_c('span',{staticClass:"tojoyProgressNum"},[_c('el-progress',{attrs:{"percentage":_vm.sourceData.progress || 0,"show-text":false}})],1)]),_c('div',{staticClass:"tojoyProScore"},[(_vm.state == '1-1')?_c('div',[(!_vm.isFuncBatch)?_c('span',{staticClass:"b1 hover",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () {
                  if (!_vm.isFuncBatch) {
                    _vm.affirmDisplayState = true;
                    _vm.affirmState = 6;
                  }
                })($event)}}},[_vm._m(0)]):(_vm.isFuncBatch)?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('el-checkbox',{attrs:{"label":"6"},on:{"change":function () {
                    if(_vm.sourceData.affirm.rejectChecked){
                       _vm.sourceData.affirm.approveChecked = false
                       _vm.sourceData.affirm.state = '6'
                    }
                  }},model:{value:(_vm.sourceData.affirm.rejectChecked),callback:function ($$v) {_vm.$set(_vm.sourceData.affirm, "rejectChecked", $$v)},expression:"sourceData.affirm.rejectChecked"}},[_c('span',[_vm._v("驳回")])])],1):_vm._e(),_c('span',{staticClass:"b2"},[_vm._v("|")]),(!_vm.isFuncBatch)?_c('span',{staticClass:"b3 hover",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (function () {
                  if (!_vm.isFuncBatch) {
                    _vm.affirmDisplayState = true;
                    _vm.affirmState = 3;
                  }
                })($event)}}},[_vm._m(1)]):_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[(_vm.isFuncBatch)?_c('el-checkbox',{attrs:{"label":"3"},on:{"change":function () {
                    if(_vm.sourceData.affirm.approveChecked){
                       _vm.sourceData.affirm.rejectChecked = false
                       _vm.sourceData.affirm.state = '3'
                    }
                  }},model:{value:(_vm.sourceData.affirm.approveChecked),callback:function ($$v) {_vm.$set(_vm.sourceData.affirm, "approveChecked", $$v)},expression:"sourceData.affirm.approveChecked"}},[_c('span',[_vm._v("确认目标")])]):_vm._e()],1)]):_vm._e(),(_vm.state == '2-1')?_c('div',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.sourceData.scoreType ? _vm.scoreTypeObj[_vm.sourceData.scoreType] : '综合评分'))]),_c('span',{staticClass:"tojoyDetailNum"},[_vm._v(_vm._s(_vm.sourceData.showScore))]),_c('span',[_vm._v("分")])]),_c('span',{staticClass:"b2"},[_vm._v("|")]),_c('span',{staticClass:"b3",staticStyle:{"width":"65px"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[(_vm.isFuncBatch)?_c('write-score',{staticClass:"target-evaluation-score",staticStyle:{"position":"relative","width":"65px","display":"inline-block"},attrs:{"placeholder":"评分","max-score-number":_vm.maxNumberScore},model:{value:(_vm.sourceData.score.score),callback:function ($$v) {_vm.$set(_vm.sourceData.score, "score", $$v)},expression:"sourceData.score.score"}}):_c('span',{staticClass:"hover",on:{"click":function($event){_vm.lscoreDisplayState = true;}}},[_c('i',{staticClass:"iconfont iconscore handle-icon"}),_vm._v(" "),_c('span',{staticClass:"handle-text"},[_vm._v("去评分")])])],1)]):_vm._e(),(_vm.state == '2-2')?_c('div',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('span',[_vm._v(_vm._s(_vm.sourceData.scoreType ? _vm.scoreTypeObj[_vm.sourceData.scoreType] : '综合评分'))]),_c('span',{staticClass:"tojoyDetailNum"},[_vm._v(_vm._s(_vm.sourceData.showScore))]),_c('span',[_vm._v("分")])]):_vm._e()])])]),_c('eval-weight-show-comp',{attrs:{"data":{
            evalType: _vm.sourceData.evalType,
            evalWeight: _vm.sourceData.evalWeight,
            sort:_vm.sourceData.sort
          },"schema":"list"}}),_vm._l((_vm.sourceData.targetIndexOutputs),function(item,index){return _c('div',{key:index,staticClass:"tojoyExamItem"},[_c('span',{staticClass:"tojoyExamInfoDesing"},[_vm._v(_vm._s(item.name))]),(item.finishTime || item.planFinishDate)?_c('span',{staticClass:"tojoyExamInfoTime"},[_vm._v("截止："+_vm._s(item.finishTime || (item.planFinishDateStr && item.planFinishDateStr.replace(/\./g,'-'))))]):(item.type===3)?_c('span',{staticClass:"tojoyExamInfoTime"},[_vm._v(_vm._s(item.frequency || '')+"例行")]):_vm._e(),_c('span',{staticClass:"tojoyExamInfoLine"},[_vm._v("进度："+_vm._s(item.progress || 0)+"%")]),_c('span')])})],2)]),_c('target-index-affirm',{attrs:{"display":_vm.affirmDisplayState,"affirmState":_vm.affirmState-0},on:{"before-close":_vm.submitAffirm}}),_c('target-index-lscore',{attrs:{"display":_vm.lscoreDisplayState,"maxNumberScore":_vm.maxNumberScore,"defaultScore":_vm.defaultScore},on:{"before-close":_vm.submitLscore}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"icon iconfont iconrejectprocess handle-icon"}),_c('span',{staticClass:"hanle-text"},[_vm._v("驳回")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"handle-icon el-icon-circle-check"}),_c('span',{staticClass:"handle-text"},[_vm._v("同意")])])}]

export { render, staticRenderFns }