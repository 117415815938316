<template>
  <div class="tojoy-dialog__index">
    <el-dialog
      :visible.sync="display"
      width="560px"
      center=""
      :close-on-click-modal="false"
      :before-close="
        () => {
          handleBeforeClose('cancel');
        }
      "
    >
      <template #title>
        <div class="tojoy-dialog__header--title">
          <span>自评</span>
        </div>
      </template>
      <div class="tojoy-dialog__body--form">
        <el-form ref="dialogForm" >
          <el-form-item class="form-item required" label="自评分数">
            <!--<el-select-->
                <!--class="pleaseWrite"-->
                <!--v-model="formData.selfScore"-->
                <!--placeholder= "请选择"-->
                <!--style="width: 100%"-->
            <!--&gt;-->
              <!--<el-option-->
                  <!--v-for="item in scoreOptions"-->
                  <!--:key="item.value"-->
                  <!--:label="item.label"-->
                  <!--:value="item.value"-->
              <!--&gt;-->
              <!--</el-option>-->
            <!--</el-select>-->
            <write-score v-model="formData.selfScore" :default-score="defaultScore"  :max-score-number="maxScoreNumber"></write-score>
          </el-form-item>
          <el-form-item class="form-item" label="工作成果">
            <el-input
                class="pleaseWrite"
                v-model="formData.workResult"
                type="textarea"
                resize="none"
                rows="5"
                show-word-limit
                maxlength="300"
                placeholder="工作成果（选填，最多300字）"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button
              v-preventClick
            type="primary"
            @click="
              () => {
                handleBeforeClose('ok');
              }
            "
            >完成</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script >
import WriteScore from '../../common/WriteScore';
export default {
  name: 'target-index-score',
  components: { WriteScore },
  props: {
    display: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    maxScoreNumber: {
      type: Number,
      default: 100
    },
    defaultScore: {
      type: Number,
      default: null
    }
  },
  data() {
    return {};
  },
  computed:{
    scoreOptions(){
      let length = 0
      let maxScoreNumber = this.maxScoreNumber+''
      if((maxScoreNumber).indexOf('.')>-1){
        length =  Math.floor(this.maxScoreNumber/1)
      }else{
        length = this.maxScoreNumber/1
      }
      let options =  (Object.keys(Array.apply(null, { length })).map(
          (item) => {
            let num = ++item * 1;
            return {
              value: num,
              label: num+'分',
            };
          },
      )).reverse()
      options.push({value:0, label:'0分'})
      if((maxScoreNumber).indexOf('.')>-1){
        options.unshift({value:this.maxScoreNumber, label:this.maxScoreNumber+'分'})
      }
      return options
    },
  },
  mounted(){

  },
  methods: {
    handleBeforeClose(val) {
      this.$emit('before-close', val);
    },
  },
};
</script>

