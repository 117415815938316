var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tojoy-dialog__lead"},[_c('el-dialog',{attrs:{"visible":_vm.display,"width":"560px","before-close":function () {
        _vm.handleBeforeClose('cancel')
      }},on:{"update:visible":function($event){_vm.display=$event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"tojoy-dialog__lead--title"},[_vm._v("三清提交人员统计（"+_vm._s(_vm.allNo-_vm.unDoNo)+"/"+_vm._s(_vm.allNo)+"）")])]},proxy:true},{key:"footer",fn:function(){return [_c('p',{staticClass:"txt"},[_vm._v("共"+_vm._s(_vm.unDoNo)+"人未交三清")]),_c('span',{staticClass:"dialog-footer"},[_c('el-button',{attrs:{"disabled":Number(_vm.unDoNo) <= 0,"type":"primary","size":"small"},on:{"click":function () {
              _vm.handleBeforeClose('ok', _vm.calUnpaidNumber)
            }}},[_vm._v("一键提醒")])],1)]},proxy:true}])},[_c('div',{staticClass:"tojoy-dialog__lead--body"},_vm._l((_vm.urgeUserList),function(item,index){return _c('div',{key:index,staticClass:"tojoyExamInfo"},[_c('small-user-card',{attrs:{"size":38,"data":{
            userid: item.userId || '',
            avatar: item.avatar || '',
            username: item.userName || '',
            position: item.jobName || ''
          }},on:{"user-card":function (u) { return _vm.$router.push({ name: 'homepage-navigation', query: { userId: u.userid } }); }}},[_c('div',{attrs:{"slot":"right"},on:{"click":function($event){$event.stopPropagation();}},slot:"right"},[_c('span',{class:['tag', item.isDone ? 'yes' : 'no']},[_vm._v(_vm._s(item.isDone ? '已交' : '未交'))])])])],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }