<script lang="jsx">
import TojoyBlank from '@/components/business/blank/blank'
import {saveCommentBatch} from '@/api/thrclear.js'
import { MessageBox, Message } from 'element-ui'
import { mapState } from 'vuex'
export default {
  name: 'batch-pageList',
  components: {
    TojoyBlank
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 10,
    },
    data: {
      type: Array,
      default: () => []
    },
    schema: {
      type: String,
      default: 'line' // line | block
    },
    loading: {
      type: Boolean,
      default: false
    },
    nodataSchema: {
      type: String,
      default: 'notdata'
    },
    showRadius: {
      type: Boolean,
      default: true
    },
    enableAllOk:{    //是否开启全部同意
      type: Boolean,
      default: true
    },
    pitchList:{  //选中的数组
      type: Array,
      default: () => []
    },
    allchecked:{ //全部选中
      type: Boolean,
      // default: false
    },
    isIndet:{ //不确定状态
      type: Boolean,
      default: false
    },
    yesComment:{
      type: Boolean,
      default: true
    },
    noComment:{
      type:Boolean,
      default:true
    },
        tabName: {
      type: String,
      default: () => {}
    },
    codeOfconducdList: {
      type: Array
    }
  },
  data() {
    return {
      scrollDom: {},
      batchloading:false,
      isTojoy: JSON.parse(localStorage.getItem('isTojoy'))
    }
  },
  async updated() {
    await this.$nextTick()
    this.scrollDom = this.$refs['tojoy-pagination-wrap-ref']
  },
  computed: {
    ...mapState({
      userName: state => state.userInfo.userName,
      userId: state => state.userInfo.userId
    }),
    //props传过来的值用计算属性修改
    allcheckedValue:{
       get() {
        return this.allchecked
      },
      set(val) {
        this.$emit('change-allCheck', val)
      }
    }
  },
  methods: {
    handleChange(val) {
      this.currentPageVal = val
      this.resetScrollTop()
      this.$emit('page-change', val)
    },
    resetScrollTop() {
      if (this.scrollDom) {
        this.scrollDom.scrollTop = 0
      }
    },

    //批量全部通过不通过
    batchReview(approveStatus) {
      console.log('全部通过不通过',this.pitchList,this.codeOfconducdList);
      this.batchloading = true
      if(!this.pitchList.length){
        this.$warning('请您先选择要审阅的三清')
      }else{
        let isPostil = this.pitchList.every(ite => !ite.remarks)
      if (approveStatus === 4) {
        //不通过批注必填
        if (this.noComment && isPostil) {
          this.$warning('批量审阅不通过批注必填')
        } else {
          this.callCommentBatch(approveStatus)
        }
      } else if (approveStatus === 3) {
        if (this.yesComment && isPostil) {
          this.$warning('批量审阅通过批注必填')
        } else {
          this.callCommentBatch(approveStatus)
         }
       }
      }

    },

    //批量审阅接口
    callCommentBatch(tp) {
      MessageBox.confirm(`是否确认全部${tp == 4 ? '不' : ''}通过审阅？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let comments = this.pitchList.map(item => {
          let info =  {
            userName: this.userName,
            content: item.remarks,
            reportId: item.id
          }
          // 如果是 天九企业月评 && 有行为规范自评参数
          if (item.reportType === 3 && this.isTojoy && item.deductionApprove) {
            info.approveActionInput =  {
              actionMainId:	item?.deductionApprove?.actionMainId, //月度自评id
              //默认审核通过，
              approveStatus: item?.deductionApprove.pointsId == 0  ? 2 : 3, //审阅状态:0待审阅，2通过，3不通过
              pointsId: item?.deductionApprove.pointsId,//扣分项ID
              pointsName: item?.deductionApprove.pointsId === 0 ? '' : this.codeOfconducdList.find(it => it.id == item.deductionApprove.pointsId )?.title,
              score:item?.deductionApprove.pointsId === 0 ? '' : this.codeOfconducdList.find(it => it.id == item.deductionApprove.pointsId )?.score
            }
          }
          return info
        })
        let pm = {
          approveStatus: tp,
          comments
        }
        console.log('批量传参',pm);
        saveCommentBatch(pm).then(res => {
          if (res.code === '000000') {
            this.$msgSuccess('成功')
            this.$emit('get-batchList')
            this.batchloading = false
          }
        })
      }).catch(() => {
        Message.info(`已取消操作`)
      });
    },
  },

  render(h) {
    const {data, total, size, $scopedSlots, schema, loading, nodataSchema, showRadius,enableAllOk} = this
    const currentClassNames = [`batch-pageList batch-pageList--${schema}`]
    const currentWrapClassNames = [schema==='line' && showRadius ? `batch-pageList__wrap show-radius` :  `batch-pageList__wrap`]
    const batchSwitch = [`batch-switch`]
    return <div class={currentClassNames}>
      {
        loading ? (
          <div class="batch-pageList__loading"></div>
        ) : (
          data.length > 0 ? (
            <div ref="batch-pageList-wrap-ref" class={currentWrapClassNames}>
            {
              data.map((item, index) => {
                return (
                  <div key={index} class="item-card">
                    {
                      $scopedSlots.item({
                        data: item
                      })
                    }
                  </div>
                )
              })
            }
        </div>) : <tojoy-blank  blank-data={{ type: 'notdata' }} />
        )
      }
      {
        enableAllOk || this.tabName == 'personBatch'  ?
        <div></div>
        :<div class="switch-all" >全部同意功能尚未开启，请联系人事管理员进行配置</div>
      }
      {
        <div class={batchSwitch}>
         <p><el-checkbox class="batch-checkbox" indeterminate={this.isIndet} on-change={(allchecked) => this.$emit('change-allCheck',allchecked)}  v-model={this.allcheckedValue}>全选({this.pitchList.length}/{data.length})</el-checkbox></p>
         <div>
          <el-button style="color:#FF526A;border: 1px solid #DDDDDD; width:200px; height:44px"plain on-click={() => this.batchReview(4)}>全部不通过</el-button>
          <el-button style="background-color: #3D7EFF;color: #fff;border: 1px solid #3D7EFF;" disabled={!enableAllOk && this.tabName !== 'personBatch' } plain size="small" on-click={() => this.batchReview(3)}>全部通过</el-button>
         </div>
        </div>
      }

    </div>
  }
}
</script>
<style lang="scss">
.batch-pageList {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &--line {
  }
  &__wrap {
    flex: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 1;
    .item {
      &-card {
        display: flex;
        align-items: center;
        background: $white;
        // border-bottom: 1px solid $linecolor;
        padding: 15px 15px 0;
        flex-shrink: 0;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
    &.show-radius {
      .item-card {
        &:first-of-type {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:last-of-type {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
  &--block {
    .tojoy-pagination-list__wrap {
      background: transparent;
    }
    .item {
      &-card {
        display: flex;
        border-bottom: none;
        background: $white;
        padding: 15px 15px 0;
        margin-bottom: 10px;
        border-radius: 5px;
        &:last-of-type {
          border-bottom: none;
        }
        &:hover {
          transition: 0.1s;
          background: $hover-block;
        }
      }
    }
  }
  .switch-all {
    z-index: 2;
    color: #fe831a;
    font-size: 14px;
    box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.05);
    background: #fef2e8;
    text-align: center;
    padding: 10px 0;
  }
  .batch-switch {
    font-size: 14px;
    z-index: 2;
    height: 68px;
    box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    .batch-checkbox {
      margin-right: 10px;
    }
    div {
      button {
        width: 200px;
        height: 44px;
        font-size: 14px;
      }
    }
  }
  &__notdata {
    margin: 20px 30px;
    border-radius: 5px;
  }
  &__loading {
    margin: 20px 30px;
    background: $white;
    height: 100%;
    border-radius: 5px;
  }
}
</style>
